export enum DetectionMode {
  USER = 1,
  AUTO = 2
}

export enum DetectionStatus {
  TO_BE_CONFIRMED = 1,
  CONFIRMED = 2,
  FALSE_POSITIVE = 3,
  NOT_RELEVANT = 4,
}

export enum DetectionBackgroundColor {
  TO_BE_CONFIRMED = 'rgba(0, 124, 166, 0.2)',
  CONFIRMED = 'rgba(42, 0, 166, 0.2)',
}

export enum DetectionBorder {
  TO_BE_CONFIRMED = '0.1px solid #007CA6',
  CONFIRMED = '0.1px solid #2a00a6',
}

export enum DetectionTextColor {
  TO_BE_CONFIRMED = '#007CA6',
  CONFIRMED = '#2a00a6',
}
