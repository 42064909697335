import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Site } from '../../../models/site';
import { deactivateSite, } from '../../../features/site/site.actions';
import { MatDialog } from '@angular/material/dialog';
import {
  SiteDeactivationDialog
} from '../../../shared/dialogs/site-deactivation-dialog/site-deactivation.dialog';
import {
  ReportModeDialog
} from '../../../shared/dialogs/report-mode-dialog/report-mode.dialog';
import { ReportMode } from '../../../enums/report';
import {
  generateCutReport, generateCutReportFailure, generateCutReportSuccess,
  generateDetectionReport,
  generateDetectionReportFailure,
  generateDetectionReportSuccess,
  reportRequireAllData,
  reportWithData
} from '../../../features/report/report.actions';
import { setDrawingMode } from '../../../features/cesium/cesium.actions';
import { DrawingMode } from '../../../enums/drawing-mode';
import { first } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';
import {
  retrieveDenseCloudDownloadUrl,
  retrieveDenseCloudDownloadUrlFailure,
  retrieveDenseCloudDownloadUrlSuccess,
  retrieveSparseCloudDownloadUrl,
  retrieveSparseCloudDownloadUrlFailure,
  retrieveSparseCloudDownloadUrlSuccess
} from '../../../features/batch/batch.actions';
import { ExportTools } from '../../../tools/export.tools';
import { User } from '../../../models/user';
import { DatePipe } from '@angular/common';
import { selectUser } from '../../../features/user/user.selectors';
import { UploadType } from '../../../enums/upload';
import {
  addCuts,
  addCutsFailure,
  addCutsSuccess, exportCutDXFFailure,
  exportCutsBookDXF, exportCutsBookDXFSuccess,
  exportCutsCSV,
  exportCutsCSVFailure,
  exportCutsCSVSuccess
} from '../../../features/cut/cut.actions';
import {
  addTrackerElevations,
  addTrackerElevationsFailure,
  addTrackerElevationsSuccess,
  addTrackerImages,
  addTrackerImagesFailure,
  addTrackerImagesSuccess,
  exportTrackersElevationCSV,
  exportTrackersElevationCSVFailure,
  exportTrackersElevationCSVSuccess,
  exportTrackersImageCSV,
  exportTrackersImageCSVFailure,
  exportTrackersImageCSVSuccess
} from '../../../features/tracker/tracker.actions';
import {
  CsvReaderDialog,
  CsvReaderMode
} from '../../../shared/dialogs/csv-reader-dialog/csv-reader.dialog';
import { InspectionModule } from '../../../enums/inspection-module';
import {
  ReportFormatsDialog
} from '../../../shared/dialogs/report-formats-dialog/report-formats.dialog';

@Component({
  selector: 'site-actions-bar',
  templateUrl: './site-actions-bar.component.html',
  styleUrls: ['./site-actions-bar.component.scss']
})
export class SiteActionsBarComponent {

  @Input({required: true}) site: Site | undefined
  @ViewChild('fileUpload') fileUploadRef: ElementRef<HTMLInputElement> | undefined;
  inspectionModule = InspectionModule;
  private uploadType: UploadType = UploadType.NONE;

  constructor(private store: Store,
              private actions: Actions,
              private dialog: MatDialog,
              private translocoService: TranslocoService,
              private toastr: ToastrService) {
    this.initHandleReport();
    this.initHandleDownload();
    this.initHandleCSV();
    this.initHandleDXF();
  }

  private initHandleDownload(): void {
    this.actions.pipe(ofType(retrieveSparseCloudDownloadUrlSuccess), takeUntilDestroyed())
      .subscribe((result: any) => {
        ExportTools.exportResourceUrl(result.url);
      });
    this.actions.pipe(ofType(retrieveDenseCloudDownloadUrlSuccess), takeUntilDestroyed())
      .subscribe((result: any)  => {
        ExportTools.exportResourceUrl(result.url);
      });
    this.actions.pipe(ofType(retrieveSparseCloudDownloadUrlFailure, retrieveDenseCloudDownloadUrlFailure), takeUntilDestroyed())
      .subscribe((result: any)  => {
        this.toastr.error(this.translocoService.translate('process_error'));
      });
  }

  private initHandleCSV(): void {
    this.actions.pipe(ofType(addCutsSuccess, addTrackerImagesSuccess, addTrackerElevationsSuccess), takeUntilDestroyed())
      .subscribe((result: any)  => {
        this.toastr.success(this.translocoService.translate('csv_content_add_success'));
      });
    this.actions.pipe(ofType(addCutsFailure, addTrackerElevationsFailure, addTrackerImagesFailure), takeUntilDestroyed())
      .subscribe((result: any)  => {
        this.toastr.error(this.translocoService.translate('csv_content_add_failure'));
      });
    this.actions.pipe(ofType(exportCutsCSVSuccess), takeUntilDestroyed())
      .subscribe((result: any)  => {
        if (this.site) {
          const filename = this.site.name + '_cuts_coordinates';
          ExportTools.exportCSV(filename, result.csv);
        }
      });
    this.actions.pipe(ofType(exportTrackersImageCSVSuccess), takeUntilDestroyed())
      .subscribe(result => {
        if (this.site) {
          const filename = this.site.name + '_markers_coordinates';
          ExportTools.exportCSV(filename, result.csv);
        }
      });
    this.actions.pipe(ofType(exportTrackersElevationCSVSuccess), takeUntilDestroyed())
      .subscribe(result => {
        if (this.site) {
          const filename = this.site.name + '_elevations_coordinates';
          ExportTools.exportCSV(filename, result.csv);
        }
      });
    this.actions.pipe(ofType(
      exportCutsCSVFailure,
      exportTrackersImageCSVFailure,
      exportTrackersElevationCSVFailure), takeUntilDestroyed())
      .subscribe((result: any)  => {
        this.toastr.error(this.translocoService.translate('process_error'));
      });
  }

  private initHandleDXF(): void {
    this.actions.pipe(ofType(exportCutsBookDXFSuccess), takeUntilDestroyed())
      .subscribe((result: any)  => {
        if (this.site) {
          const filename = this.site.name + '_cuts';
          ExportTools.exportDXF(filename, result.dxf);
        }
      });
    this.actions.pipe(ofType(exportCutDXFFailure), takeUntilDestroyed())
      .subscribe((result: any)  => {
        this.toastr.error(this.translocoService.translate('process_error'));
      });
  }

  private initHandleReport(): void {
    this.actions.pipe(ofType(generateDetectionReportSuccess, generateCutReportSuccess),
      takeUntilDestroyed())
      .subscribe(data => {
        this.toastr.success(this.translocoService.translate('generate_report_success', {mail: data.email}));
      });
    this.actions.pipe(ofType(generateDetectionReportFailure, generateCutReportFailure),
      takeUntilDestroyed())
      .subscribe(result => {
        this.toastr.error(this.translocoService.translate('generate_report_failure'));
      });
  }

  onClickDeactivateSite(site: Site) {
    const deactivateSiteDialogRef = this.dialog.open(SiteDeactivationDialog);
    deactivateSiteDialogRef.componentInstance.siteName = site.name;
    deactivateSiteDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.store.dispatch(deactivateSite({
          organizationId: site.organization,
          siteId: site.id
        }));
      }
    });
  }

  onFileSelected(event: any): void {
    const file:File = event.target.files[0];
    if (file) {
      file.text().then((content: any) => {
        const csvReaderDialogRef = this.dialog.open(CsvReaderDialog);
        csvReaderDialogRef.componentInstance.content = content;
        csvReaderDialogRef.componentInstance.mode = (this.uploadType == UploadType.CSV_CUT) ? CsvReaderMode.TWO_POINTS : CsvReaderMode.ONE_POINT;
        csvReaderDialogRef
          .afterClosed()
          .subscribe(data => {
          if (data && this.site) {
            switch (this.uploadType) {
              case UploadType.CSV_CUT:
                this.store.dispatch(addCuts({
                  organizationId: this.site.organization,
                  siteId: this.site.id,
                  cuts: data
                }));
                break;
              case UploadType.CSV_ELEVATION:
                this.store.dispatch(addTrackerElevations({
                  organizationId: this.site.organization,
                  siteId: this.site.id,
                  trackers: data,
                }));
                break;
              case UploadType.CSV_MARKER:
                this.store.dispatch(addTrackerImages({
                  organizationId: this.site.organization,
                  siteId: this.site.id,
                  trackers: data,
                }));
                break;
            }
          }
        });
      });
    }
  }

  onClickUploadCSVCut(site: Site): void {
    this.uploadType = UploadType.CSV_CUT;
    this.openFileChooser("text/csv");
  }

  onClickUploadCSVElevation(site: Site): void {
    this.uploadType = UploadType.CSV_ELEVATION;
    this.openFileChooser("text/csv");
  }

  onClickUploadCSVMarker(site: Site): void {
    this.uploadType = UploadType.CSV_MARKER;
    this.openFileChooser("text/csv");
  }

  onClickDownloadPointCloudDense(site: Site) {
    this.store.select(selectUser)
      .pipe(first())
      .subscribe((user: User | null | undefined) => {
        if (this.site && user) {
          const dateFormat = user.languageCode == 'fr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
          const datePipe: DatePipe = new DatePipe(this.translocoService.getActiveLang());
          const date = datePipe.transform(new Date(this.site.lastBatchDate), dateFormat)
          const filename = this.site.name.replace(/ /g, "_") + '_' + date + '_dense_cloud.ply';
          this.store.dispatch(retrieveDenseCloudDownloadUrl({
            organizationId: site.organization,
            siteId: site.id,
            batchId: site.lastBatch,
            filename
          }));
        }
      });
  }

  onClickDownloadPointCloudSparse(site: Site) {
    this.store.select(selectUser)
      .pipe(first())
      .subscribe((user: User | null | undefined) => {
        if (this.site && user) {
          const dateFormat = user.languageCode == 'fr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
          const datePipe: DatePipe = new DatePipe(this.translocoService.getActiveLang());
          const date = datePipe.transform(new Date(this.site.lastBatchDate), dateFormat)
          const filename = this.site.name.replace(/ /g, "_") + '_' + date + '_sparse_cloud.ply';
          this.store.dispatch(retrieveSparseCloudDownloadUrl({
            organizationId: site.organization,
            siteId: site.id,
            batchId: site.lastBatch,
            filename
          }));
        }
      });
  }

  onClickGenerateDetectionReport(site: Site) {
    this.store.dispatch(generateDetectionReport({
      organizationId: site.organization,
      siteId: site.id,
      formats: ['pdf']
    }));
    // const dialogReportFormatsModeRef = this.dialog.open(ReportFormatsDialog, {
    //   disableClose: false
    // });
    // dialogReportFormatsModeRef.afterClosed().subscribe(formats => {
    //   if (formats) {
    //
    //   }
    // });
  }

  onClickGenerateCutReport(site: Site) {
    const dialogReportFormatsModeRef = this.dialog.open(ReportFormatsDialog, {
      disableClose: false
    });
    dialogReportFormatsModeRef.afterClosed().subscribe(formats => {
      if (formats) {
        this.store.dispatch(generateCutReport({
          organizationId: site.organization,
          siteId: site.id,
          formats: formats
        }));
      }
    });
  }

  onClickGenerateCutsBookDXF(site: Site) {
    this.store.dispatch(exportCutsBookDXF({
      organizationId: site.organization,
      siteId: site.id
    }));
  }

  onClickGenerateCutsCSV(site: Site) {
    this.store.dispatch(exportCutsCSV({
      organizationId: site.organization,
      siteId: site.id
    }));
  }

  onClickGenerateTrackersImageCSV(site: Site) {
    this.store.dispatch(exportTrackersImageCSV({
      organizationId: site.organization,
      siteId: site.id
    }));
  }

  onClickGenerateTrackersElevationCSV(site: Site) {
    this.store.dispatch(exportTrackersElevationCSV({
      organizationId: site.organization,
      siteId: site.id
    }));
  }


  selectReportMode(): void {
    const dialogReportModeRef = this.dialog.open(ReportModeDialog, {
      disableClose: false
    });
    dialogReportModeRef.afterClosed().subscribe(mode => {
      if (mode == ReportMode.ALL) {
        this.store.dispatch(reportRequireAllData());
      }
      else if (mode == ReportMode.AREA) {
        this.store.dispatch(setDrawingMode({drawingMode: DrawingMode.REPORT_AREA}));
      }
    });
  }

  private openFileChooser(accept: string) {
    this.fileUploadRef?.nativeElement.setAttribute('accept',"text/csv" );
    this.fileUploadRef?.nativeElement.click();
  }
}
