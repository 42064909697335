import {
  createSelector,
  createFeatureSelector,
} from '@ngrx/store';
import * as fromCut from './cut.reducer';

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = fromCut.adapter.getSelectors();

export const selectCutState = createFeatureSelector<fromCut.State>('cuts');

export const selectCutIds = createSelector(
  selectCutState,
  selectIds
);
export const selectCutEntities = createSelector(
  selectCutState,
  selectEntities
);
export const selectAllCuts = createSelector(
  selectCutState,
  selectAll
);
export const selectCutTotal = createSelector(
  selectCutState,
  selectTotal
);

export const selectCurrentCutId = createSelector(
  selectCutState,
  fromCut.getSelectedCutId
);

export const selectCurrentCut = createSelector(
  selectCutEntities,
  selectCurrentCutId,
  (entities, id) => (id) ? entities[id] : null
);

export const selectCutById = (id: string) => createSelector(
  selectAllCuts,
  (allItems) => {
  if (allItems) {
    return allItems.find(item => {
      return item.id === id;
    });
  } else {
    return {};
  }
});


export const selectCutsByIds = (ids: Array<string>) => createSelector(
  selectAllCuts,
  (allItems) => {
    return allItems.filter(item => {
      return ids.includes(item.id);
    });
  });
