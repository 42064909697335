<mat-card class="container" *ngIf="detection">
  <mat-card-header>
    <mat-card-title-group>
      <mat-card-title>
        <span style="position: relative; font-weight: 600">
          {{this.detection.name}}
          <div class="emergency-badge" [style.background-color]="detection.textColor"></div>
        </span>
      </mat-card-title>
      <mat-card-subtitle>{{detection.createMode | formatDetectionMode}}</mat-card-subtitle>
      <mat-card-subtitle>{{this.detection.center | wgs84}}</mat-card-subtitle>
      <button mat-icon-button
              class="close-button material-symbols-outlined"
              (click)="onClickClose()">close
      </button>
    </mat-card-title-group>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content>
    <div class="indicator-content">
      <div style="width: 50%;">
        <div class="module-title">{{'confidence_index' | transloco | uppercase}} :</div>
        <div class="indicators">{{confidence.toFixed(0)}} %</div>
      </div>
      <div style="padding-left: 5px;">
        <div class="module-title">{{'evolution_indicator' | transloco | uppercase}} :</div>
        <div class="indicators">{{evolution.toFixed(0)}} %</div>
      </div>
    </div>

    <div class="selector-content">
      <form [formGroup]="form">
        <div class="detection-controls">
          <mat-form-field class="btn-left" *ngIf="detectionTemplate">
            <mat-label>{{'type' | transloco}}</mat-label>
            <mat-select required formControlName="type">
              <mat-option *ngFor="let item of detectionTemplate.labels" [value]="item.label">{{item.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field [ngClass]="{'btn-right': detectionTemplate}">
            <mat-label>{{'status' | transloco}}</mat-label>
            <mat-select required formControlName="status">
              <mat-option [value]="detectionStatusEnum.TO_BE_CONFIRMED">{{'to_confirm' | transloco}}</mat-option>
              <mat-option [value]="detectionStatusEnum.CONFIRMED">{{'confirmed' | transloco}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="emergency-controls" *ngIf="statusFormControl.value == detectionStatusEnum.CONFIRMED && emergencyTemplate">
          <mat-form-field [ngClass]="{'btn-left': detectionTemplate}">
            <mat-label>{{'emergency_level' | transloco}}</mat-label>
            <mat-select formControlName="emergency">
              <mat-option *ngFor="let item of emergencyTemplate.labels;" value="{{item.priority}}">
                <span style="position: relative;">
                  {{item.label}}
                  <div class="emergency-badge" [style.background-color]="emergencyLevelColor(item.level)"></div>
                </span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>
    </div>

    <history-channel [detectionId]="detection.id"></history-channel>

  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions>
    <div class="left-actions">
      <button mat-icon-button
              matTooltip="{{'delete' | transloco}}"
              (click)="onClickDelete(detection)">delete
      </button>
      <div class="vertical-separator"></div>
      <button mat-icon-button
              matTooltip="{{'move_the_camera_on_the_detection' | transloco}}"
              (click)="onClickFlyTo(detection)">location_on
      </button>
      <button mat-icon-button
              matTooltip="{{'toggle_show' | transloco}}"
              (click)="onClickToggleShow(detection)">
        <mat-icon *ngIf="detection.show">visibility</mat-icon>
        <mat-icon *ngIf="!detection.show">visibility_off</mat-icon>
      </button>
      <button mat-icon-button
              matTooltip="{{'download' | transloco}}"
              [matMenuTriggerFor]="menu">download
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="onClickExportPicture(detection)">{{'export_picture' | transloco}}</button>
        <button mat-menu-item (click)="onClickExportPictureAltered(detection)">{{'export_picture_altered' | transloco}}</button>
<!--        <button mat-menu-item (click)="onClickExportDXF(detection)">{{'export_dxf' | transloco}}</button>-->
<!--        <button mat-menu-item (click)="onClickExportData(detection)">{{'export_data' | transloco}}</button>-->
      </mat-menu>
    </div>
    <button mat-icon-button
            [ngClass]="{'btn-save-enable': formHasChanged, 'btn-save-disable': !formHasChanged}"
            [disabled]="!formHasChanged"
            matTooltip="{{'save_changes' | transloco}}"
            class="material-symbols-outlined"
            (click)="onClickUpdate(detection)">done
    </button>
  </mat-card-actions>
</mat-card>
