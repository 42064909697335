<div class="layer" *ngIf="displayModeExtended"></div>
<mat-card class="container"
          *ngIf="marker && (currentUser$ | async) as currentUser"
          [ngClass]="{'expanded': displayModeExtended}"
          [@displayMode]="displayModeExtended ? 'expanded' : 'collapsed'">
  <mat-card-header>
    <mat-card-title-group>
      <mat-card-title>{{marker.name}}</mat-card-title>
      <mat-card-subtitle>{{marker.center | wgs84}}</mat-card-subtitle>
      <button mat-icon-button
              class="close-button material-symbols-outlined"
              (click)="onClickClose()">close
      </button>
    </mat-card-title-group>
  </mat-card-header>
  <mat-card-content class="content">
    <div [ngClass]="{
        'content-extended': displayModeExtended,
        'content-collapsed' : !displayModeExtended}">

      <div class="pictures"
           [ngClass]="{
           'pictures-expanded': displayModeExtended ,
           'pictures-collapsed': !displayModeExtended}">
        <div *ngIf="referenceBatch"
             class="left" [ngClass]="{
           'picture-border': displayModeExtended,
           'left-expanded': displayModeExtended,
           'left-side-open': displayModeExtended && selectedSplitScreenMode == 0,
           'left-side-close': displayModeExtended && selectedSplitScreenMode == 2}">
          <mat-label *ngIf="displayModeExtended && selectedSplitScreenMode != 2">
            {{referenceBatch.dateAcquired | date:dateFormat(currentUser)}}
          </mat-label>
          <picture #referencePicture
                   [pictureConfig]="referenceBatchPictureConfig"
                   (pictureFromCanvasAvailable)="referenceBatchPictureCanvasAvailable=$event"></picture>
        </div>
        <div class="right"
             [ngClass]="{
           'picture-border': displayModeExtended,
           'right-expanded': displayModeExtended,
           'right-side-open': displayModeExtended && selectedSplitScreenMode == 2,
           'right-side-close': displayModeExtended && selectedSplitScreenMode == 0,
           'full-size': displayModeExtended && !referenceBatch}">
          <mat-label *ngIf="displayModeExtended && selectedSplitScreenMode != 0 && site && referenceBatch">
            {{site.lastBatchDate | date:dateFormat(currentUser)}}
          </mat-label>
          <picture #lastPicture
                   [pictureConfig]="lastBatchPictureConfig"
                   (pictureFromCanvasAvailable)="lastBatchPictureCanvasAvailable=$event"></picture>
        </div>
        <div class="buttons" *ngIf="displayModeExtended && referenceBatch">
          <mat-button-toggle-group (change)="onSplitModeChanged($event.value)"
                                   [hideSingleSelectionIndicator]="true"
                                   [value]="selectedSplitScreenMode">
            <mat-button-toggle [checked]="selectedSplitScreenMode == 0" value="0">
              <mat-icon>splitscreen_left</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle [checked]="selectedSplitScreenMode == 1" value="1">
              <mat-icon>split_scene</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle [checked]="selectedSplitScreenMode == 2" value="2">
              <mat-icon>splitscreen_right</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <div class="history">
        <div *ngIf="currentUser.name != marker.author" class="author">{{'created_by_f' | transloco }} {{marker.author}}</div>
        <tracker-history-channel [trackerId]="marker.id"></tracker-history-channel>
      </div>
    </div>

  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions>
    <div class="left-actions">
      <button *ngIf="currentUser.name == marker.author"
              mat-icon-button
              matTooltip="{{'delete' | transloco}}"
              (click)="onClickDelete(marker)">delete
      </button>
      <div *ngIf="currentUser.name == marker.author" class="vertical-separator"></div>
      <button mat-icon-button
              matTooltip="{{'move_the_camera_on_the_maker' | transloco}}"
              class="material-symbols-outlined"
              (click)="onClickFlyTo(marker)">location_on
      </button>
      <button mat-icon-button
              matTooltip="{{'toggle_show' | transloco}}"
              (click)="onClickToggleShow(marker)">
        <mat-icon *ngIf="marker.show">visibility</mat-icon>
        <mat-icon *ngIf="!marker.show">visibility_off</mat-icon>
      </button>
      <button *ngIf="currentUser.name == marker.author"
              mat-icon-button
              matTooltip="{{'rename' | transloco}}"
              (click)="onClickRename(marker)"><mat-icon>edit</mat-icon></button>
      <button *ngIf="!marker.isShared && currentUser.name == marker.author"
              mat-icon-button
              matTooltip="{{'share' | transloco}}"
              (click)="onClickToggleShare(marker)">
        <mat-icon>share</mat-icon>
      </button>
      <button *ngIf="marker.isShared && currentUser.name == marker.author"
              mat-icon-button
              matTooltip="{{'share_off' | transloco}}"
              (click)="onClickToggleShare(marker)">
        <mat-icon>share_off</mat-icon>
      </button>
      <button mat-icon-button
              matTooltip="{{'download' | transloco}}"
              class="material-symbols-outlined"
              [matMenuTriggerFor]="menu">download
      </button>
      <mat-menu #menu="matMenu">
        <button *ngIf="site" mat-menu-item (click)="onClickExportPicture(marker, site.lastBatch)">{{'export_picture_from' | transloco}} {{site.lastBatchDate | date:dateFormat(currentUser)}}</button>
        <button *ngIf="site" [disabled]="!lastBatchPictureCanvasAvailable" mat-menu-item (click)="onClickExportLastPictureFromCanvas(marker)">{{'export_picture_altered_from' | transloco}} {{site.lastBatchDate | date:dateFormat(currentUser)}}</button>
        <button *ngIf="referenceBatch && displayModeExtended" mat-menu-item (click)="onClickExportPicture(marker, referenceBatch.id)">{{'export_picture_from' | transloco}} {{referenceBatch.dateAcquired | date:dateFormat(currentUser)}}</button>
        <button *ngIf="referenceBatch && displayModeExtended" [disabled]="!referenceBatchPictureCanvasAvailable" mat-menu-item (click)="onClickExportReferencePictureFromCanvas(marker)">{{'export_picture_altered_from' | transloco}} {{referenceBatch.dateAcquired | date:dateFormat(currentUser)}}</button>
      </mat-menu>
    </div>
    <span class="spacer"></span>
    <button *ngIf="!displayModeExtended"
            mat-icon-button
            matTooltip="{{'extend' | transloco}}"
            (click)="onClickToggleExtend()">
      <mat-icon>open_in_full</mat-icon>
    </button>
    <button *ngIf="displayModeExtended"
            mat-icon-button
            matTooltip="{{'collapse' | transloco}}"
            (click)="onClickToggleExtend()">
      <mat-icon>close_fullscreen</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
