import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges, OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  PanZoomAPI,
  PanZoomComponent,
  PanZoomConfig,
  PanZoomConfigOptions
} from 'ngx-panzoom';
import { first} from 'rxjs';
import { FileTools } from '../../../tools/file.tools';



export interface PictureConfig{
  url: string | undefined;
  circle: PictureCircle | undefined;
}

export interface PictureCircle {
  x: number;
  y: number;
  r: number;
}


@Component({
  selector: 'picture',
  templateUrl: './picture.component.html',
  styleUrls: ['./picture.component.scss'],
})
export class PictureComponent implements OnChanges, OnInit {

  @Input() pictureConfig: PictureConfig | undefined;
  @Output() pictureFromCanvasAvailable: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('panzoom') panzoomRef: PanZoomComponent | undefined;
  @ViewChild('canvas') canvasRef: ElementRef<HTMLCanvasElement> | undefined;
  imageIsLoaded: boolean = true;
  noImage: boolean = false;

  private options: PanZoomConfigOptions = {
    zoomLevels: 10,
  };
  panZoomConfig: PanZoomConfig = new PanZoomConfig(this.options);

  private resizeObserver: ResizeObserver | undefined;

  constructor(private host: ElementRef) {

  }

  ngOnInit() {
    this.resizeObserver = new ResizeObserver(entries => {
      // this.center();
    });
    this.resizeObserver.observe(this.host.nativeElement);
  }

  ngOnDestroy() {
    this.resizeObserver?.unobserve(this.host.nativeElement);
  }

  private get canvas(): any | undefined {
      return this.canvasRef?.nativeElement;
    }

  private loadImage(url: string): void {
    this.imageIsLoaded = false;
    this.noImage = false;
    this.clearCanvas();
    const image = new Image();

    image.onload = () => {
      if (this.canvas) {
        this.canvas.setAttribute('width', image.naturalWidth +'px');
        this.canvas.setAttribute('height', image.naturalHeight+'px');
        const ctx = this.canvas.getContext('2d')
        if (ctx) {
          ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight);
          if (this.pictureConfig && this.pictureConfig.circle) {
            ctx.beginPath();
            ctx.arc(this.pictureConfig.circle.x,
              this.pictureConfig.circle.y,
              this.pictureConfig.circle.r,
              0,
              2 * Math.PI);
            ctx.lineWidth = 4;
            ctx.strokeStyle = "red";
            ctx.stroke();
          }
        }
      }

      this.panZoomConfig.api
        .pipe(first())
        .subscribe( (api: PanZoomAPI) => {
          if (this.pictureConfig && this.pictureConfig.circle) {
            this.center();
            setTimeout(()=>{
              this.imageIsLoaded = true;
              this.noImage = false;
              this.pictureFromCanvasAvailable.next(true);
            }, 500);
          }
          else {
            this.imageIsLoaded = true;
            this.noImage = false;
            this.pictureFromCanvasAvailable.next(true);
          }
        });
    };

    fetch(url)
      .then(response => {
        response.blob().then((data: Blob) => {
          FileTools.pFileReader(data).then(event => {
            const reader = event?.target;
            image.src = (typeof (reader.result) === 'string') ? reader.result : '';
          }, (err) => {
            this.imageIsLoaded = false;
            this.noImage = true;
          });
        });
      })
      .catch((err: any) => {
        this.imageIsLoaded = false;
        this.noImage = true;
      });

  }

  private clearCanvas(): void {
    if(this.canvas) {
      const ctx = this.canvas.getContext('2d')
      if (ctx) {
        const width = this.canvas.width;
        const height = this.canvas.height;
        ctx.clearRect(0, 0, width, height);
      }
    }
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pictureConfig']) {
      const newPictureConfig = changes['pictureConfig'].currentValue;
      this.configure(newPictureConfig);
    }
  }

  configure(pictureConfig: PictureConfig | undefined): void {
    this.pictureFromCanvasAvailable.next(false);
    if (pictureConfig && pictureConfig.url) {
      this.loadImage(pictureConfig.url);
    }
    else {
      this.noimage();
    }
  }

  reset(): void {
    this.noImage = false;
    this.imageIsLoaded = false;
    this.clearCanvas();
  }

  noimage(): void {
    this.imageIsLoaded = true;
    this.noImage = true;
    this.clearCanvas();
  }

  canvasTopPNG(): any {
    if (this.canvas) {
      const dt = this.canvas.toDataURL('image/png');
      return dt.replace(/^data:image\/[^;]/, 'data:application/octet-stream');
    }
    return undefined;
  }

  center(): void {
    this.panZoomConfig.api
      .pipe(first())
      .subscribe( (api: PanZoomAPI) => {
        if (this.pictureConfig && this.pictureConfig.circle) {
          // const factor = 4;
          // const x: number = this.pictureConfig.circle.x - (this.pictureConfig.circle.r * factor);
          // const y: number = this.pictureConfig.circle.y - (this.pictureConfig.circle.r * factor);
          // const width: number = this.pictureConfig.circle.r * 2 * factor;
          // const height: number = this.pictureConfig.circle.r * 2 * factor;
          // api.zoomToFit({
          //   x,
          //   y,
          //   width,
          //   height,
          // });
          api.panToPoint({
            x: this.pictureConfig.circle.x,
            y: this.pictureConfig.circle.y
          });
        }
      });
  }
}
