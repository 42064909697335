<div class="container">

  <div cesium #cesium [handleKeyboard]="mapHandleKeyboard"></div>
  <div *ngIf="selectedSite && referenceBatch && (selectedViewRepresentation == viewRepresentation.SPLITTED_TEXTURED || selectedViewRepresentation == viewRepresentation.SPLITTED_POINT_CLOUD)"
       split-view-slider [scene]="scene"
       class="slider">
    <div class="info info-left">{{referenceBatch.dateAcquired | date:dateFormat}}</div>
    <div class="info info-right">{{selectedSite.lastBatchDate | date:dateFormat}}</div>
  </div>

  <compute-loader></compute-loader>

  <div *ngIf="selectedSite" class="view-configuration"
       [ngClass]="{'view-configuration-extended': selectedViewRepresentation == viewRepresentation.DETECTIONS || selectedViewRepresentation == viewRepresentation.VOLUMES}">
    <view-configuration></view-configuration>
    <volume-color-ramp *ngIf="selectedSite
  && selectedViewRepresentation == viewRepresentation.VOLUMES
  && (selectedSite | module: [inspectionModule.PERREAD])">
    </volume-color-ramp>

    <tileset-diff-color-ramp *ngIf="selectedSite
  && selectedViewRepresentation == viewRepresentation.DIFFERENTIAL
  && (selectedTilesetConfigId$ | async)">
    </tileset-diff-color-ramp>
    <detection-header *ngIf="selectedViewRepresentation == viewRepresentation.DETECTIONS"></detection-header>
    <detection-list class="list" *ngIf="selectedViewRepresentation == viewRepresentation.DETECTIONS"></detection-list>

    <volume-list class="list" *ngIf="selectedViewRepresentation == viewRepresentation.VOLUMES"></volume-list>
  </div>

  <div *ngIf="selectedSite" class="entities">
    <draw-entities-toolbar [site]="selectedSite"></draw-entities-toolbar>
  </div>

  <detection-details></detection-details>
  <volume-details></volume-details>
  <measure-details></measure-details>
  <cut-details></cut-details>
  <marker-details></marker-details>
  <elevation-details></elevation-details>

</div>
