import { Component, HostListener, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TrackerElevation} from '../../../../models/tracker';
import { selectVolume } from '../../../../features/volume/volume.actions';
import {
  selectDetection
} from '../../../../features/detection/detection.actions';
import {
  selectCut
} from '../../../../features/cut/cut.actions';
import {
  selectMeasure} from '../../../../features/measure/measure.actions';
import { MatDialog } from '@angular/material/dialog';
import {
  DeleteConfirmDialog
} from '../../../dialogs/delete-confirm-dialog/delete-confirm.dialog';
import { RenameDialog } from '../../../dialogs/rename-dialog/rename.dialog';
import {
  setCameraConfiguration,
  setCameraMode,
  setMapHandleKeyboard
} from '../../../../features/cesium/cesium.actions';
import { ExportTools } from '../../../../tools/export.tools';
import { CesiumTools } from '../../../../tools/cesium.tools';
import { CameraMode } from '../../../../enums/camera';
import { selectCurrentSite } from '../../../../features/site/site.selectors';
import { first, Observable } from 'rxjs';
import { Site } from '../../../../models/site';
import { ApiService } from '../../../../services/api.service';
import { TranslocoService } from '@jsverse/transloco';
import { User } from '../../../../models/user';
import { selectUser } from '../../../../features/user/user.selectors';
import {
  ElevationChartComponent
} from '../../charts/elevation-chart.component';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  selectCurrentTrackerElevation
} from '../../../../features/tracker/tracker.selectors';
import {
  deleteTracker, deleteTrackerSuccess, selectTrackerElevation,
  selectTrackerImage, setTrackerShow, updateTracker
} from '../../../../features/tracker/tracker.actions';
import { Actions, ofType } from '@ngrx/effects';


@Component({
  selector: 'elevation-details',
  templateUrl: './elevation-details.component.html',
  styleUrls: ['../details.component.scss', './elevation-details.component.scss'],
  animations: [
    trigger('displayMode', [
      state('expanded', style({
        right: '10px',
      })),
      state('collapsed', style({
        width: '400px',
      })),
      transition('expanded => collapsed', [
        animate('0ms ease-out'),
      ]),
      transition('collapsed => expanded', [
        animate('0ms ease-out'),
      ])
    ]),
  ],
})
export class ElevationDetailsComponent {

  displayModeExtended: boolean = false;
  elevation: TrackerElevation | null | undefined;
  @ViewChild('graph') graph: ElevationChartComponent | undefined;

  constructor(private store: Store,
              private actions: Actions,
              private api: ApiService,
              private dialog: MatDialog,
              private translocoService: TranslocoService) {
    this.store.select(selectCurrentTrackerElevation)
      .pipe(takeUntilDestroyed())
      .subscribe((elevation: TrackerElevation | null | undefined) => {
        this.elevation = elevation;
        if (this.elevation) {
          this.store.dispatch(selectMeasure({id: undefined}));
          this.store.dispatch(selectVolume({id: undefined}));
          this.store.dispatch(selectDetection({id: undefined}));
          this.store.dispatch(selectCut({id: undefined}));
          this.store.dispatch(selectTrackerImage({id: undefined}));
        }
        else {
          this.displayModeExtended = false;
        }
      });

    this.actions
      .pipe(ofType(deleteTrackerSuccess), takeUntilDestroyed())
      .subscribe((result: any) => {
        this.displayModeExtended = false;
      });
  }

  private resize(): void {
    setTimeout(()=>{
      if (this.graph) this.graph.resize();
    }, 1);
  }

  onHandleFocus(): void {
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: false}));
  }

  onFocusLeave(): void {
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: true}));
  }

  onClickFlyTo(elevation: TrackerElevation): void {
    const cameraConfiguration = CesiumTools.cameraConfigurationFromTargetAndDirection(elevation.center, elevation.cameraDirection, elevation.length);
    if (!cameraConfiguration) return;
    this.store.dispatch(setCameraMode({cameraMode: CameraMode.MODE_3D}));
    this.store.dispatch(setCameraConfiguration({cameraConfiguration}));
  }

  onClickClose(): void {
    this.store.dispatch(selectTrackerElevation({id: undefined}));
  }

  onClickExportData(elevation: TrackerElevation): void {
    ExportTools.exportJSON(elevation.name, JSON.stringify(elevation));
  }

  onClickDelete(elevation: TrackerElevation): void {
    const deleteDialogRef = this.dialog.open(DeleteConfirmDialog, {
      disableClose: false
    });
    deleteDialogRef.componentInstance.message = this.translocoService.translate("would_you_like_to_delete", {name: elevation.name});
    deleteDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.store.select(selectCurrentSite)
          .pipe(first())
          .subscribe((site: Site | null | undefined) => {
            if (site) {
              this.store.dispatch(deleteTracker({
                organizationId: site.organization,
                siteId: site.id,
                trackerId: elevation.id
              }));
            }
          });
      }
    });
  }

  onClickRename(elevation: TrackerElevation): void {
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: false}));
    const renameDialogRef = this.dialog.open(RenameDialog, {
      disableClose: false
    });
    renameDialogRef.componentInstance.placeholder = elevation.name;
    renameDialogRef.afterClosed().subscribe(result => {
      this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: true}));
      if(result && this.elevation) {
        this.store.select(selectCurrentSite)
          .pipe(first())
          .subscribe((site: Site | null | undefined) => {
            if (site) {
              this.store.dispatch(updateTracker({
                organizationId: site.organization,
                siteId: site.id,
                trackerId: elevation.id,
                payload: {
                  name: result,
                  is_shared: elevation.isShared
                }}));
            }
          });
      }
    });
  }

  onClickToggleShare(elevation: TrackerElevation): void {
    this.store.select(selectCurrentSite)
      .pipe(first())
      .subscribe((site: Site | null | undefined) => {
        if (site) {
          this.store.dispatch(updateTracker({
            organizationId: site.organization,
            siteId: site.id,
            trackerId: elevation.id,
            payload: {
              name: elevation.name,
              is_shared: !elevation.isShared
            }}));
        }
      });
  }

  onClickToggleExtend(): void {
    this.displayModeExtended = !this.displayModeExtended;
  }

  onClickToggleShow(elevation: TrackerElevation): void {
    elevation.show = !elevation.show;
    this.store.dispatch(setTrackerShow({id: elevation.id, show: elevation.show}));
  }

  get currentUser$(): Observable<User | undefined> {
    return this.store.select(selectUser)
  }

  onAnimationDone(): void {
    this.resize();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: any) {
    this.resize();
  }
}
