import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as DetectionActions from './detection.actions';
import { Deserialize } from 'cerialize';
import { Detection } from '../../models/detection';
import { ApiService } from '../../services/api.service';
import { ColorTools } from '../../tools/color.tools';
import { EmergencyTemplate } from '../../models/emergency-template';
import { DetectionTemplate } from '../../models/detection-template';
import { Comment } from '../../models/comment';
import { TranslocoService } from '@jsverse/transloco';
import { DetectionSummary } from '../../models/site';


@Injectable()
export class DetectionEffects {

  loadDetections$ = createEffect(() => {
    return this.actions$.pipe(ofType(DetectionActions.loadDetections),
      concatMap(({organizationId, siteId, batchId}) =>
      this.api.detections$(organizationId, siteId, batchId).pipe(
        map(detections => detections.map((detection: any) => {
          const obj: Detection = Deserialize(detection, Detection);
          obj.name = detection.label_translated + " " + obj.pool.slice(0,4).toUpperCase();
          return obj;
        })),
        map(detections => ColorTools.setColorsForDetections(detections)),
        map(detections => DetectionActions.loadDetectionsSuccess({ detections })),
        catchError(error => of(DetectionActions.loadDetectionsFailure({ error }))))
    ));
  });

  loadDetectionEmergencyTemplate$ = createEffect(() => {
    return this.actions$.pipe(ofType(DetectionActions.loadDetectionEmergencyTemplate),
      concatMap(({organizationId, siteId}) =>
        this.api.detectionEmergencyTemplate$(organizationId, siteId).pipe(
          map(template => Deserialize(template, EmergencyTemplate)),
          map(template => DetectionActions.loadDetectionEmergencyTemplateSuccess({ template })),
          catchError(error => of(DetectionActions.loadDetectionEmergencyTemplateFailure({ error }))))
      ));
  });

  addDetectionEmergency$ = createEffect(() => {
    return this.actions$.pipe(ofType(DetectionActions.addDetectionEmergency),
      concatMap(({organizationId, siteId, batchId, detectionId, payload}) =>
        this.api.addDetectionEmergency$(organizationId, siteId, batchId, detectionId, payload).pipe(
          map(detection => ColorTools.setEmergencyColors(Deserialize(detection, Detection))),
          map(detection => {
            return {id: detection.id, changes: detection};
          }),
          map(update => DetectionActions.addDetectionEmergencySuccess({ update })),
          catchError(error => of(DetectionActions.addDetectionEmergencyFailure({ error }))))
      ));
  });

  updateDetection$ = createEffect(() => {
    return this.actions$.pipe(ofType(DetectionActions.updateDetection),
      concatMap(({organizationId, siteId, batchId, detectionId, payload}) =>
        this.api.updateDetection$(organizationId, siteId, batchId, detectionId, payload).pipe(
          map(detection => ColorTools.setEmergencyColors(Deserialize(detection, Detection))),
          map(detection => {
            return {id: detection.id, changes: detection};
          }),
          map(update => DetectionActions.updateDetectionSuccess({ update })),
          catchError(error => of(DetectionActions.updateDetectionFailure({ error }))))
      ));
  });

  deleteDetection$ = createEffect(() => {
    return this.actions$.pipe(ofType(DetectionActions.deleteDetection),
      concatMap(({organizationId, siteId, batchId, detectionId, payload}) =>
        this.api.updateDetection$(organizationId, siteId, batchId, detectionId, payload).pipe(
          map(detection => DetectionActions.deleteDetectionSuccess({ id: detection.id })),
          catchError(error => of(DetectionActions.deleteDetectionFailure({ error }))))
      ));
  });

  addDetection$ = createEffect(() => {
    return this.actions$.pipe(ofType(DetectionActions.addDetection),
      concatMap(({organizationId, siteId, batchId, payload}) =>
        this.api.addDetection$(organizationId, siteId, batchId, payload).pipe(
          map(detection => {
            const obj: Detection = Deserialize(detection, Detection);
            obj.name = detection.label_translated + " " + obj.pool.slice(0,4).toUpperCase();
            return obj;
          }),
          map(detection => ColorTools.setColorsForDetection(detection)),
          map((detection:Detection) => DetectionActions.addDetectionSuccess({ detection })),
          catchError(error => of(DetectionActions.addDetectionFailure({ error }))))
      ));
  });

  loadDetectionTemplate$ = createEffect(() => {
    return this.actions$.pipe(ofType(DetectionActions.loadDetectionTemplate),
      concatMap(({organizationId, siteId}) =>
        this.api.detectionTemplate$(organizationId, siteId).pipe(
          map(template => Deserialize(template, DetectionTemplate)),
          map(template => DetectionActions.loadDetectionTemplateSuccess({ template })),
          catchError(error => of(DetectionActions.loadDetectionTemplateFailure({ error }))))
      ));
  });

  loadDetectionComments$ = createEffect(() => {
    return this.actions$.pipe(ofType(DetectionActions.loadDetectionComments),
      concatMap(({detectionId}) =>
        this.api.detectionComments$(detectionId).pipe(
          map(comments => comments.map((comment: any) => Deserialize(comment, Comment))),
          map(comments => DetectionActions.loadDetectionCommentsSuccess({ comments })),
          catchError(error => of(DetectionActions.loadDetectionCommentsFailure({ error }))))
      ));
  });

  addDetectionComment$ = createEffect(() => {
    return this.actions$.pipe(ofType(DetectionActions.addDetectionComment),
      concatMap(({detectionId, payload}) =>
        this.api.addDetectionComment$(detectionId, payload).pipe(
          map(comment => Deserialize(comment, Comment)),
          map(comment => DetectionActions.addDetectionCommentSuccess({ comment })),
          catchError(error => of(DetectionActions.addDetectionCommentFailure({ error }))))
      ));
  });

  loadDetectionSummary$ = createEffect(() => {
    return this.actions$.pipe(ofType(DetectionActions.loadDetectionSummary),
      concatMap(({organizationId, siteId, batchId}) =>
        this.api.detectionsSummary$(organizationId, siteId, batchId).pipe(
          map(summary => Deserialize(summary, DetectionSummary)),
          map(summary => DetectionActions.loadDetectionSummarySuccess({ summary })),
          catchError(error => of(DetectionActions.loadDetectionSummaryFailure({ error }))))
      ));
  });

  constructor(private actions$: Actions,
              private api: ApiService) {}

}
