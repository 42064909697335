import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ApiService } from '../../services/api.service';
import * as ReportActions from './report.actions';


@Injectable()
export class ReportEffects {

  generateDetectionReport$ = createEffect(() => {
    return this.actions$.pipe(ofType(ReportActions.generateDetectionReport),
      concatMap(({organizationId, siteId, formats}) =>
      this.api.generateDetectionReport$(organizationId, siteId, formats).pipe(
        map(email => ReportActions.generateDetectionReportSuccess({ email })),
        catchError(error => of(ReportActions.generateDetectionReportFailure({ error }))))
    ));
  });

  generateCutReport$ = createEffect(() => {
    return this.actions$.pipe(ofType(ReportActions.generateCutReport),
      concatMap(({organizationId, siteId, formats}) =>
        this.api.generateCutReport$(organizationId, siteId, formats).pipe(
          map(email => ReportActions.generateCutReportSuccess({ email })),
          catchError(error => of(ReportActions.generateCutReportFailure({ error }))))
      ));
  });

  constructor(private actions$: Actions, private api: ApiService) {}

}
