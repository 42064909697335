import { FileTools } from './file.tools';

export class ExportTools {

  static exportJSON(filename: string, json: string) {
    const createEl = document.createElement('a');
    createEl.href = 'data:application/json;charset=utf-8,' + encodeURIComponent(json);
    createEl.download = filename.replace(/ /g,"_") + '.json';
    createEl.click();
    createEl.remove();
  }

  static exportDXF(filename: string, text: any) {
    const createEl = document.createElement('a');
    createEl.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(text);
    createEl.download = filename.replace(/ /g,"_") + '.dxf';
    createEl.click();
    createEl.remove();
  }

  static exportCSV(filename: string, text: any) {
    const createEl = document.createElement('a');
    createEl.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(text);
    createEl.download = filename.replace(/ /g,"_") + '.csv';
    createEl.click();
    createEl.remove();
  }

  static exportPNG(filename: string, data: any) {
    const createEl = document.createElement('a');
    createEl.href = data;
    createEl.download = filename.replace(/ /g,"_") + '.png';
    createEl.click();
    createEl.remove();
  }

  static exportResourceUrl(url: string) {
    const createEl = document.createElement('a');
    createEl.href = url;
    createEl.click();
    createEl.remove();
  }

  static exportImageUrlWithBoundingBox(url: string, filename: string, extension: string, bb: any, color: string, callback: any) {
    const canvasEl = document.createElement('canvas');

    function resizeCanvas(originalCanvas: any, maxWidth: number, maxHeight: number) {
      const tmpCanvas = document.createElement('canvas');
      const ctx = tmpCanvas.getContext('2d');
      if (ctx) {
        const ratio = Math.min(maxWidth / originalCanvas.width, maxHeight / originalCanvas.height);
        tmpCanvas.width = originalCanvas.width * ratio;
        tmpCanvas.height = originalCanvas.height * ratio;
        ctx.drawImage(originalCanvas, 0, 0, tmpCanvas.width, tmpCanvas.height);
      }
      return tmpCanvas;
    }

    const image = new Image();
    image.onload = () => {
      canvasEl.setAttribute('width', image.naturalWidth + 'px');
      canvasEl.setAttribute('height', image.naturalHeight + 'px');
      const ctx = canvasEl.getContext('2d')
      if (ctx) {
        ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight);
        ctx.lineWidth = 10;
        ctx.strokeStyle = color;
        ctx.strokeRect(bb.x, bb.y, bb.with, bb.height);
        const resizedCanvas = resizeCanvas(canvasEl, image.naturalWidth / 2.0, image.naturalHeight / 2.0);
        resizedCanvas.toBlob((blob) => {
          if (blob) {
            const link = document.createElement('a');
            link.download = filename.replace(/ /g, "_")
            link.href = URL.createObjectURL(blob);
            link.click();
            callback(true);
          }
          else {
            callback(false);
          }
        }, 'image/'+extension.toLowerCase());
      }
    }

    fetch(url)
      .then(response => {
        response.blob().then((data: Blob) => {
          FileTools.pFileReader(data).then(event => {
            const reader = event?.target;
            image.src = (typeof (reader.result) === 'string') ? reader.result : '';
          }, (err) => {
            callback(false);
          });
        });
      })
      .catch((err: any) => {
        callback(false);
      });
  }
}
