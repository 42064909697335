import { createAction, props } from '@ngrx/store';

export const reportWithData = createAction('[Report/API] Report with data', props<{ data: any }>());
export const reportRequireAllData = createAction('[Report/API] Report require all data');

export const generateDetectionReport = createAction('[Report/API] Generate Detection Report ', props<{ organizationId: string, siteId: string, formats: Array<string>}>());
export const generateDetectionReportSuccess = createAction('[Report/API] Generate Detection Report success', props<{ email: string }>());
export const generateDetectionReportFailure = createAction('[Report/API] Generate Detection Report failure', props<{ error: any }>());

export const generateCutReport = createAction('[Report/API] Generate Cut Report ', props<{ organizationId: string, siteId: string, formats: Array<string>}>());
export const generateCutReportSuccess = createAction('[Report/API] Generate Cut Report success', props<{ email: string }>());
export const generateCutReportFailure = createAction('[Report/API] Generate Cut Report failure', props<{ error: any }>());
