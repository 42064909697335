import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

(window as Record<string, any>)['CESIUM_BASE_URL'] = '/assets/cesium/';

window.addEventListener('unhandledrejection', event => {
  if (event.reason === undefined) {
    console.warn('⚠️ Unhandled rejection with undefined'); // plus discret
  }
});

Sentry.init({
  dsn: "https://e47accbbd203ea7402067657f69fda10@o4507548662038528.ingest.de.sentry.io/4507549501227088",
  ignoreErrors: [
    'fb_xd_fragment',
    'Script error.',
    'Script error',
    /ResizeObserver loop (limit|completed)/,
    /^chrome-extension:/,
    /^moz-extension:/,
    'NetworkError when attempting to fetch resource.',
    'WebSocket is already in CLOSING or CLOSED state',
    /Handled unknown error/,
    'Non-Error exception: undefined or null',
  ],
  // Ignorer les erreurs provenant d'URLs spécifiques
  denyUrls: [
    // Les extensions Chrome ou Firefox
    /^chrome-extension:\/\//,
    /^moz-extension:\/\//,
    // Certains domaines d'annonces ou trackers
    /.*\/ads\/.*/,
    /.*doubleclick\.net.*/,
    /.*google-analytics\.com.*/,
    /.*fbcdn\.net.*/,
  ],
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  enabled: environment.production,
  debug: !environment.production,
  // Performance Monitoring
  tracesSampleRate: environment.production ? 0.2 : 1.0,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: (environment.production) ? 0.1 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

enableProdMode();
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
