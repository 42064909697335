<div *ngIf="(markers$ | async) as markers" class="container">
  <mat-spinner *ngIf="isLoading" strokeWidth="4"></mat-spinner>
  <div *ngIf="markers.length == 0 && !isLoading" class="info">
    {{'used' | transloco}} <mat-icon>radio_button_checked</mat-icon> {{'placeholder_list_marker' | transloco}}
  </div>
  <item-list-header *ngIf="markers.length && !isLoading"
                    [hasSelectedItems]="selectedItemIds.length > 0"
                    [allItemsSelected]="markers.length == selectedItemIds.length"
                    (allCheckboxStateChanged)="allCheckboxStateChanged($event)"
                    (clickDelete)="clickDelete()"
                    (clickShow)="clickShow()"
                    (clickHide)="clickHide()"
                    (clickShare)="clickShare()"
                    (clickShareOff)="clickSareOff()"></item-list-header>
  <div class="list">
    <div class="item" *ngFor="let item of markers;">
      <mat-checkbox
        [checked]="itemIsSelected(item.id)"
        (change)="onClickUpdateSelectedItemIds($event.checked, item.id)"> </mat-checkbox>
      <marker-card class="card" [marker]="item"></marker-card>
    </div>
  </div>
</div>
