import { Component } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Actions, ofType } from '@ngrx/effects';
import {
  addMeasureArea,
  addMeasureDistance,
  addMeasureFailure,
  addMeasureSuccess
} from '../../../../features/measure/measure.actions';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  addCut,
  addCutFailure,
  addCuts,
  addCutsFailure,
  addCutsSuccess,
  addCutSuccess,
  exportCutsBookDXF,
  exportCutsBookDXFFailure,
  exportCutsBookDXFSuccess
} from '../../../../features/cut/cut.actions';
import {
  addTracker,
  addTrackerFailure,
  addTrackerSuccess,
  addTrackerImages,
  addTrackerImagesFailure,
  addTrackerImagesSuccess,
  addTrackerElevations,
  addTrackerElevationsSuccess,
  addTrackerElevationsFailure
} from '../../../../features/tracker/tracker.actions';
import {
  addDetection, addDetectionFailure, addDetectionSuccess
} from '../../../../features/detection/detection.actions';
import { Store } from '@ngrx/store';
import {
  selectConfigIsDownloadingPicture
} from '../../../../features/config/config.selectors';

@Component({
  selector: 'compute-loader',
  templateUrl: './compute-loader.component.html',
  styleUrls: ['./compute-loader.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      state('*', style({top: '-100px'})),
      state('in', style({
        top: '10px'
      })),
      state('out', style({
        top: '-100px'
      })),
      transition('in => out', [
        animate('150ms ease-out'),
      ]),
      transition('out => in', [
        animate('150ms ease-out'),
      ])
    ])
  ]
})
export class ComputeLoaderComponent {
  isComputing: boolean = false;

  constructor(private store: Store,private actions: Actions) {
    this.initHandleActions();
    this.store.select(selectConfigIsDownloadingPicture)
      .pipe(takeUntilDestroyed())
      .subscribe((isDownloading: boolean) => {
        this.isComputing = isDownloading;
    });
  }

  private initHandleActions() {
    this.actions.pipe(
      ofType(
        addDetection,
        addMeasureDistance,
        addMeasureArea,
        addCut,
        addCuts,
        addTracker,
        addTrackerImages,
        addTrackerElevations,
        exportCutsBookDXF),
      takeUntilDestroyed())
      .subscribe(result => {
        this.isComputing = true;
      });

    this.actions.pipe(
      ofType(
        addDetectionSuccess, addDetectionFailure,
        addMeasureSuccess, addMeasureFailure,
        addCutSuccess, addCutFailure,
        addCutsSuccess, addCutsFailure,
        addTrackerSuccess, addTrackerFailure,
        addTrackerImagesSuccess, addTrackerImagesFailure,
        addTrackerElevationsSuccess, addTrackerElevationsFailure,
        exportCutsBookDXFSuccess, exportCutsBookDXFFailure),
      takeUntilDestroyed())
      .subscribe(result => {
        this.isComputing = false;
      });
  }
}
