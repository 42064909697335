<mat-card *ngIf="elevation"
          class="card-elevation"
          [style.border]=border
          [ngClass]="{'selected': elevation.id == (selectedItem$ | async)?.id}"
          (click)="onClickEdit(elevation)">

  <mat-card-header style="padding-bottom: 16px;">
    <mat-card-title>{{elevation.name}}</mat-card-title>
  </mat-card-header>

  <div  *ngIf="(currentUser$ | async) as currentUser" class="shared-item-indicators">
    <mat-icon *ngIf="!elevation.show">visibility_off</mat-icon>
    <mat-icon *ngIf="elevation.isShared && currentUser.name == elevation.author">person</mat-icon>
    <mat-icon *ngIf="elevation.isShared">share</mat-icon>
  </div>
</mat-card>



