import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Comment } from '../../models/comment';
import { Tracker, TrackerElevation, TrackerImage } from '../../models/tracker';
import { TrackerType } from '../../enums/tracker';


export const selectTrackerImage = createAction('[Tracker] Select TrackerImage', props<{ id: string | undefined}>());
export const selectTrackerElevation = createAction('[Tracker] Select TrackerElevation', props<{ id: string | undefined}>());

export const loadTrackersImage = createAction('[Tracker/API] Load Trackers Image ', props<{ organizationId: string, siteId: string}>());
export const loadTrackersImageSuccess = createAction('[Tracker/API] Load Trackers Image  success', props<{ trackers: TrackerImage[] }>());
export const loadTrackersImageFailure = createAction('[Tracker/API] Load Trackers Image  failure', props<{ error: any }>());

export const loadTrackersElevation = createAction('[Tracker/API] Load Trackers Elevation ', props<{ organizationId: string, siteId: string}>());
export const loadTrackersElevationSuccess = createAction('[Tracker/API] Load Trackers Elevation  success', props<{ trackers: TrackerElevation[] }>());
export const loadTrackersElevationFailure = createAction('[Tracker/API] Load Trackers Elevation  failure', props<{ error: any }>());

export const addTracker = createAction('[Tracker] Add Tracker ', props<{ organizationId: string, siteId: string, point: Array<number>, trackerType: TrackerType}>());
export const addTrackerSuccess = createAction('[Tracker/API] Add Tracker success', props<{ tracker: Tracker}>());
export const addTrackerFailure = createAction('[Tracker/API] Add Tracker failure', props<{ error: any }>());


export const updateTracker = createAction('[Tracker/API] Update Tracker ', props<{ organizationId: string, siteId: string, trackerId: string, payload: any }>());
export const updateTrackerSuccess = createAction('[Tracker/API] Update Tracker success', props<{ update: Update<any> }>());
export const updateTrackerFailure = createAction('[Tracker/API] Update Tracker failure', props<{ error: any }>());

export const deleteTracker = createAction('[Tracker] Delete Tracker', props<{ organizationId: string, siteId: string, trackerId: string }>());
export const deleteTrackerSuccess = createAction('[Tracker/API] Delete Tracker success', props<{ id: string }>());
export const deleteTrackerFailure = createAction('[Tracker/API] Delete Tracker failure', props<{ error: any }>());

export const clearAllTrackers = createAction('[Tracker] Clear All Trackers');

export const loadTrackerComments = createAction('[Tracker/API] Load Tracker Comments ', props<{ organizationId: string, siteId: string, trackerId: string  }>());
export const loadTrackerCommentsSuccess = createAction('[Tracker/API] Load Tracker Comments success', props<{ comments: Array<Comment> }>());
export const loadTrackerCommentsFailure = createAction('[Tracker/API] Load Tracker Comments failure', props<{ error: any }>());

export const addTrackerComment = createAction('[Tracker/API] Add Tracker Comment ', props<{ organizationId: string, siteId: string, trackerId: string, payload: any }>());
export const addTrackerCommentSuccess = createAction('[Tracker/API] Add Tracker Comment success', props<{ comment: Comment }>());
export const addTrackerCommentFailure = createAction('[Tracker/API] Add Tracker Comment failure', props<{ error: any }>());

export const setTrackerShow = createAction('[Tracker] Set Tracker Show property', props<{ id: string, show: boolean}>());
export const setTrackersShow = createAction('[Tracker/API] Set Trackers Show property', props<{ ids: Array<string>, show: boolean }>());

export const addTrackerImages = createAction('[Tracker/API] Add Tracker Images', props<{ organizationId: string, siteId: string, trackers: any}>());
export const addTrackerImagesSuccess = createAction('[Tracker/API] Add Tracker Images success', props<{ trackers: Array<TrackerImage> }>());
export const addTrackerImagesFailure = createAction('[Tracker/API] Add Tracker Images failure', props<{ error: any }>());

export const addTrackerElevations = createAction('[Tracker/API] Add Tracker Elevaltions', props<{ organizationId: string, siteId: string, trackers: any }>());
export const addTrackerElevationsSuccess = createAction('[Tracker/API] Add Tracker Elevaltions success', props<{ trackers: Array<TrackerElevation> }>());
export const addTrackerElevationsFailure = createAction('[Tracker/API] Add Tracker Elevaltions failure', props<{ error: any }>());


export const exportTrackersImageCSV = createAction('[Tracker] Export Trackers Image CSV', props<{ organizationId: string, siteId: string}>());
export const exportTrackersImageCSVSuccess = createAction('[Tracker/API] Export Trackers Image CSV success', props<{ csv: string}>());
export const exportTrackersImageCSVFailure = createAction('[Tracker/API] Export Trackers Image CSV failure', props<{ error: any }>());

export const exportTrackersElevationCSV = createAction('[Tracker] Export Trackers Elevation CSV', props<{ organizationId: string, siteId: string}>());
export const exportTrackersElevationCSVSuccess = createAction('[Tracker/API] Export Trackers Elevation CSV success', props<{ csv: string}>());
export const exportTrackersElevationCSVFailure = createAction('[Tracker/API] Export Trackers Elevation CSV failure', props<{ error: any }>());


export const deleteTrackers = createAction('[Tracker] Delete Trackers', props<{ organizationId: string, siteId: string, ids: Array<string> }>());
export const deleteTrackersSuccess = createAction('[Tracker/API] Delete Trackers success', props<{ ids: Array<string> }>());
export const deleteTrackersFailure = createAction('[Tracker/API] Delete Trackers failure', props<{ error: any }>());

export const shareTrackers = createAction('[Tracker] Share Trackers', props<{ organizationId: string, siteId: string, ids: Array<string> }>());
export const shareTrackersSuccess = createAction('[Tracker/API] Share Trackers success', props<{ ids: Array<string> }>());
export const shareTrackersFailure = createAction('[Tracker/API] Share Trackers failure', props<{ error: any }>());

export const shareOffTrackers = createAction('[Tracker] Share Off Trackers', props<{ organizationId: string, siteId: string, ids: Array<string> }>());
export const shareOffTrackersSuccess = createAction('[Tracker/API] Share Off Trackers success', props<{ ids: Array<string> }>());
export const shareOffTrackersFailure = createAction('[Tracker/API] Share Off Trackers failure', props<{ error: any }>());
