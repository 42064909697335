import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectAllCuts,
} from '../../../../features/cut/cut.selectors';
import { Cut } from '../../../../models/cut';
import { first, Observable } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import {
  deleteCut,
  deleteCuts, deleteCutsSuccess,
  loadCuts,
  loadCutsFailure,
  loadCutsSuccess,
  setCutsShow,
  shareCuts,
  shareCutsSuccess,
  shareOffCuts,
  shareOffCutsSuccess
} from '../../../../features/cut/cut.actions';
import { selectCurrentSite } from '../../../../features/site/site.selectors';
import { Site } from '../../../../models/site';
import { MatDialog } from '@angular/material/dialog';
import {
  DeleteConfirmDialog
} from '../../../dialogs/delete-confirm-dialog/delete-confirm.dialog';
import { TranslocoService } from '@jsverse/transloco';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';


@Component({
  selector: 'cut-list',
  templateUrl: './cut-list.component.html',
  styleUrls: ['../list.component.scss']
})
export class CutListComponent {

  isLoading: boolean = false;
  selectedItemIds: Array<string> = [];

  constructor(private store: Store,
              private actions: Actions,
              private dialog: MatDialog,
              private translocoService: TranslocoService) {
    this.actions.pipe(ofType(loadCuts), first())
      .subscribe(result => {
        this.isLoading = true;
      });
    this.actions.pipe(ofType(loadCutsSuccess, loadCutsFailure), first())
      .subscribe(result => {
        this.isLoading = false;
      });
    this.actions.pipe(ofType(deleteCutsSuccess, shareCutsSuccess, shareOffCutsSuccess, setCutsShow), takeUntilDestroyed())
      .subscribe(result => {
        this.selectedItemIds = [];
      });
  }

  get cuts$(): Observable<Array<Cut>> {
    return this.store.select(selectAllCuts);
  }

  itemIsSelected(id: string): boolean {
    return this.selectedItemIds.indexOf(id) > -1;
  }

  onClickUpdateSelectedItemIds(checked: boolean, id: string): void {
    const index = this.selectedItemIds.indexOf(id);
    if (checked) {
      if (index < 0) {
        this.selectedItemIds.push(id);
      }
    }
    else {
      if (index > -1) {
        this.selectedItemIds.splice(index, 1);
      }
    }
  }

  allCheckboxStateChanged(checked: boolean): void {
    this.cuts$
      .pipe(first())
      .subscribe((items: Array<Cut>) => {
      this.selectedItemIds = (checked) ? items.map((i: Cut) => i.id) : [];
    });
  }

  clickDelete(): void {
    const deleteDialogRef = this.dialog.open(DeleteConfirmDialog, {
      disableClose: false
    });
    deleteDialogRef.componentInstance.message = this.translocoService.translate("delete_selected_items", {count: this.selectedItemIds.length});
    deleteDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.store.select(selectCurrentSite)
          .pipe(first())
          .subscribe((site: Site | null | undefined) => {
            if (site) {
              this.store.dispatch(deleteCuts({
                organizationId: site.organization,
                siteId: site.id,
                ids: this.selectedItemIds
              }));
            }
          });
      }
    });
  }

  clickShow(): void {
    this.store.dispatch(setCutsShow({ids: this.selectedItemIds, show: true}));
  }

  clickHide(): void {
    this.store.dispatch(setCutsShow({ids: this.selectedItemIds, show: false}));
  }

  clickShare(): void {
    this.store.select(selectCurrentSite)
      .pipe(first())
      .subscribe((site: Site | null | undefined) => {
      if (site) {
        this.store.dispatch(shareCuts({
          organizationId: site.organization,
          siteId: site.id,
          ids: this.selectedItemIds
        }));
      }
    });
  }

  clickSareOff(): void {
    this.store.select(selectCurrentSite)
      .pipe(first())
      .subscribe((site: Site | null | undefined) => {
        if (site) {
          this.store.dispatch(shareOffCuts({
            organizationId: site.organization,
            siteId: site.id,
            ids: this.selectedItemIds
          }));
        }
      });
  }
}
