import { autoserialize } from 'cerialize';

export class DetectionTemplateLabel {
  @autoserialize id!: number;
  @autoserialize label!: string;
  @autoserialize name!: string;
}

export class DetectionTemplate {
  @autoserialize id!: string;
  @autoserialize name!: string;
  @autoserialize labels!: Array<DetectionTemplateLabel>;
}
