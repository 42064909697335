import { Pipe, PipeTransform } from '@angular/core';
import { DetectionMode, DetectionStatus } from '../../enums/detection';
import { TranslocoService } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import {
  selectConfigDetectionFilters,
} from '../../features/config/config.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Detection } from '../../models/detection';
import { DetectionFilterKeys } from '../../enums/detection-filter';


@Pipe({name: 'formatDetectionMode'})
export class FormatDetectionModePipe implements PipeTransform {
  constructor(private translocoService: TranslocoService) {
  }

  transform(detectionMode: number | string): string {
    if(detectionMode == DetectionMode.AUTO) {return this.translocoService.translate('automatic_detection');}
    return this.translocoService.translate('manual_detection');
  }
}

@Pipe({name: 'formatDetectionStatus'})
export class FormatDetectionStatusPipe implements PipeTransform {
  constructor(private translocoService: TranslocoService) {
  }

  transform(detectionStatus: number): string {
    if(detectionStatus == DetectionStatus.TO_BE_CONFIRMED) {return this.translocoService.translate('to_confirm')}
    if(detectionStatus == DetectionStatus.CONFIRMED) {return this.translocoService.translate('confirmed');}
    return 'Inconnu';
  }
}


@Pipe({name: 'filterDetections'})
export class FilterDetectionsPipe implements PipeTransform {

  private filters: any;

  constructor(private store: Store) {
    this.initHandleDetectionFilters();
  }

  private initHandleDetectionFilters(): void {
    this.store.select(selectConfigDetectionFilters)
      .pipe(takeUntilDestroyed())
      .subscribe((detectionFilters: any) => {
        this.filters = detectionFilters;
      });
  }

  private labelFilter(items: Array<Detection>): Array<Detection> {
    if(!this.filters[DetectionFilterKeys.LABELS] || this.filters[DetectionFilterKeys.LABELS].length == 0) return items;
    return items.filter((item: Detection) => {
      return this.filters[DetectionFilterKeys.LABELS].includes(item.label_translated);
    });
  }

  private confidenciesFilter(items: Array<Detection>): Array<Detection> {
    if(!this.filters[DetectionFilterKeys.CONFIDENCES]) return items;
    return items.filter((item: Detection) => {
      let settings = this.filters[DetectionFilterKeys.CONFIDENCES];
      return (settings.start / 100.0) <= item.confidence && item.confidence <= (settings.end / 100.0);
    });
  }

  private createModesFilter(items: Array<Detection>): Array<Detection> {
    if(!this.filters[DetectionFilterKeys.CREATE_MODES]) return items;
    return items.filter((item: Detection) => {
      return this.filters[DetectionFilterKeys.CREATE_MODES].includes(item.createMode.toString());
    });
  }

  transform(items: Array<Detection>): Array<Detection> {
    if (!items) return [];
    if (!this.filters) return items;
    let filtererItems = this.labelFilter(items);
    filtererItems = this.createModesFilter(filtererItems);
    filtererItems = this.confidenciesFilter(filtererItems);
    return filtererItems;
  }

}


@Pipe({name: 'distinctDetectionLabels'})
export class DistinctDetectionLabelsPipe implements PipeTransform {

  transform(items: Array<Detection>): Array<string> {
    if (!items) return [];
    return [...new Set(items.map(d => d.label_translated))];
  }
}

@Pipe({name: 'distinctDetectionCreateModes'})
export class DistinctDetectionCreateModesPipe implements PipeTransform {

  transform(items: Array<Detection>): Array<string> {
    if (!items) return [];
    return [...new Set(items.map(d => d.createMode.toString()))];
  }
}
