<div class="container">
  <mat-checkbox [checked]="allItemsSelected" (change)="onAllCheckboxStateChange($event)">
  </mat-checkbox>
  <button mat-icon-button
          [disabled]="!hasSelectedItems"
          (click)="onClickDelete($event)"
          matTooltip="{{'delete' | transloco}}">delete
  </button>
  <button mat-icon-button
          [disabled]="!hasSelectedItems"
          (click)="onClickShow($event)"
          matTooltip="{{'show' | transloco}}">
    <mat-icon>visibility</mat-icon>
  </button>
  <button mat-icon-button
          [disabled]="!hasSelectedItems"
          (click)="onClickHide($event)"
          matTooltip="{{'hide' | transloco}}">
    <mat-icon>visibility_off</mat-icon>
  </button>
  <button mat-icon-button
          [disabled]="!hasSelectedItems"
          (click)="onClickShare($event)"
          matTooltip="{{'share' | transloco}}">
    <mat-icon>share</mat-icon>
  </button>
  <button mat-icon-button
          [disabled]="!hasSelectedItems"
          (click)="onClickShareOff($event)"
          matTooltip="{{'share_off' | transloco}}">
    <mat-icon>share_off</mat-icon>
  </button>
</div>
