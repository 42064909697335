import {
  Component,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'report-formats-dialog',
  templateUrl: './report-formats.dialog.html',
  styleUrls: ['../dialog.scss', './report-formats.dialog.scss']
})
export class ReportFormatsDialog {

  availableReportFormats: Array<any> = [
    {name: 'PDF', extension: 'pdf', checked: false},
    {name: 'Docx', extension: 'docx', checked: false},
    {name: 'LibreOffice/OpenOffice', extension: 'odt', checked: false}
  ];

  constructor(public dialogRef: MatDialogRef<ReportFormatsDialog>) {}

  get selectedFormats(): Array<string> {
    return this.availableReportFormats
      .filter((data:any) => data.checked == true)
      .map((data:any) => data.extension);
  }
}
