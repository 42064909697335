import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { Detection } from '../../../models/detection';
import {
  selectAllDetections
} from '../../../features/detection/detection.selectors';
import { DetectionFilterKeys } from '../../../enums/detection-filter';
import { FilterSlide } from '../../../models/filter-slide';
import { Site } from '../../../models/site';
import {
  SiteFilterDetectionKeys, SiteFilterKeys,
  SiteFilterVolumeKeys
} from '../../../enums/site-filter';
import {
  selectConfigDetectionFilters,
  selectConfigSitesFilters
} from '../../../features/config/config.selectors';
import { selectAllSites } from '../../../features/site/site.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  setConfigSitesFilters
} from '../../../features/config/config.actions';


@Component({
  selector: 'detection-filter-dialog',
  templateUrl: './detection-filter.dialog.html',
  styleUrls: ['../dialog.scss', './detection-filter.dialog.scss'],
})
export class DetectionFilterDialog {
  icon: string | undefined;
  title: string = '';
  subtitle: string | undefined;
  message: string = '';
  batchComparedId: string | undefined;
  batchReferenceId: string | undefined;

  detectionFilterKeysEnum = DetectionFilterKeys
  config: any;
  confidenceFilterSlide: FilterSlide = new FilterSlide();

  constructor(private store: Store, public dialogRef: MatDialogRef<DetectionFilterDialog>) {
    this.initHandleConfigAndDetections();
  }

  private initHandleConfigAndDetections(): void {
    combineLatest([
      this.store.select(selectConfigDetectionFilters),
      this.store.select(selectAllDetections)
    ]).pipe(takeUntilDestroyed())
      .subscribe( results => {
        console.log(results);
        this.config = (results[0]) ? JSON.parse(JSON.stringify(results[0])) : {};
        const detections: Array<Detection> = (results[1]) ? results[1] : [];
        this.setup(this.config, detections);
      });
  }

  private setup(config: any, detections: Array<Detection>): void {
    this.setupFilterSlide(this.confidenceFilterSlide, config, DetectionFilterKeys.CONFIDENCES,  detections, 'confidence');
  }

  private setupFilterSlide(filterSlide: FilterSlide, config: any, slideKey: string, detections: Array<any>, key: string): void {
    filterSlide.max = this.max(detections, key) * 100;
    if (!config || !config[slideKey]) {
      filterSlide.start = 0;
      filterSlide.end = filterSlide.max;
    }
    else {
      filterSlide.start = config[slideKey].start;
      filterSlide.end = config[slideKey].end;
    }
  }

  private max(detections: Array<any>, key: string): number {
    return detections.map(detection => (detection) ? detection[key] : 0).reduce((prev, current) => {
      return (prev && prev > current) ? prev : current
    });
  }

  private updateConfidenceFilter(config: any): any {
    if (!this.config) this.config = {};
    this.config[DetectionFilterKeys.CONFIDENCES] = this.confidenceFilterSlide.toJson();
    return config;
  }


  get detections$(): Observable<Array<Detection>> {
    return this.store.select(selectAllDetections);
  }

  isSelected(value: string, key: string): boolean {
    if (this.config && this.config[key]) {
      return this.config[key].filter((item:Array<any>) => {
        return item.includes(value);
      }).length > 0;
    }
    return false;
  }

  updateConfig(value: string, key: string): void {
    const isAlreadySelected: boolean = this.isSelected(value, key);
    if (isAlreadySelected){
      if (this.config && this.config[key]) {
        const index = this.config[key].indexOf(value, 0);
        if (index > -1) {
          this.config[key].splice(index, 1);
        }
      }
    }
    else {
      if (!this.config) this.config = {};
      if (!this.config[key]) this.config[key] = [];
      this.config[key].push(value);
    }
  }

  validConfig(): any {
    this.updateConfidenceFilter(this.config);
    return this.config;
  }

}
