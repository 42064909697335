import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FileTools } from '../../../tools/file.tools';
import { cloneDeep } from 'lodash';
import { TranslocoService } from '@jsverse/transloco';
import { setMapHandleKeyboard } from '../../../features/cesium/cesium.actions';
import { Store } from '@ngrx/store';

export enum CsvReaderMode {
  ONE_POINT,
  TWO_POINTS
}

export interface RequiredColumn {
  name: string;
  position: number;
  required: boolean;
}

export interface Separator {
  name: string;
  value: string;
}

@Component({
  selector: 'csv-reader-dialog',
  templateUrl: './csv-reader.dialog.html',
  styleUrls: ['../dialog.scss', './csv-reader.dialog.scss']
})
export class CsvReaderDialog implements AfterViewInit {

  content: any;
  mode: CsvReaderMode = CsvReaderMode.ONE_POINT;

  @ViewChild('otherSeparatorInput') otherSeparatorInputEltRef: ElementRef<HTMLInputElement> | undefined;
  @ViewChild('validateBtn') validateBtnEltRef: ElementRef<HTMLButtonElement> | undefined;

  separators: Array<Separator> = [
    {'name': 'semicolon', 'value': ';'},
    {'name': 'comma', 'value': ','},
    {'name': 'space', 'value': ' '},
    {'name': 'other', 'value': ''}
  ];
  selectedSeparator: Separator = this.separators[0];

  private onePointRequiredColumns: Array<RequiredColumn> = [
    {'name': 'X', 'position': 0, 'required': true},
    {'name': 'Y', 'position': 1, 'required': true},
    {'name': 'Z', 'position': 2, 'required': true}
  ];

  private twoPointsRequiredColumns: Array<RequiredColumn> = [
    {'name': 'X1', 'position': 0, 'required': true},
    {'name': 'Y1', 'position': 1, 'required': true},
    {'name': 'Z1', 'position': 2, 'required': true},
    {'name': 'X2', 'position': 3, 'required': true},
    {'name': 'Y2', 'position': 4, 'required': true},
    {'name': 'Z2', 'position': 5, 'required': true}
  ];

  requiredColumns: Array<RequiredColumn> = [];
  contentColumns: Array<any> = [];
  indexesByRow: Array<any> = [];
  dataSource: Array<any> = [];


  constructor(public dialogRef: MatDialogRef<CsvReaderDialog>,
              private store: Store,
              private translocoService: TranslocoService) {

  }

  private buildDataSource(): void {
    const data = FileTools.csvFileToArray(this.content, this.selectedSeparator.value);
    const nbItemsByRow = (data.length) ? data[0].length : 0;
    this.requiredColumns = (this.mode == CsvReaderMode.ONE_POINT) ? cloneDeep(this.onePointRequiredColumns) : cloneDeep(this.twoPointsRequiredColumns);
    this.indexesByRow = Array.from({length: nbItemsByRow}, (_, i) => i);
    this.contentColumns = (data.length) ? [...Array(data[0].length).keys()].map(value => value.toString()) : [];
    if (this.contentColumns.length > this.requiredColumns.length) {
      this.requiredColumns.push(
        {
          'name': this.translocoService.translate('name'),
          'position': -1,
          'required': false
        }
      )
    }

    this.dataSource = data.map(values => {
      let dict:any = {};
      this.contentColumns.forEach((_col, index) => {
        dict[index.toString()] = (typeof values[index] === 'undefined') ? '' : values[index];
      });
      return dict;
    });
  }

  get contentIsValid(): boolean {
    const content = this.buildContent();
    const nbColMin = (this.mode == CsvReaderMode.TWO_POINTS) ? 6 : 3;
    if (content && content.length) {
      return content[0].length >= nbColMin;
    }
    return false;
  }

  private buildContent(): Array<Array<any>> {
    let content: Array<Array<any>> = [];
    this.dataSource.forEach((dataSourceRow: any) => {
      let contentRow: Array<any> = [];
      this.requiredColumns.forEach((column => {
        if(dataSourceRow[column.position] && column.position >= 0) {
          contentRow.push(dataSourceRow[column.position]);
        }
      }));
      content.push(contentRow);
    });
    return content;
  }

  ngAfterViewInit(): void {
    this.buildDataSource();
  }

  formatColumnTitle(name:string): string {
    const index = parseInt(name);
    const availableColumnNames = this.requiredColumns.filter((value: RequiredColumn) => index == value.position);
    return (availableColumnNames.length) ? availableColumnNames.map(value => value.name).join(', ') : (index+1).toString();
  }

  matchWithRequiredColumn(name:string): boolean {
    const index = parseInt(name);
    const availableColumnNames = this.requiredColumns.filter((value: RequiredColumn) => index == value.position);
    return !!(availableColumnNames.length);
  }

  updateRequiredColumn(columnName: string, value: number): void {
    const column: RequiredColumn | undefined = this.requiredColumns.find((column: RequiredColumn) => column.name === columnName);
    if (column) {
      column.position = value;
    }
  }

  updateSeparator(separator: Separator): void {
    this.selectedSeparator = separator;
    this.buildDataSource();
    if (this.selectedSeparator.name == 'other' && this.otherSeparatorInputEltRef) {
      this.otherSeparatorInputEltRef.nativeElement.focus();
    }
  }

  updateOtherTypeSeparator(event: any): void {
    this.separators[3] = {'name': 'other', 'value': event.target.value};
    this.updateSeparator(this.separators[3]);
  }

  onInputHandleFocus(event: any): void {
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: false}));
    this.updateSeparator(this.separators[3]);
  }
  onInputLeaveFocus(event: any): void {
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: true}));
  }

  onValidate(): void {
    this.dialogRef.close(this.buildContent());
  }

}
