import { Action, createReducer, on } from '@ngrx/store';
import {
  EntityState,
  EntityAdapter,
  createEntityAdapter,
  Update
} from '@ngrx/entity';
import * as TrackerActions from './tracker.actions';
import { Tracker } from '../../models/tracker';

export interface State extends EntityState<Tracker> {
  selectedTrackerImageId: string | undefined;
  selectedTrackerElevationId: string | undefined;
}

export const adapter: EntityAdapter<Tracker> = createEntityAdapter<Tracker>({
  selectId: (entity) => entity.id, // Important : préciser comment récupérer l'ID
});

export const initialState: State = adapter.getInitialState({
  selectedTrackerImageId: undefined,
  selectedTrackerElevationId: undefined,
});

const trackerReducer = createReducer(
  initialState,
  on(TrackerActions.loadTrackersImage, (state) => {
    return state;
  }),
  on(TrackerActions.loadTrackersImageSuccess, (state, { trackers }) => {
    return adapter.addMany(trackers, state);
  }),
  on(TrackerActions.loadTrackersImageFailure, (state, { error }) => {
    return state;
  }),
  on(TrackerActions.loadTrackersElevation, (state) => {
    return state;
  }),
  on(TrackerActions.loadTrackersElevationSuccess, (state, { trackers }) => {
    return adapter.addMany(trackers, state);
  }),
  on(TrackerActions.loadTrackersElevationFailure, (state, { error }) => {
    return state;
  }),
  on(TrackerActions.addTracker, (state) => {
    return state;
  }),
  on(TrackerActions.addTrackerSuccess, (state, { tracker }) => {
    return adapter.setOne(tracker, state);
  }),
  on(TrackerActions.addTrackerFailure, (state, { error }) => {
    return state;
  }),
  on(TrackerActions.updateTracker, (state) => {
    return state;
  }),
  on(TrackerActions.updateTrackerSuccess, (state, { update }) => {
    return adapter.updateOne(update, state);
  }),
  on(TrackerActions.updateTrackerFailure, (state) => {
    return state;
  }),
  on(TrackerActions.deleteTracker, (state) => {
    return state;
  }),
  on(TrackerActions.deleteTrackerSuccess, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
  on(TrackerActions.deleteTrackerFailure, (state, { error }) => {
    return state;
  }),
  on(TrackerActions.clearAllTrackers, (state, {}) => {
    return adapter.removeAll(state);
  }),
  on(TrackerActions.selectTrackerImage, (state, {id}) => {
    return { ...state, selectedTrackerImageId: id };
  }),
  on(TrackerActions.selectTrackerElevation, (state, {id}) => {
    return { ...state, selectedTrackerElevationId: id };
  }),
  on(TrackerActions.setTrackerShow, (state, {id, show}) => {
    return adapter.updateOne({id: id, changes: {show : show}}, state);
  }),
  on(TrackerActions.setTrackersShow, (state, {ids, show}) => {
    const updates: Array<Update<any>> = ids.map((id: string) => {
      return { id: id, changes: { show: show } };
    });
    return adapter.updateMany(updates, state);
  }),
  on(TrackerActions.addTrackerImages, (state) => {
    return state;
  }),
  on(TrackerActions.addTrackerImagesSuccess, (state, { trackers }) => {
    return adapter.addMany(trackers, state);
  }),
  on(TrackerActions.addTrackerImagesFailure, (state, { error }) => {
    return state;
  }),
  on(TrackerActions.addTrackerElevations, (state) => {
    return state;
  }),
  on(TrackerActions.addTrackerElevationsSuccess, (state, { trackers }) => {
    return adapter.addMany(trackers, state);
  }),
  on(TrackerActions.addTrackerElevationsFailure, (state, { error }) => {
    return state;
  }),
  on(TrackerActions.deleteTrackers, (state) => {
    return state;
  }),
  on(TrackerActions.deleteTrackersSuccess, (state, { ids }) => {
    return adapter.removeMany(ids, state);
  }),
  on(TrackerActions.deleteTrackersFailure, (state, { error }) => {
    return state;
  }),
  on(TrackerActions.shareTrackers, (state) => {
    return state;
  }),
  on(TrackerActions.shareTrackersSuccess, (state, { ids }) => {
    const updates: Array<Update<any>> = ids.map((id: string) => {
      return { id: id, changes: { isShared: true } };
    });
    return adapter.updateMany(updates, state);
  }),
  on(TrackerActions.shareTrackersFailure, (state, { error }) => {
    return state;
  }),
  on(TrackerActions.shareOffTrackers, (state) => {
    return state;
  }),
  on(TrackerActions.shareOffTrackersSuccess, (state, { ids }) => {
    const updates: Array<Update<any>> = ids.map((id: string) => {
      return { id: id, changes: { isShared: false } };
    });
    return adapter.updateMany(updates, state);
  }),
  on(TrackerActions.shareOffTrackersFailure, (state, { error }) => {
    return state;
  })
);

export function reducer(state: State | undefined, action: Action): any {
  return trackerReducer(state, action);
}

export const getSelectedTrackerImageId = (state: State) => state.selectedTrackerImageId;
export const getSelectedTrackerElevationId = (state: State) => state.selectedTrackerElevationId;
