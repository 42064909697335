import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Measure } from '../../../../models/measure';
import {
  selectAllMeasures
} from '../../../../features/measure/measure.selectors';
import { first, Observable } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import {
  deleteMeasures, deleteMeasuresSuccess,
  loadMeasures,
  loadMeasuresFailure,
  loadMeasuresSuccess,
  setMeasuresShow,
  shareMeasures, shareMeasuresSuccess,
  shareOffMeasures,
  shareOffMeasuresSuccess
} from '../../../../features/measure/measure.actions';
import { selectCurrentSite } from '../../../../features/site/site.selectors';
import { Site } from '../../../../models/site';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';
import {
  DeleteConfirmDialog
} from '../../../dialogs/delete-confirm-dialog/delete-confirm.dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';


@Component({
  selector: 'measure-list',
  templateUrl: './measure-list.component.html',
  styleUrls: ['../list.component.scss']
})
export class MeasureListComponent {

  isLoading: boolean = false;
  selectedItemIds: Array<string> = [];

  constructor(private store: Store,
              private actions: Actions,
              private dialog: MatDialog,
              private translocoService: TranslocoService) {
    this.actions.pipe(ofType(loadMeasures), first())
      .subscribe(result => {
        this.isLoading = true;
      });
    this.actions.pipe(ofType(loadMeasuresSuccess, loadMeasuresFailure), first())
      .subscribe(result => {
        this.isLoading = false;
      });
    this.actions.pipe(ofType(deleteMeasuresSuccess, shareMeasuresSuccess, shareOffMeasuresSuccess, setMeasuresShow), takeUntilDestroyed())
      .subscribe(result => {
        this.selectedItemIds = [];
      });
  }

  get measures$(): Observable<Array<Measure>> {
    return this.store.select(selectAllMeasures);
  }

  itemIsSelected(id: string): boolean {
    return this.selectedItemIds.indexOf(id) > -1;
  }

  onClickUpdateSelectedItemIds(checked: boolean, id: string): void {
    const index = this.selectedItemIds.indexOf(id);
    if (checked) {
      if (index < 0) {
        this.selectedItemIds.push(id);
      }
    }
    else {
      if (index > -1) {
        this.selectedItemIds.splice(index, 1);
      }
    }
  }

  allCheckboxStateChanged(checked: boolean): void {
    this.measures$
      .pipe(first())
      .subscribe((items: Array<Measure>) => {
        this.selectedItemIds = (checked) ? items.map((i: Measure) => i.id) : [];
      });
  }

  clickDelete(): void {
    const deleteDialogRef = this.dialog.open(DeleteConfirmDialog, {
      disableClose: false
    });
    deleteDialogRef.componentInstance.message = this.translocoService.translate("delete_selected_items", {count: this.selectedItemIds.length});
    deleteDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.store.select(selectCurrentSite)
          .pipe(first())
          .subscribe((site: Site | null | undefined) => {
            if (site) {
              this.store.dispatch(deleteMeasures({
                organizationId: site.organization,
                siteId: site.id,
                batchId: site.lastBatch,
                ids: this.selectedItemIds
              }));
            }
          });
      }
    });
  }

  clickShow(): void {
    this.store.dispatch(setMeasuresShow({ids: this.selectedItemIds, show: true}));
  }

  clickHide(): void {
    this.store.dispatch(setMeasuresShow({ids: this.selectedItemIds, show: false}));
  }

  clickShare(): void {
    this.store.select(selectCurrentSite)
      .pipe(first())
      .subscribe((site: Site | null | undefined) => {
        if (site) {
          this.store.dispatch(shareMeasures({
            organizationId: site.organization,
            siteId: site.id,
            batchId: site.lastBatch,
            ids: this.selectedItemIds
          }));
        }
      });
  }

  clickSareOff(): void {
    this.store.select(selectCurrentSite)
      .pipe(first())
      .subscribe((site: Site | null | undefined) => {
        if (site) {
          this.store.dispatch(shareOffMeasures({
            organizationId: site.organization,
            siteId: site.id,
            batchId: site.lastBatch,
            ids: this.selectedItemIds
          }));
        }
      });
  }
}
