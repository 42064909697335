import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  animate,
  animateChild,
  query,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  selectConfigTilesetColorRampMax,
  selectConfigTilesetColorRampMin,
  selectConfigTilesetStyleColorConditions
} from '../../../features/config/config.selectors';
import {
  selectCurrentTilesetConfig
} from '../../../features/tileset-config/tileset-config.selectors';
import { TilesetConfig } from '../../../models/tileset-config';
import { cloneDeep } from 'lodash';


@Component({
  selector: 'tileset-diff-color-ramp',
  templateUrl: './tileset-diff-color-ramp.component.html',
  styleUrls: ['./tileset-diff-color-ramp.component.scss'],
})
export class TilesetDiffColorRampComponent implements AfterViewInit {

  @ViewChild('canvas') colorRampCanvas: ElementRef<HTMLCanvasElement> | undefined;
  @ViewChild('scale') valuesCanvas: ElementRef<HTMLCanvasElement> | undefined;
  private min: number = 0;
  private max: number = 1;
  private colors: Array<string> = [];


  constructor(private store: Store) {
    this.initHandleConfig();
    this.initHandleTilesetConfig();
  }

  private initHandleTilesetConfig(): void {
    this.store.select(selectCurrentTilesetConfig)
      .pipe(takeUntilDestroyed())
      .subscribe((tilesetConfig: TilesetConfig | null | undefined) => {
        this.min = 0;
        this.max = (tilesetConfig) ? tilesetConfig.maxDistance : 1;
      });
    this.store.select(selectConfigTilesetColorRampMin)
      .pipe(takeUntilDestroyed())
      .subscribe((value: number) => {
        this.min = value;
        this.drawValues(this.min, this.max);
      });
    this.store.select(selectConfigTilesetColorRampMax)
      .pipe(takeUntilDestroyed())
      .subscribe((value: number) => {
        this.max = value;
        this.drawValues(this.min, this.max);
      });
  }

  private initHandleConfig() {
    this.store.select(selectConfigTilesetStyleColorConditions)
      .pipe(takeUntilDestroyed())
      .subscribe(((conditions: Array<any> | undefined) => {
        const alteredConditions = (conditions) ? cloneDeep(conditions) : [];
        this.colors = (conditions) ? alteredConditions?.map((c:any) => {
          const params = c[1];
          return "hsl("+ String(params[0]) + "turn,"+ String(params[1]*100) +"%, "+ String(params[2]*100) + "%)";
        }) : [];
        this.drawColorRamp(this.colors);
      }));
  }

  private generateGradient(colors: Array<string>): void {
    if(this.colorRampCanvas) {
      const ctx = this.colorRampCanvas.nativeElement.getContext('2d');
      if (ctx && colors.length) {
        const range = colors.length;
        const d = (height:number) => height / range;
        const width = this.colorRampCanvas.nativeElement.width;
        const height = this.colorRampCanvas.nativeElement.height;
        const grd = ctx.createLinearGradient(0, 0, width, 0);
        colors.forEach((color: string, index:number) => {
          grd.addColorStop(d(index), color);
        });
        ctx.fillStyle = grd;
        ctx.fillRect(0, 0, width, height);
      }
    }
  }

  private generateValues(min: number, max: number, nbStep: number): void {
    if(this.valuesCanvas) {
      const ctx = this.valuesCanvas.nativeElement.getContext('2d');
      if (ctx) {
        const width = this.valuesCanvas.nativeElement.width;
        const step = width / nbStep;
        ctx.font = "12px Inter";
        ctx.textBaseline = "top";
        const dpr = window.devicePixelRatio || 1
        ctx.scale(dpr, dpr);

        for(let i = 0 ; i < nbStep+1 ; i++) {
          let line_x = i*step;
          if (i == 0) line_x += 1;
          if (i == nbStep) line_x -= 1;
          ctx.beginPath();
          ctx.moveTo(line_x, 0);
          ctx.lineTo(line_x, 5);
          ctx.stroke();

          const value = min + ((i * step * (max-min)) / width);
          const text = value.toFixed(2);
          let metrics = ctx.measureText(text);

          let text_x = i*step;
          if (i != 0 && i != nbStep) {
            text_x -= (metrics.width /2.0);
          }
          if (i == nbStep) {
            text_x -= metrics.width;
          }
          ctx.fillText(text, text_x, 10);
        }
      }
    }
  }

  private clearColorRamp(): void {
    if(this.colorRampCanvas) {
      const ctx = this.colorRampCanvas.nativeElement.getContext('2d')
      if (ctx) {
        const width = this.colorRampCanvas.nativeElement.width;
        const height = this.colorRampCanvas.nativeElement.height;
        ctx.clearRect(0, 0, width, height);
      }
    }
  }

  private clearValues(): void {
    if(this.valuesCanvas) {
      const ctx = this.valuesCanvas.nativeElement.getContext('2d')
      if (ctx) {
        const width = this.valuesCanvas.nativeElement.width;
        const height = this.valuesCanvas.nativeElement.height;
        ctx.clearRect(0, 0, width, height);
      }
    }
  }

  private drawColorRamp(colors: any): void {
    this.clearColorRamp();
    this.generateGradient(colors);
  }

  private drawValues(min: number, max: number): void {
    this.clearValues();
    this.generateValues(min, max, 4);
  }

  ngAfterViewInit(): void {
    this.drawColorRamp(this.colors);
    this.drawValues(this.min, this.max);
  }
}
