<mat-card>
  <mat-card-header>
    <mat-card-title>
      <mat-icon>assignment</mat-icon>&nbsp;{{'report_formats' | transloco}}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <ul>
    @for (item of availableReportFormats; track item) {
      <li>
        <mat-checkbox
          [checked]="item.checked"
          (change)="item.checked = !item.checked">
            {{item.name}}
        </mat-checkbox>
      </li>
    }
    </ul>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="dialogRef.close()">{{'cancel' | transloco}}</button>
    <button mat-button
            [disabled]="selectedFormats.length == 0"
            cdkFocusInitial
            color="primary"
            (click)="dialogRef.close(selectedFormats)">{{'validate' | transloco}}</button>
  </mat-card-actions>
</mat-card>
