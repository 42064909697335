<div class="container">
  <div class="selector">
    <volume-display-mode-selector *ngIf="emergencyTemplate"></volume-display-mode-selector>
  </div>

  <div class="threshold-form" *ngIf="selectedVolumeColorMode==volumeColorMode.COLOR_MODE_VOLUME_MOVED">
    <volume-threshold-form></volume-threshold-form>
  </div>

  <canvas #canvas></canvas>

  <div class="info" *ngIf="labelMin">
    <div>{{labelMin}}</div>
    <div>{{labelMax}}</div>
  </div>
</div>
