import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from '../environments/environment';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private sentryHandler = Sentry.createErrorHandler({ showDialog: false });

  handleError(error: any): void {
    let finalError: Error;

    // 🧼 Transformation des erreurs non standards
    if (error instanceof Error) {
      finalError = error;
    } else if (typeof error === 'string') {
      finalError = new Error(error);
    } else if (error === undefined || error === null) {
      // ⛔ Ignorer complètement
      if (!environment.production) {
        console.warn('⛔ Ignored: undefined/null error (probably from lib)');
      }
      return;
    } else {
      // Tentative de sérialisation d'objet brut
      let serialized = '';
      try {
        serialized = JSON.stringify(error, null, 2);
      } catch (e) {
        serialized = '[Unserializable object]';
      }
      finalError = new Error(`Non-Error exception: ${serialized}`);
    }

    // ⛔ Filtrage d'erreurs spécifiques
    if (finalError.message.includes('Handled unknown error')) {
      if (!environment.production) {
        console.warn('⛔ Ignored: Handled unknown error');
      }
      return;
    }

    // 🔁 Log utile en dev uniquement
    if (!environment.production) {
      console.error('🔥 GlobalErrorHandler caught:', finalError);
    }

    // 📤 Envoi à Sentry (prod uniquement si configuré comme tel)
    this.sentryHandler.handleError(finalError);
  }
}
