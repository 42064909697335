<div class="container">
  <div class="count">
    {{ detectionCount }}
  </div>
  <div>
    <button mat-icon-button
            matTooltip="{{'display_filters' | transloco}}"
            [color]="(detectionFiltersIsActive$ | async) ? 'accent' : 'undefined'"
            (click)="openFilters()">
      tune
    </button>
  </div>
</div>
