<div *ngIf="(measures$ | async) as measures" class="container">
  <mat-spinner *ngIf="isLoading" strokeWidth="4"></mat-spinner>
  <div *ngIf="measures.length == 0 && !isLoading" class="info">
    {{'used' | transloco}} <mat-icon>straighten</mat-icon> {{'or' | transloco}} <mat-icon>activity_zone</mat-icon> {{'placeholder_list_measure' | transloco}}
  </div>
  <item-list-header *ngIf="measures.length && !isLoading"
                    [hasSelectedItems]="selectedItemIds.length > 0"
                    [allItemsSelected]="measures.length == selectedItemIds.length"
                    (allCheckboxStateChanged)="allCheckboxStateChanged($event)"
                    (clickDelete)="clickDelete()"
                    (clickShow)="clickShow()"
                    (clickHide)="clickHide()"
                    (clickShare)="clickShare()"
                    (clickShareOff)="clickSareOff()"></item-list-header>
  <div class="list">
    <div class="item" *ngFor="let item of measures;">
      <mat-checkbox
        [checked]="itemIsSelected(item.id)"
        (change)="onClickUpdateSelectedItemIds($event.checked, item.id)"> </mat-checkbox>
      <measure-card class="card" [measure]="item"></measure-card>
    </div>
  </div>
</div>


