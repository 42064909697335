import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import * as TrackerActions from './tracker.actions';
import { Deserialize } from 'cerialize';
import { TrackerElevation, TrackerImage } from '../../models/tracker';
import { ApiService } from '../../services/api.service';
import { TranslocoService } from '@jsverse/transloco';
import { Comment } from '../../models/comment';
import { TrackerType } from '../../enums/tracker';


@Injectable()
export class TrackerEffects {

  loadTrackersImage$ = createEffect(() => {
    return this.actions$.pipe(ofType(TrackerActions.loadTrackersImage),
      concatMap(({organizationId, siteId}) =>
        this.api.trackers$(organizationId, siteId, TrackerType.IMAGE).pipe(
          map(trackers => trackers.map((tracker: any) => {
            let obj = Deserialize(tracker, TrackerImage);
            if (!obj.name) {
              const name = this.translocoService.translate('marker');
              obj.name = name + " " + obj.id.slice(0,4).toUpperCase();
            }
            return obj;
          })),
          map(trackers => TrackerActions.loadTrackersImageSuccess({ trackers })),
          catchError(error => of(TrackerActions.loadTrackersImageFailure({ error })))
        )
      ));
  });

  loadTrackersElevation$ = createEffect(() => {
    return this.actions$.pipe(ofType(TrackerActions.loadTrackersElevation),
      concatMap(({organizationId, siteId}) =>
        this.api.trackers$(organizationId, siteId, TrackerType.ELEVATION).pipe(
          map(trackers => trackers.map((tracker: any) => {
            let obj = Deserialize(tracker, TrackerElevation);
            if (!obj.name) {
              const name = this.translocoService.translate('elevation');
              obj.name = name + " " + obj.id.slice(0,4).toUpperCase();
            }
            return obj;
          })),
          map(trackers => TrackerActions.loadTrackersElevationSuccess({ trackers })),
          catchError(error => of(TrackerActions.loadTrackersElevationFailure({ error })))
        )
      ));
  });

  addTracker$ = createEffect(() => {
    return this.actions$.pipe(ofType(TrackerActions.addTracker),
      concatMap(({organizationId, siteId, point, trackerType}) =>
      this.api.addTracker$(organizationId, siteId, point, trackerType).pipe(
        map(tracker => {
          let obj;
          if (trackerType == TrackerType.IMAGE) {
            obj = Deserialize(tracker, TrackerImage);
          }
          else {
            obj = Deserialize(tracker, TrackerElevation);
          }
          if (!obj.name) {
            let name;
            if (obj.trackerType == TrackerType.IMAGE) {
              name = this.translocoService.translate('marker');
            }
            else {
              name = this.translocoService.translate('elevation');
            }
            obj.name = name + " " + obj.id.slice(0,4).toUpperCase();
          }
          return obj;
        }),
        map(tracker => TrackerActions.addTrackerSuccess({ tracker })),
        catchError(error => of(TrackerActions.addTrackerFailure({ error }))))
    ));
  });

  deleteTracker$ = createEffect(() => {
    return this.actions$.pipe(ofType(TrackerActions.deleteTracker),
      concatMap(({organizationId, siteId, trackerId}) =>
        this.api.deleteTracker$(organizationId, siteId, trackerId).pipe(
          map(tracker =>  TrackerActions.deleteTrackerSuccess({ id: trackerId })),
          catchError(error => of(TrackerActions.deleteTrackerFailure({ error }))))
      ));
  });

  updateTracker$ = createEffect(() => {
    return this.actions$.pipe(ofType(TrackerActions.updateTracker),
      concatMap(({organizationId, siteId, trackerId, payload}) =>
        this.api.updateTracker$(organizationId, siteId, trackerId, payload).pipe(
          map(tracker => {
            if (tracker.tracker_type == TrackerType.IMAGE) {
              return Deserialize(tracker, TrackerImage)
            }
            else {
              return Deserialize(tracker, TrackerElevation)
            }
          }),
          map(tracker => {
            return {id: tracker.id, changes: tracker};
          }),
          map(update => TrackerActions.updateTrackerSuccess({ update })),
          catchError(error => of(TrackerActions.updateTrackerFailure({ error }))))
      ));
  });

  loadTrackerComments$ = createEffect(() => {
    return this.actions$.pipe(ofType(TrackerActions.loadTrackerComments),
      concatMap(({organizationId, siteId, trackerId}) =>
        this.api.trackerComments$(organizationId, siteId, trackerId).pipe(
          map(comments => comments.map((comment: any) => Deserialize(comment, Comment))),
          map(comments => TrackerActions.loadTrackerCommentsSuccess({ comments })),
          catchError(error => of(TrackerActions.loadTrackerCommentsFailure({ error }))))
      ));
  });

  addTrackerComment$ = createEffect(() => {
    return this.actions$.pipe(ofType(TrackerActions.addTrackerComment),
      concatMap(({organizationId, siteId, trackerId, payload}) =>
        this.api.addTrackerComment$(organizationId, siteId, trackerId, payload).pipe(
          map(comment => Deserialize(comment, Comment)),
          map(comment => TrackerActions.addTrackerCommentSuccess({ comment })),
          catchError(error => of(TrackerActions.addTrackerCommentFailure({ error }))))
      ));
  });

  addTrackerImages$ = createEffect(() => {
    return this.actions$.pipe(ofType(TrackerActions.addTrackerImages),
      concatMap(({organizationId, siteId, trackers}) =>
        this.api.addTrackers$(organizationId, siteId, trackers, TrackerType.IMAGE).pipe(
          map(trackers => trackers.map((tracker: any) => {
            const obj = Deserialize(tracker, TrackerImage);
            if (obj && !obj.name) {
              const name = this.translocoService.translate('marker');
              obj.name = name + " " + obj.id.slice(0,4).toUpperCase();
            }
            return obj;
          })),
          map(trackers => TrackerActions.addTrackerImagesSuccess({ trackers })),
          catchError(error => of(TrackerActions.addTrackerImagesFailure({ error })))
        )
      ));
  });

  addTrackerElevations$ = createEffect(() => {
    return this.actions$.pipe(ofType(TrackerActions.addTrackerElevations),
      concatMap(({organizationId, siteId, trackers}) =>
        this.api.addTrackers$(organizationId, siteId, trackers, TrackerType.ELEVATION).pipe(
          map(trackers => trackers.map((tracker: any) => {
            const obj = Deserialize(tracker, TrackerElevation);
            if (obj && !obj.name) {
              const name = this.translocoService.translate('elevation');
              obj.name = name + " " + obj.id.slice(0,4).toUpperCase();
            }
            return obj;
          })),
          map(trackers => TrackerActions.addTrackerElevationsSuccess({ trackers })),
          catchError(error => of(TrackerActions.addTrackerElevationsFailure({ error })))
        )
      ));
  });

  exportTrackersImageCSV$ = createEffect(() => {
    return this.actions$.pipe(ofType(TrackerActions.exportTrackersImageCSV),
      concatMap(({organizationId, siteId}) =>
        this.api.exportTrackersCSV$(organizationId, siteId, TrackerType.IMAGE).pipe(
          map(csv => TrackerActions.exportTrackersImageCSVSuccess({ csv })),
          catchError(error => of(TrackerActions.exportTrackersImageCSVFailure({ error }))))
      ));
  });

  exportTrackersElevationCSV$ = createEffect(() => {
    return this.actions$.pipe(ofType(TrackerActions.exportTrackersElevationCSV),
      concatMap(({organizationId, siteId}) =>
        this.api.exportTrackersCSV$(organizationId, siteId, TrackerType.ELEVATION).pipe(
          map(csv => TrackerActions.exportTrackersElevationCSVSuccess({ csv })),
          catchError(error => of(TrackerActions.exportTrackersElevationCSVFailure({ error }))))
      ));
  });


  deleteTrackers$ = createEffect(() => {
    return this.actions$.pipe(ofType(TrackerActions.deleteTrackers),
      concatMap(({organizationId, siteId, ids}) =>
        this.api.deleteTrackers$(organizationId, siteId, ids).pipe(
          map(result =>  TrackerActions.deleteTrackersSuccess({ ids: result.ids })),
          catchError(error => of(TrackerActions.deleteTrackersFailure({ error }))))
      ));
  });

  shareTrackers$ = createEffect(() => {
    return this.actions$.pipe(ofType(TrackerActions.shareTrackers),
      concatMap(({organizationId, siteId, ids}) =>
        this.api.shareTrackers$(organizationId, siteId, ids).pipe(
          map(result =>  TrackerActions.shareTrackersSuccess({ ids: result.ids })),
          catchError(error => of(TrackerActions.shareTrackersFailure({ error }))))
      ));
  });

  shareOffTrackers$ = createEffect(() => {
    return this.actions$.pipe(ofType(TrackerActions.shareOffTrackers),
      concatMap(({organizationId, siteId, ids}) =>
        this.api.shareOffTrackers$(organizationId, siteId, ids).pipe(
          map(result =>  TrackerActions.shareOffTrackersSuccess({ids: result.ids })),
          catchError(error => of(TrackerActions.shareOffTrackersFailure({ error }))))
      ));
  });

  constructor(private actions$: Actions,
              private api: ApiService,
              private translocoService: TranslocoService) {}

}
