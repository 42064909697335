<div class="container">
  <label>{{'point_cloud_size' | transloco}}: x{{slider.value}}</label>
  <div class="slide">
    <label>x{{pointSizeMin}}</label>
    <mat-slider min="{{pointSizeMin}}" max="{{pointSizeMax}}" step="1" class="slider">
      <input matSliderThumb
             #slider
             [value]="(pointSize$ | async)"
             (valueChange)="onSliderChange($event)">
    </mat-slider>
    <label>x{{pointSizeMax}}</label>
  </div>
</div>

