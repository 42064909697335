<mat-card>
  <mat-card-header>
    <mat-card-title>
      <mat-icon>overview</mat-icon>&nbsp;{{'csv_reader_dialog_title' | transloco}}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="content">
      <div class="configuration">
        <div class="section" style="width: 40%;">
          <label>{{ 'csv_delimiters' | transloco }}</label>
          <div class="delimiters">
            <mat-radio-group
              [(ngModel)]="selectedSeparator">
              @for (separator of separators; track separator) {
                <mat-radio-button
                  [value]="separator"
                  (change)="updateSeparator(separator)">{{separator.name | transloco}}
                </mat-radio-button>
              }
            </mat-radio-group>
            <input type="text"
                   #otherSeparatorInput
                   class="input-separator"
                   (focusin)="onInputHandleFocus($event)"
                   (focusout)="onInputLeaveFocus($event)"
                   (input)="updateOtherTypeSeparator($event)">
          </div>
        </div>
        <div class="section"  style="width: 60%;">
          <label>{{ 'csv_columns' | transloco }}</label>
          <div class="columns">
            @for (column of requiredColumns; track column) {
              <mat-form-field>
                <mat-label>{{column.name}}</mat-label>
                <mat-select [ngModel]="column.position" (selectionChange)="updateRequiredColumn(column.name, $event.value)">
                  @for (n of indexesByRow; track n) {
                    <mat-option [value]=n>{{n+1}}</mat-option>
                  }
                  <mat-option *ngIf="!column.required" [value]="-1">---</mat-option>
                </mat-select>
              </mat-form-field>
            }
         </div>
        </div>
      </div>
      <div class="section table">
        <label>{{ 'csv_content' | transloco }} ({{dataSource.length}} {{ 'lines' | transloco }})</label>
        <div class="viewer">
          <table mat-table [dataSource]="dataSource">
            @for (col of contentColumns; track col) {
            <ng-container matColumnDef="{{col}}">
            <th mat-header-cell
                class="sticky-header"
                *matHeaderCellDef
                [ngClass]="{'match-header': matchWithRequiredColumn(col) }">{{ formatColumnTitle(col) }}</th>
            <td mat-cell
                *matCellDef="let element"
                [ngClass]="{'match': matchWithRequiredColumn(col) }">{{element[col]}}</td>
          </ng-container>
            }
            <tr mat-header-row *matHeaderRowDef="contentColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: contentColumns;"></tr>
          </table>
        </div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="dialogRef.close()">{{'cancel' | transloco}}</button>
    <button mat-button
            #validateBtn
            [disabled]="!contentIsValid"
            cdkFocusInitial
            color="primary"
            (click)="onValidate()">{{'validate' | transloco}}</button>
  </mat-card-actions>
</mat-card>
