import {
  AfterViewInit,
  Component, ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  TrackerImage, TrackerImageBatchData,
  TrackerImageBatchDataImage
} from '../../../../models/tracker';
import { selectVolume } from '../../../../features/volume/volume.actions';
import {
  selectDetection
} from '../../../../features/detection/detection.actions';
import { selectCut } from '../../../../features/cut/cut.actions';
import {
  selectMeasure} from '../../../../features/measure/measure.actions';
import { MatDialog } from '@angular/material/dialog';
import {
  DeleteConfirmDialog
} from '../../../dialogs/delete-confirm-dialog/delete-confirm.dialog';
import { RenameDialog } from '../../../dialogs/rename-dialog/rename.dialog';
import {
  setCameraConfiguration,
  setCameraMode,
  setMapHandleKeyboard
} from '../../../../features/cesium/cesium.actions';
import { ExportTools } from '../../../../tools/export.tools';
import { CesiumTools } from '../../../../tools/cesium.tools';
import { CameraMode } from '../../../../enums/camera';
import { selectCurrentSite } from '../../../../features/site/site.selectors';
import { combineLatest, first, Observable, of } from 'rxjs';
import { Site } from '../../../../models/site';
import { ApiService } from '../../../../services/api.service';
import { MarkerSortDistancePipe } from '../../../pipes/marker.pipe';
import { TranslocoService } from '@jsverse/transloco';
import { User } from '../../../../models/user';
import { selectUser } from '../../../../features/user/user.selectors';
import {
  selectCurrentTrackerImage
} from '../../../../features/tracker/tracker.selectors';
import {
  deleteTracker, deleteTrackerSuccess,
  selectTrackerElevation, selectTrackerImage, setTrackerShow, updateTracker
} from '../../../../features/tracker/tracker.actions';
import {
  selectCurrentReferenceBatch
} from '../../../../features/batch/batch.selectors';
import { Batch } from '../../../../models/batch';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  PictureComponent,
  PictureConfig
} from '../../picture/picture.component';
import { DatePipe } from '@angular/common';
import { Actions, ofType } from '@ngrx/effects';

export interface TmpImage {
  name: string;
  url: string;
}

export enum SplitScreenMode {
  LEFT,
  SPLITTED,
  RIGHT
}

@Component({
  selector: 'marker-details',
  templateUrl: './marker-details.component.html',
  styleUrls: ['../details.component.scss', './marker-details.component.scss'],
  animations: [
    trigger('displayMode', [
      state('expanded', style({
        right: '10px',
        bottom: '10px'
      })),
      state('collapsed', style({
        width: '400px',
      })),
      transition('expanded => collapsed', [
        animate('0ms ease-out'),
      ]),
      transition('collapsed => expanded', [
        animate('0ms ease-out'),
      ])
    ]),
  ],
})
export class MarkerDetailsComponent implements AfterViewInit {

  site: Site | null | undefined;
  marker: TrackerImage | null | undefined;
  referenceBatch: Batch | null | undefined;
  displayModeExtended: boolean = false;
  lastBatchPictureConfig: PictureConfig | undefined;
  referenceBatchPictureConfig: PictureConfig | undefined;
  selectedSplitScreenMode: SplitScreenMode = SplitScreenMode.SPLITTED;
  lastBatchPictureCanvasAvailable: boolean = false;
  referenceBatchPictureCanvasAvailable: boolean = false;

  @ViewChild('lastPicture') lastPictureRef: PictureComponent | undefined;
  @ViewChild('referencePicture') referencePictureRef: PictureComponent | undefined;


  constructor(private store: Store,
              private actions: Actions,
              private api: ApiService,
              private sortDistancePipe: MarkerSortDistancePipe,
              private dialog: MatDialog,
              private translocoService: TranslocoService) {
    combineLatest([
      this.store.select(selectCurrentSite),
      this.store.select(selectCurrentTrackerImage),
      this.store.select(selectCurrentReferenceBatch)
    ]).pipe(takeUntilDestroyed())
      .subscribe((results: any) => {
        this.clear();
        this.site = results[0];
        this.marker = results[1];
        this.referenceBatch = results[2];

        if (this.marker) {
          this.store.dispatch(selectMeasure({id: undefined}));
          this.store.dispatch(selectVolume({id: undefined}));
          this.store.dispatch(selectDetection({id: undefined}));
          this.store.dispatch(selectCut({id: undefined}));
          this.store.dispatch(selectTrackerElevation({id: undefined}));
        }
        else {
          this.displayModeExtended = false;
        }

        setTimeout(() => {
          this.loadPictures();
        }, 50);

      });

    this.actions
      .pipe(ofType(deleteTrackerSuccess), takeUntilDestroyed())
      .subscribe((result: any) => {
        this.displayModeExtended = false;
      });
  }

  private clear(): void {
    if (this.lastPictureRef) {
      this.lastPictureRef.reset();
    }
    if (this.referencePictureRef) {
      this.referencePictureRef.reset();
    }
    this.lastBatchPictureConfig = {
      url: undefined,
      circle: undefined
    };
    this.referenceBatchPictureConfig = {
      url: undefined,
      circle: undefined
    };
  }

  private loadPictures(): void {
    if (this.site && this.marker?.data) {
      this.loadLastBatchPicture(this.site, this.marker)
      if (this.referenceBatch) {
        this.loadReferenceBatchPicture(this.site, this.marker, this.referenceBatch.id);
      }
    }
  }

  private loadLastBatchPicture(site:Site, marker: TrackerImage): void {
    const trackerImageBatchData: Array<TrackerImageBatchData> = marker.data
      .filter((data: TrackerImageBatchData) => data.batch === site.lastBatch);

    if (trackerImageBatchData.length) {
      const closerImage: TrackerImageBatchDataImage | undefined = trackerImageBatchData[0].images
        .sort((a,b) => a.distance - b.distance)[0]
      if (closerImage) {
        const resource_splitted = closerImage.name.split('.');
        const extension = resource_splitted[resource_splitted.length-1];
        const filename = marker.name.replace(/ /g,"_") + '.' + extension;
        this.api.imageTmpUrl$(site.organization, site.id, site.lastBatch, closerImage.name, filename)
          .pipe(first())
          .subscribe((url: string) => {
            this.lastBatchPictureConfig = {
              url: url,
              circle:{
                x: closerImage.position[0],
                y: closerImage.position[1],
                r: 50
              }
            }
          });
        }
        else {
          if (this.lastPictureRef) {
            this.lastPictureRef.noimage();
          }
        }
      }
    else {
      if (this.lastPictureRef) {
        this.lastPictureRef.noimage();
      }
    }
  }

  private loadReferenceBatchPicture(site:Site, marker: TrackerImage, batchId: string): void {
    const trackerImageBatchData: Array<TrackerImageBatchData> = marker.data
      .filter((data: TrackerImageBatchData) => data.batch === batchId);

    if (trackerImageBatchData.length) {
      const closerImage: TrackerImageBatchDataImage | undefined = trackerImageBatchData[0].images
        .sort((a,b) => a.distance - b.distance)[0]
      if (closerImage) {
        // console.log(marker.name, 'loadReferenceBatchPicture', closerImage.name, closerImage.distance);
        this.api.imageTmpUrl$(site.organization, site.id, batchId, closerImage.name, closerImage.name)
          .pipe(first())
          .subscribe((url: string) => {
            this.referenceBatchPictureConfig = {
              url: url,
              circle:{
                x: closerImage.position[0],
                y: closerImage.position[1],
                r: 50
              }
            }
          });
      }
      else {
        if (this.referencePictureRef) {
          this.referencePictureRef.noimage();
        }
      }
    }
    else {
      if (this.referencePictureRef) {
        this.referencePictureRef.noimage();
      }
    }
  }

  onHandleFocus(): void {
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: false}));
  }

  onFocusLeave(): void {
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: true}));
  }

  onClickFlyTo(marker: TrackerImage): void {
    const cameraConfiguration = CesiumTools.cameraConfigurationFromTargetAndDirection(marker.center, marker.cameraDirection, marker.length);
    if (!cameraConfiguration) return;
    this.store.dispatch(setCameraMode({cameraMode: CameraMode.MODE_3D}));
    this.store.dispatch(setCameraConfiguration({cameraConfiguration}));
  }

  onClickClose(): void {
    this.store.dispatch(selectTrackerImage({id: undefined}));
  }

  onClickExportPicture(marker: TrackerImage, batchId: string): void {
    if (this.site && marker?.data) {
      const lastMarkerBatch = marker.data.filter(mbi => mbi.batch == batchId)[0];
      const data = this.sortDistancePipe.transform(lastMarkerBatch.images);
      if (data.length) {
        const dateFormat = this.translocoService.getActiveLang() == 'fr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
        const datePipe: DatePipe = new DatePipe(this.translocoService.getActiveLang());
        const date = datePipe.transform(new Date(lastMarkerBatch.dateAcquired), dateFormat)
        const firstMarkerImage: TrackerImageBatchDataImage = data[0];
        const extension = firstMarkerImage.name.split('/')[-1];
        const filename = marker.name.replace(/ /g,"_") + '.' + date + '.' + extension;
        this.api.imageTmpUrl$(this.site.organization, this.site.id, batchId, firstMarkerImage.name, filename)
          .pipe(first()).subscribe(url => {
          ExportTools.exportResourceUrl(url);
        });
      }
    }
  }

  onClickExportLastPictureFromCanvas(marker: TrackerImage): void {
    if (this.lastPictureRef) {
      const data = this.lastPictureRef.canvasTopPNG();
      if (data && this.site) {
        const dateFormat = this.translocoService.getActiveLang() == 'fr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
        const datePipe: DatePipe = new DatePipe(this.translocoService.getActiveLang());
        const date = datePipe.transform(new Date(this.site.lastBatchDate), dateFormat)
        const filename = marker.name.replace(/ /g,"_") + '.' + date;
        ExportTools.exportPNG(filename, data);
      }
    }
  }

  onClickExportReferencePictureFromCanvas(marker: TrackerImage): void {
    if (this.referencePictureRef) {
      const data = this.referencePictureRef.canvasTopPNG();
      if (data && this.referenceBatch) {
        const dateFormat = this.translocoService.getActiveLang() == 'fr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
        const datePipe: DatePipe = new DatePipe(this.translocoService.getActiveLang());
        const date = datePipe.transform(new Date(this.referenceBatch.dateAcquired), dateFormat)
        const filename = marker.name.replace(/ /g,"_") + '.' + date;
        ExportTools.exportPNG(filename, data);
      }
    }
  }

  onClickExportData(marker: TrackerImage): void {
    ExportTools.exportJSON(marker.name, JSON.stringify(marker));
  }

  onClickDelete(marker: TrackerImage): void {
    const deleteDialogRef = this.dialog.open(DeleteConfirmDialog, {
      disableClose: false
    });
    deleteDialogRef.componentInstance.message = this.translocoService.translate("would_you_like_to_delete", {name: marker.name});
    deleteDialogRef.afterClosed().subscribe(result => {
      if(result && this.site) {
        this.store.dispatch(deleteTracker({
          organizationId: this.site.organization,
          siteId: this.site.id,
          trackerId: marker.id
        }));
      }
    });
  }

  onClickRename(marker: TrackerImage): void {
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: false}));
    const renameDialogRef = this.dialog.open(RenameDialog, {
      disableClose: false
    });
    renameDialogRef.componentInstance.placeholder = marker.name;
    renameDialogRef.afterClosed().subscribe(result => {
      this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: true}));
      if(result && this.marker && this.site) {
        this.store.dispatch(updateTracker({
          organizationId: this.site.organization,
          siteId: this.site.id,
          trackerId: marker.id,
          payload: {
            name: result,
            is_shared: marker.isShared
          }}));
      }
    });
  }

  onClickToggleShare(marker: TrackerImage): void {
    if (this.site) {
      this.store.dispatch(updateTracker({
        organizationId: this.site.organization,
        siteId: this.site.id,
        trackerId: marker.id,
        payload: {
          name: marker.name,
          is_shared: !marker.isShared
        }}));
    }
  }

  onClickToggleShow(marker: TrackerImage): void {
    marker.show = !marker.show;
    this.store.dispatch(setTrackerShow({id: marker.id, show: marker.show}));
  }

  onClickToggleExtend(): void {
    this.displayModeExtended = !this.displayModeExtended;
    if (this.referencePictureRef) {
      this.referencePictureRef.center();
    }
    if (this.lastPictureRef) {
      this.lastPictureRef.center();
    }
  }

  get currentUser$(): Observable<User | undefined> {
    return this.store.select(selectUser)
  }

  ngAfterViewInit(): void {
    this.loadPictures();
  }

  onSplitModeChanged(mode: SplitScreenMode): void {
    this.selectedSplitScreenMode = mode;
  }

  dateFormat(user: User) {
    return (user.languageCode == 'fr') ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
  }

}
