<div *ngIf="(elevations$ | async) as elevations" class="container">
  <mat-spinner *ngIf="isLoading" strokeWidth="4"></mat-spinner>
  <div *ngIf="elevations.length == 0 && !isLoading" class="info">
    {{'used' | transloco}} <mat-icon>altitude</mat-icon> {{'placeholder_list_elevation' | transloco}}
  </div>
  <item-list-header *ngIf="elevations.length && !isLoading"
                    [hasSelectedItems]="selectedItemIds.length > 0"
                    [allItemsSelected]="elevations.length == selectedItemIds.length"
                    (allCheckboxStateChanged)="allCheckboxStateChanged($event)"
                    (clickDelete)="clickDelete()"
                    (clickShow)="clickShow()"
                    (clickHide)="clickHide()"
                    (clickShare)="clickShare()"
                    (clickShareOff)="clickSareOff()"></item-list-header>
  <div class="list">
    <div class="item" *ngFor="let item of elevations;">
      <mat-checkbox
        [checked]="itemIsSelected(item.id)"
        (change)="onClickUpdateSelectedItemIds($event.checked, item.id)"> </mat-checkbox>
      <elevation-card class="card" [elevation]="item"></elevation-card>
    </div>
  </div>
</div>
