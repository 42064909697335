import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  setCameraConfiguration,
  setCameraMode,
} from '../../../../features/cesium/cesium.actions';
import { CesiumTools } from '../../../../tools/cesium.tools';
import { CameraMode } from '../../../../enums/camera';
import { Cut } from '../../../../models/cut';
import { selectCut } from '../../../../features/cut/cut.actions';
import { EmergencyTools } from '../../../../tools/emergency.tools';
import { Observable } from 'rxjs';
import { User } from '../../../../models/user';
import { selectUser } from '../../../../features/user/user.selectors';
import { selectCurrentCut } from '../../../../features/cut/cut.selectors';

@Component({
  selector: 'cut-card',
  templateUrl: './cut-card.component.html',
  styleUrls: ['../card.component.scss'],
})
export class CutCardComponent {

  @Input({required: true}) cut: Cut | undefined;

  constructor(private store: Store) {
  }

  get border() {
    return EmergencyTools.border(0);
  }

  onClickFlyTo(cut: Cut): void {
    const cameraConfiguration = CesiumTools.cameraConfigurationFromTargetAndDirection(cut.center, cut.cameraDirection, cut.length);
    if (!cameraConfiguration) return;
    this.store.dispatch(setCameraMode({cameraMode: CameraMode.MODE_3D}));
    this.store.dispatch(setCameraConfiguration({cameraConfiguration}));
  }

  onClickEdit(cut: Cut): void {
    // this.onClickFlyTo(cut);
    this.store.dispatch(selectCut({id: cut.id}));
  }

  get currentUser$(): Observable<User | undefined> {
    return this.store.select(selectUser)
  }

  get selectedItem$(): Observable<Cut | null | undefined> {
    return this.store.select(selectCurrentCut)
  }
}
