import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Detection } from '../../../../models/detection';

import {
  setCameraConfiguration,
  setCameraMode,
} from '../../../../features/cesium/cesium.actions';
import { CesiumTools } from '../../../../tools/cesium.tools';
import { CameraMode } from '../../../../enums/camera';
import {
  selectDetection
} from '../../../../features/detection/detection.actions';
import {
  DetectionMode,
  DetectionStatus,
} from '../../../../enums/detection';
import { Observable } from 'rxjs';
import {
  selectCurrentDetection
} from '../../../../features/detection/detection.selectors';


@Component({
  selector: 'detection-card',
  templateUrl: './detection-card.component.html',
  styleUrls: ['../card.component.scss'],
})
export class DetectionCardComponent {

  @Input({required: true}) detection: Detection | undefined;
  detectionStatusEnum = DetectionStatus;
  detectionCreateModeEnum = DetectionMode;

  constructor(private store: Store) {
  }

  get confidence(): number {
    if (this.detection?.createMode == DetectionMode.USER) {
      return 100.0;
    }
    else {
      return (this.detection?.confidence) ? this.detection.confidence * 100.0 : 0
    }
  }

  get evolution(): number {
    return (this.detection?.evolution) ? this.detection.evolution * 100.0 : 0
  }

  get selectedItem$(): Observable<Detection | null | undefined> {
    return this.store.select(selectCurrentDetection);
  }

  onClickFlyTo(detection: Detection): void {
    const cameraConfiguration = CesiumTools.cameraConfigurationFromTargetAndDirection(detection.center, detection.cameraDirection, detection.length);
    if (!cameraConfiguration) return;
    this.store.dispatch(setCameraMode({cameraMode: CameraMode.MODE_3D}));
    this.store.dispatch(setCameraConfiguration({cameraConfiguration}));
  }

  onClickEdit(detection: Detection): void {
    this.store.dispatch(selectDetection({id: detection.id}));
  }
}
