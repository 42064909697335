<div class="layer" *ngIf="displayModeExtended"></div>
<mat-card class="container"
          *ngIf="cut && {site: currentSite$ | async, batch: currentBatch$ | async} as items"
          [ngClass]="{'expanded': displayModeExtended}"
          [@displayMode]="displayModeExtended ? 'expanded' : 'collapsed'"
          (@displayMode.done)="onAnimationDone()" >
  <mat-card-header>
    <mat-card-title-group>
      <mat-card-title>{{cut.name}}</mat-card-title>
      <mat-card-subtitle>{{cut.center | wgs84}}</mat-card-subtitle>
      <button mat-icon-button
              class="close-button material-symbols-outlined"
              (click)="onClickClose()">close
      </button>
    </mat-card-title-group>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content class="content">
    <div [ngClass]="{
        'content-extended': displayModeExtended,
        'content-collapsed' : !displayModeExtended}">

      <div *ngIf="graph.chart">
          <div *ngIf="!displayModeExtended" class="datasets-selector-row-direction">
            @for (dataset of graph.chart.data.datasets; track dataset; let i = $index) {
              <div *ngIf="i <= 1 || dataset.label == formatDate(items?.batch?.dateAcquired)"
                    class="dataset-row"
                   (click)="toggleHiddenChartDataset(dataset.hidden, i)">
                <div *ngIf="dataset.hidden"
                      style="background-color: transparent!important; border: 2px solid {{dataset.backgroundColor}}; width: 30px; height:15px;"></div>
                <div *ngIf="!dataset.hidden"
                      style="background-color: {{dataset.backgroundColor}}; border: 2px solid {{dataset.backgroundColor}}; width: 30px; height:15px;"></div>
                <div class="dataset-row-label">{{dataset.label}}</div>
              </div>
            }
          </div>
          <div *ngIf="displayModeExtended" class="datasets-selector-column-direction">
            @for (dataset of graph.chart.data.datasets; track dataset; let i = $index) {
              <div class="dataset-row"
                   (click)="toggleHiddenChartDataset(dataset.hidden, i)">
                <div *ngIf="dataset.hidden"
                      style="background-color: transparent!important; border: 2px solid {{dataset.backgroundColor}}; width: 30px; height:15px;"></div>
                <div *ngIf="!dataset.hidden"
                      style="background-color: {{dataset.backgroundColor}}; border: 2px solid {{dataset.backgroundColor}}; width: 30px; height:15px;"></div>
                <div class="dataset-row-label">{{dataset.label}}</div>
              </div>
            }
          </div>
      </div>
      <cut-chart #graph
                 class="chart"
                 (slopeDragging)="onSlopeDragging($event)"
                 (slopeHasChanged)="onSlopeHasChanged($event)">
      </cut-chart>

      <div class="infos">
        <div class="add-slope" *ngIf="slope.length == 0 && displayModeExtended && user?.name == cut.author">
          <button mat-button color="primary"
                  (click)="graph.isDrawingSlope=true"
                  [disabled]="graph.isDrawingSlope">{{'add_slope' | transloco}}</button>
        </div>
        <div *ngIf="slope.length">
          <div *ngIf="horizontalDistance">
            <div class="module-title">{{'horizontal_distance_from_slope' | transloco | uppercase}} :</div>
            <div class="indicators">{{ horizontalDistance.toFixed(2) }} m</div>
          </div>
          <div *ngIf="verticalDistance">
            <div class="module-title">{{'vertical_distance_from_slope' | transloco | uppercase}} :</div>
            <div class="indicators">{{ verticalDistance.toFixed(2) }} m</div>
          </div>
          <div *ngIf="angle">
            <div class="module-title">{{'slope_angle' | transloco | uppercase}} :</div>
            <div class="indicators">{{ angle.toFixed(2) }}°</div>
          </div>
        </div>
        <div *ngIf="user?.name != cut.author" class="author">{{'created_by_f' | transloco }} {{cut.author}}</div>
        <cut-history-channel [cutId]="cut.id"></cut-history-channel>
      </div>
    </div>

  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions>
    <div class="left-actions">
      <button *ngIf="user?.name == cut.author"
              mat-icon-button
              matTooltip="{{'delete' | transloco}}"
              (click)="onClickDelete(cut)">delete
      </button>
      <div *ngIf="user?.name == cut.author" class="vertical-separator"></div>
      <button mat-icon-button
              matTooltip="{{'move_the_camera_on_the_cut' | transloco}}"
              (click)="onClickFlyTo(cut)">location_on
      </button>
      <button mat-icon-button
              matTooltip="{{'toggle_show' | transloco}}"
              (click)="onClickToggleShow(cut)">
        <mat-icon *ngIf="cut.show">visibility</mat-icon>
        <mat-icon *ngIf="!cut.show">visibility_off</mat-icon>
      </button>
      <button *ngIf="user?.name == cut.author"
              mat-icon-button
              matTooltip="{{'rename' | transloco}}"
              (click)="onClickRename(cut)">
        <mat-icon>edit</mat-icon>
      </button>
      <button *ngIf="!cut.isShared && user?.name == cut.author"
              mat-icon-button
              matTooltip="{{'share' | transloco}}"
              (click)="onClickToggleShare(cut)">
        <mat-icon>share</mat-icon>
      </button>
      <button *ngIf="cut.isShared && user?.name == cut.author"
              mat-icon-button
              matTooltip="{{'share_off' | transloco}}"
              (click)="onClickToggleShare(cut)">
        <mat-icon>share_off</mat-icon>
      </button>
      <button mat-icon-button
              matTooltip="{{'download' | transloco}}"
              [matMenuTriggerFor]="menu">download
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="onClickExportGraph(cut)">{{'export_graph' | transloco}}</button>
        <!--        <button mat-menu-item (click)="onClickExportData(cut)">{{'export_data' | transloco}}</button>-->
        <button mat-menu-item (click)="onClickExportDXF(cut)">{{'export_dxf' | transloco}}</button>
      </mat-menu>
    </div>
    <span class="spacer"></span>
    <button *ngIf="!displayModeExtended"
            mat-icon-button
            matTooltip="{{'extend' | transloco}}"
            (click)="onClickToggleExtend()">
      <mat-icon>open_in_full</mat-icon>
    </button>
    <button *ngIf="displayModeExtended"
            mat-icon-button
            matTooltip="{{'collapse' | transloco}}"
            (click)="onClickToggleExtend()">
      <mat-icon>close_fullscreen</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
