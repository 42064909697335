import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Measure } from '../../../../models/measure';
import {
  selectCurrentMeasure
} from '../../../../features/measure/measure.selectors';
import {
  deleteMeasure,
  selectMeasure, setMeasureShow, updateMeasure
} from '../../../../features/measure/measure.actions';
import {
  selectDetection
} from '../../../../features/detection/detection.actions';
import { selectCut } from '../../../../features/cut/cut.actions';
import { selectVolume } from '../../../../features/volume/volume.actions';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  DeleteConfirmDialog
} from '../../../dialogs/delete-confirm-dialog/delete-confirm.dialog';
import { RenameDialog } from '../../../dialogs/rename-dialog/rename.dialog';
import {
  setCameraConfiguration,
  setCameraMode,
  setMapHandleKeyboard
} from '../../../../features/cesium/cesium.actions';
import { ExportTools } from '../../../../tools/export.tools';
import { CesiumTools } from '../../../../tools/cesium.tools';
import { CameraMode } from '../../../../enums/camera';
import { TranslocoService } from '@jsverse/transloco';
import { selectCurrentSite } from '../../../../features/site/site.selectors';
import { first, Observable } from 'rxjs';
import { Site } from '../../../../models/site';
import { User } from '../../../../models/user';
import { selectUser } from '../../../../features/user/user.selectors';
import {
  selectTrackerElevation,
  selectTrackerImage
} from '../../../../features/tracker/tracker.actions';


@Component({
  selector: 'measure-details',
  templateUrl: './measure-details.component.html',
  styleUrls: ['../details.component.scss', 'measure-details.component.scss'],
})
export class MeasureDetailsComponent {

  measure: Measure | null | undefined;
  private deleteDialogRef: MatDialogRef<DeleteConfirmDialog> | undefined;
  private renameDialogRef: MatDialogRef<RenameDialog> | undefined;

  constructor(private store: Store, private dialog: MatDialog, private translocoService: TranslocoService) {
    this.store.select(selectCurrentMeasure)
      .pipe(takeUntilDestroyed())
      .subscribe((measure: Measure | null | undefined) => {
        this.measure = measure;
        if (this.measure) {
          this.store.dispatch(selectVolume({id: undefined}));
          this.store.dispatch(selectDetection({id: undefined}));
          this.store.dispatch(selectCut({id: undefined}));
          this.store.dispatch(selectTrackerImage({id: undefined}));
          this.store.dispatch(selectTrackerElevation({id: undefined}));
        }
      });
  }

  onHandleFocus(): void {
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: false}));
  }

  onFocusLeave(): void {
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: true}));
  }

  onClickFlyTo(measure: Measure): void {
    const cameraConfiguration = CesiumTools.cameraConfigurationFromTargetAndDirection(measure.center, measure.cameraDirection, measure.length);
    if (!cameraConfiguration) return;
    this.store.dispatch(setCameraMode({cameraMode: CameraMode.MODE_3D}));
    this.store.dispatch(setCameraConfiguration({cameraConfiguration}));
  }


  onClickClose(): void {
    this.store.dispatch(selectMeasure({id: undefined}));
  }

  onClickDelete(measure: Measure): void {
    this.deleteDialogRef = this.dialog.open(DeleteConfirmDialog, {
      disableClose: false
    });
    this.deleteDialogRef.componentInstance.message = this.translocoService.translate("would_you_like_to_delete", {name: measure.name});
    this.deleteDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.store.select(selectCurrentSite)
          .pipe(first())
          .subscribe((site: Site | null | undefined) => {
            if (site) {
              this.store.dispatch(deleteMeasure({
                organizationId: site.organization,
                siteId: site.id,
                batchId: site.lastBatch,
                measureId: measure.id
              }));
            }
          });
      }
    });
  }

  onClickExportData(measure: Measure): void {
    ExportTools.exportJSON(measure.name, JSON.stringify(measure));
  }

  onClickRename(measure: Measure): void {
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: false}));
    this.renameDialogRef = this.dialog.open(RenameDialog, {
      disableClose: false
    });
    this.renameDialogRef.componentInstance.placeholder = measure.name;
    this.renameDialogRef.afterClosed().subscribe(result => {
      this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: true}));
      if(result && this.measure) {
        this.store.select(selectCurrentSite)
          .pipe(first())
          .subscribe((site: Site | null | undefined) => {
            if (site) {
              this.store.dispatch(updateMeasure({
                organizationId: site.organization,
                siteId: site.id,
                batchId: site.lastBatch,
                measureId: measure.id,
                payload: {
                  name: result,
                  is_shared: measure.isShared
                }}));
            }
          });
      }
    });
  }

  onClickToggleShare(measure: Measure): void {
    this.store.select(selectCurrentSite)
      .pipe(first())
      .subscribe((site: Site | null | undefined) => {
        if (site) {
          this.store.dispatch(updateMeasure({
            organizationId: site.organization,
            siteId: site.id,
            batchId: site.lastBatch,
            measureId: measure.id,
            payload: {
              name: measure.name,
              is_shared: !measure.isShared
            }}));
        }
      });
  }

  onClickToggleShow(measure: Measure): void {
    measure.show = !measure.show;
    this.store.dispatch(setMeasureShow({id: measure.id, show: measure.show}));
  }

  get currentUser$(): Observable<User | undefined> {
    return this.store.select(selectUser)
  }
}
