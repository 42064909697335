import {
  AfterViewInit,
  Component,
  ElementRef, EventEmitter, HostListener, Input, Output,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { combineLatest } from 'rxjs';
import { ActiveElement, Chart } from 'chart.js/auto';
import { ChartData, ChartOptions } from 'chart.js/dist/types';
import { selectCurrentSite } from '../../../features/site/site.selectors';
import { selectCurrentCut } from '../../../features/cut/cut.selectors';
import { Cut, CutBatchData } from '../../../models/cut';
import { Color } from '../../../enums/color';
import { getRelativePosition } from 'chart.js/helpers';
import { DatePipe } from '@angular/common';
import { TranslocoService } from '@jsverse/transloco';
import { selectUser } from '../../../features/user/user.selectors';
import {
  selectCurrentReferenceBatchId
} from '../../../features/batch/batch.selectors';



@Component({
  selector: 'cut-chart',
  template: '<canvas #canvas (mousedown)="onMouseDown($event)" (mouseup)="onMouseUp($event)" (mousemove)="onMouseMove($event)"></canvas>',
  styles: ['canvas { width: 100%; height: 100%}']
})
export class CutChartComponent implements AfterViewInit {

  @ViewChild('canvas') canvasRef: ElementRef<HTMLCanvasElement> | undefined;

  @Input() canEditSlope: boolean = false;
  @Output() slopeDragging: EventEmitter<Array<Array<number>>> = new EventEmitter();
  @Output() slopeHasChanged: EventEmitter<Array<Array<number>>> = new EventEmitter();
  chart: any;
  isDrawingSlope: boolean = false;
  private cut: Cut | null | undefined;
  private config: any;
  private isDragging: boolean = false;
  private slopeElement: ActiveElement | undefined;
  private slope: Array<Array<number>> | undefined;

  constructor(private store: Store, private translocoService: TranslocoService) {
    combineLatest([
      this.store.select(selectCurrentSite),
      this.store.select(selectCurrentCut),
      this.store.select(selectCurrentReferenceBatchId),
      this.store.select(selectUser)
    ]).pipe(takeUntilDestroyed())
      .subscribe(contents => {
        const site:any = contents[0];
        this.cut = contents[1];
        const currentBatchId = contents[2];
        const user = contents[3];

        if (site && this.cut && user) {
          this.slope = Object.assign([], this.cut.slope);
          const dateFormat = user.languageCode == 'fr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
          const datePipe: DatePipe = new DatePipe(this.translocoService.getActiveLang());
          let count:number = 0;
          let datasets = this.cut.data
            .sort((a, b) => (a.batch == site.lastBatch ? -1 : 1))
            .map((profile:CutBatchData) => {
              let color;
              if (profile.batch == site.lastBatch) {
                color = Color.ANALOGOUS_1
              }
              else {
                const h:number = 225-count;
                color = 'hsl('+ h.toString() +', 100%, 33%)';
                count += 22.5;
              }

              const scatter: any = {
                type: 'scatter',
                hidden: (profile.batch != currentBatchId && profile.batch != site.lastBatch),
                label: datePipe.transform(new Date(profile.dateAcquired), dateFormat),
                data: profile.dataset,
                pointRadius: 1,
                borderColor: color,
                backgroundColor: color,
              };
              return scatter;
            });

          if (this.slope.length) {
            this.addSlopeDataset(datasets, this.slope, user.name == this.cut.author, );
          }

          const data: ChartData = {
            datasets
          }

          const options: ChartOptions = {
            onClick: (event: any, elements: ActiveElement[]) => {
              if (!this.isDrawingSlope) return;

              const xValue = CutChartComponent.convertPositionToValue(
                event.x,
                event.chart.chartArea.left,
                event.chart.chartArea.right,
                event.chart.scales.x.min,
                event.chart.scales.x.max);

              const yValue = CutChartComponent.convertPositionToValue(
                event.y,
                event.chart.chartArea.bottom,
                event.chart.chartArea.top,
                event.chart.scales.y.min,
                event.chart.scales.y.max);

              const slopeDataset = event.chart.config.data.datasets.find((d:any) => d.type == 'line');
              if (slopeDataset) {
                slopeDataset.data[1] = [xValue, yValue];
                this.isDrawingSlope = false;
                this.resize();
              }
              else {
                this.addSlopeDataset(event.chart.config.data.datasets, [[xValue, yValue], [xValue, yValue]],true);
                this.resize();
                if (this.slope) {
                  this.slope[0] = [xValue, yValue];
                }
              }
            },
            onHover: (event: any, elements: ActiveElement[], chart: any) => {
              const filteredElements = elements?.filter((e) => e.datasetIndex == 0);
              const element = (filteredElements?.length) ? filteredElements[0] : undefined;
              if (this.isDrawingSlope) {
                document.body.style.cursor = 'crosshair';
                const slopeDataset = event.chart.config.data.datasets.find((d:any) => d.type == 'line');
                if (slopeDataset) {
                  this.slopeElement = element;
                }
              }else {
                this.slopeElement = element;
                if (this.isDragging) {
                  document.body.style.cursor = 'grabbing';
                }
                else {
                  document.body.style.cursor = (this.slopeElement) ? 'grab' : 'default';
                }
              }
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
            animation: {
              duration: 0
            },
            scales: {
              x:{
                type: 'linear',
              },
              y:{
                type: 'linear',
              },
            }
          }
          this.config = {
            data,
            options,
          }
          this.draw();
        }
      });
  }

  private static convertPositionToValue(value: number, start1: number, stop1: number, start2: number, stop2: number): number {
    return start2 + (stop2 - start2) * ((value - start1) / (stop1 - start1))
  };

  private addSlopeDataset(datasets: any, data: Array<Array<number>>, editable:boolean,): void {
    const color = Color.TRIADIC_2;
    let pointStyle = undefined;
    let pointRadius = 0;
    if (editable) {
      pointStyle = "circle"
      pointRadius = 5;
    }

    datasets.unshift({
      type: 'line',
      label: this.translocoService.translate("slope"),
      data: Object.assign([], data),
      fill: false,
      pointStyle: pointStyle,
      pointRadius: pointRadius,
      pointHitRadius: pointRadius * 2,
      pointHoverRadius: pointRadius * 2,
      pointFill: true,
      borderColor: color,
      backgroundColor: color,
      pointBackgroundColor: color,
    });
  }

  private draw(): void {
    if (this.canvasRef?.nativeElement) {
      const canvas = this.canvasRef.nativeElement;
      const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
      if (ctx) {
        if (this.chart) this.chart.destroy();
        this.chart = new Chart(ctx, this.config);
        setTimeout(()=>{
          this.resize();
        }, 1);
      }
    }
  }

  resize(): void {
    if (!this.chart) return;

    let xMin:number | undefined = undefined;
    let yMin:number | undefined = undefined;
    let xMax:number | undefined = undefined;
    let yMax:number | undefined = undefined;
    this.chart.config.data.datasets.forEach((dataset: any) => {
      dataset.data.forEach((point: any) => {
        const x = parseFloat(point[0]);
        const y = parseFloat(point[1]);
        xMin = (!xMin) ? x : ((x < xMin) ? x : xMin);
        yMin = (!yMin) ? y : ((y < yMin) ? y : yMin);
        xMax = (!xMax) ? x : ((x > xMax) ? x : xMax);
        yMax = (!yMax) ? y : ((y > yMax) ? y : yMax);
      });
    });

    if (!xMin || !yMin || !xMax || !yMax) return;

    xMin = Math.floor(xMin);
    yMin = Math.floor(yMin);
    xMax = Math.ceil(xMax);
    yMax = Math.ceil(yMax);

    const chartAreaWidth = this.chart.chartArea.width;
    const chartAreaHeight = this.chart.chartArea.height;
    let pxPerUnityX:number = chartAreaWidth / (xMax - xMin);
    let pxPerUnityY:number = chartAreaHeight / (yMax - yMin);

    let options:any = this.chart.config.options
    if (pxPerUnityX < pxPerUnityY) {
      options.scales.x.min = xMin;
      options.scales.x.max = xMax;
      options.scales.y.min = yMin;
      options.scales.y.max = Math.floor(yMin + (chartAreaHeight / pxPerUnityX));
    } else {
      options.scales.x.min = xMin;
      options.scales.x.max = Math.floor(xMin + (chartAreaWidth / pxPerUnityY));
      options.scales.y.min = yMin;
      options.scales.y.max = yMax;
    }
    this.chart.update();
  }

  ngAfterViewInit(): void {
    this.draw();
  }

  canvasToImage(filename: string): void {
    if (this.canvasRef?.nativeElement) {
      const canvas = this.canvasRef.nativeElement;
      let canvasUrl = canvas.toDataURL("image/png");
      const createEl = document.createElement('a');
      createEl.href = canvasUrl;
      createEl.download = filename.replace(/ /g,"_");
      createEl.click();
      createEl.remove();
    }
  }

  onMouseDown(event: any): void {
    if (this.slopeElement) {
      this.isDragging = true;
    }
  }

  onMouseUp(event: any): void {
    this.isDragging = false;
    document.body.style.cursor = 'default';
    if (this.slopeElement && this.slope) {
      this.slopeHasChanged.next(this.slope);
    }
  }

  onMouseMove(event: any): void {
    if ((this.isDragging || this.isDrawingSlope) && this.slopeElement) {
      const canvasPosition = getRelativePosition(event, this.chart);

      let valX = this.chart.scales.x.getValueForPixel(canvasPosition.x);
      let posX = canvasPosition.x;

      let valY = this.chart.scales.y.getValueForPixel(canvasPosition.y);
      let posY = canvasPosition.y;

      this.slopeElement.element.x = posX;
      this.slopeElement.element.y = posY;

      if (this.slope) {
        if (this.isDrawingSlope) {
          this.slope[1] = [valX, valY];
        }
        else {
          this.slope[this.slopeElement.index] = [valX, valY];
        }
        this.slopeDragging.next(this.slope);
      }
    }
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.isDrawingSlope && event.code == 'Escape') {
      this.isDrawingSlope = false;
      this.slope = [];
      const slopeDataset = this.chart.config.data.datasets.find((d:any) => d.type == 'line');
      if (slopeDataset) {
        let removalIndex = this.chart.config.data.datasets.indexOf(slopeDataset);
        if(removalIndex >= 0) {
          this.chart.config.data.datasets.splice(removalIndex, 1);
          this.slopeHasChanged.next(this.slope);
        }
      }
    }
  }

  setChartDatasetVisible(datasetIndex: number, visible:boolean): void {
    const dataset = this.chart.data.datasets[datasetIndex];
    dataset.hidden = !visible;
    this.draw();
  }

}
