import {
  Component,
  ElementRef,
  Input, OnChanges, SimpleChanges,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Comment } from '../../../models/comment';
import { setMapHandleKeyboard } from '../../../features/cesium/cesium.actions';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@jsverse/transloco';
import { selectCurrentSite } from '../../../features/site/site.selectors';
import { first } from 'rxjs';
import { Site } from '../../../models/site';
import {
  addTracker, addTrackerComment,
  addTrackerCommentFailure,
  addTrackerCommentSuccess, loadTrackerComments,
  loadTrackerCommentsSuccess
} from '../../../features/tracker/tracker.actions';


@Component({
  selector: 'tracker-history-channel',
  templateUrl: './history-channel.component.html',
  styleUrls: ['./history-channel.component.scss'],
})
export class TrackerHistoryChannelComponents implements OnChanges {

  @Input({required: true}) trackerId: string | undefined;
  comments: Array<Comment> = [];
  @ViewChild('editor') textareaRef: ElementRef<HTMLTextAreaElement> | undefined;
  @ViewChild('commentList') commentsRef: ElementRef<HTMLDivElement> | undefined;

  constructor(private store: Store,
              private actions: Actions,
              private toastr: ToastrService,
              private translocoService: TranslocoService) {
    this.initHandleComments();
  }

  private initHandleComments(): void {
    this.actions.pipe(ofType(loadTrackerCommentsSuccess), takeUntilDestroyed())
      .subscribe(result => {
        this.comments = Object.assign([], result.comments);
        this.scrollToBottom();
      });
    this.actions.pipe(ofType(addTrackerCommentSuccess), takeUntilDestroyed())
      .subscribe(result => {
        this.comments.push(result.comment);
        this.scrollToBottom();
        this.clearComment();
      });
    this.actions.pipe(ofType(addTrackerCommentFailure), takeUntilDestroyed())
      .subscribe(result => {
        this.toastr.error(this.translocoService.translate("add_comment_failure"));
      });
  }

  private loadComments() {
    this.store.select(selectCurrentSite)
      .pipe(first())
      .subscribe((site: Site | null | undefined) => {
        if (site && this.trackerId) {
          this.store.dispatch(loadTrackerComments({
            organizationId: site.organization,
            siteId: site.id,
            trackerId: this.trackerId,
          }))
        }
      });
  }

  private clearComments() {
    this.comments = [];
  }

  private clearComment() {
    if (this.textareaRef) {
      this.textareaRef.nativeElement.value = '';
    }
  }

  private scrollToBottom(): void {
    setTimeout(() => {
      if (this.commentsRef) {
        this.commentsRef.nativeElement.scrollTop = this.commentsRef.nativeElement.scrollHeight;
      }
    }, 0);
  }

  dateToTimestamp(date: string): number {
    return new Date(date).getTime();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.clearComments();
    this.loadComments();
  }

  onHandleFocus(): void {
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: false}));
  }

  onFocusLeave(): void {
    this.store.dispatch(setMapHandleKeyboard({mapHandleKeyboard: true}));
  }

  onClickAddComment(): void {
    this.store.select(selectCurrentSite)
      .pipe(first())
      .subscribe((site: Site | null | undefined) => {
        if (site && this.trackerId && this.textareaRef) {
          this.store.dispatch(addTrackerComment({
            organizationId: site.organization,
            siteId: site.id,
            trackerId: this.trackerId,
            payload: {'text': this.textareaRef.nativeElement.value}
          }))
        }
      });
  }
}
