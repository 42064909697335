import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as CutActions from './cut.actions';
import { Deserialize } from 'cerialize';
import { ApiService } from '../../services/api.service';
import { Cut } from '../../models/cut';
import { Store } from '@ngrx/store';
import { TranslocoService } from '@jsverse/transloco';
import { Comment } from '../../models/comment';
import * as MeasureActions from '../measure/measure.actions';

@Injectable()
export class CutEffects {

  loadCuts$ = createEffect(() => {
    return this.actions$.pipe(ofType(CutActions.loadCuts),
      concatMap(({organizationId, siteId}) =>
        this.api.cuts$(organizationId, siteId).pipe(
          map(cuts => cuts.map((cut: any) => {
            const obj: Cut = Deserialize(cut, Cut);
            if (!obj.name) {
              obj.name = this.translocoService.translate('cut') + " " + obj.id.slice(0,4).toUpperCase();
            }
            return obj;
          })),
          map(cuts => CutActions.loadCutsSuccess({ cuts })),
          catchError(error => of(CutActions.loadCutsFailure({ error }))))
      ));
  });

  addCut$ = createEffect(() => {
    return this.actions$.pipe(ofType(CutActions.addCut),
      concatMap(({organizationId, siteId, point1, point2}) =>
      this.api.addCut$(organizationId, siteId, point1, point2).pipe(
        map(cut => {
          const obj: Cut = Deserialize(cut, Cut);
          obj.name = this.translocoService.translate('cut') + " " + obj.id.slice(0,4).toUpperCase();
          return obj;
        }),
        map(cut => CutActions.addCutSuccess({ cut })),
        catchError(error => of(CutActions.addCutFailure({ error }))))
    ));
  });

  deleteCut$ = createEffect(() => {
    return this.actions$.pipe(ofType(CutActions.deleteCut),
      concatMap(({organizationId, siteId, cutId}) =>
        this.api.deleteCut$(organizationId, siteId, cutId).pipe(
          map(cut =>  CutActions.deleteCutSuccess({ id: cutId })),
          catchError(error => of(CutActions.deleteCutFailure({ error }))))
      ));
  });

  updateCut$ = createEffect(() => {
    return this.actions$.pipe(ofType(CutActions.updateCut),
      concatMap(({organizationId, siteId, cutId, payload}) =>
        this.api.updateCut$(organizationId, siteId, cutId, payload).pipe(
          map(cut => Deserialize(cut, Cut)),
          map(cut => {
            return {id: cut.id, changes: cut};
          }),
          map(update => CutActions.updateCutSuccess({ update })),
          catchError(error => of(CutActions.updateCutFailure({ error }))))
      ));
  });

  exportCutDXF$ = createEffect(() => {
    return this.actions$.pipe(ofType(CutActions.exportCutDXF),
      concatMap(({organizationId, siteId, cutId}) =>
        this.api.exportCutDXF$(organizationId, siteId, cutId).pipe(
          map(dxf => CutActions.exportCutDXFSuccess({ dxf })),
          catchError(error => of(CutActions.exportCutDXFFailure({ error }))))
      ));
  });

  exportCutsBookDXF$ = createEffect(() => {
    return this.actions$.pipe(ofType(CutActions.exportCutsBookDXF),
      concatMap(({organizationId, siteId}) =>
        this.api.exportCutsBookDXF$(organizationId, siteId).pipe(
          map(dxf => CutActions.exportCutsBookDXFSuccess({ dxf })),
          catchError(error => of(CutActions.exportCutsBookDXFFailure({ error }))))
      ));
  });


  exportCutsCSV$ = createEffect(() => {
    return this.actions$.pipe(ofType(CutActions.exportCutsCSV),
      concatMap(({organizationId, siteId}) =>
        this.api.exportCutsCSV$(organizationId, siteId).pipe(
          map(csv => CutActions.exportCutsCSVSuccess({ csv })),
          catchError(error => of(CutActions.exportCutsCSVFailure({ error }))))
      ));
  });


  loadCutComments$ = createEffect(() => {
    return this.actions$.pipe(ofType(CutActions.loadCutComments),
      concatMap(({organizationId, siteId, cutId}) =>
        this.api.cutComments$(organizationId, siteId, cutId).pipe(
          map(comments => comments.map((comment: any) => Deserialize(comment, Comment))),
          map(comments => CutActions.loadCutCommentsSuccess({ comments })),
          catchError(error => of(CutActions.loadCutCommentsFailure({ error }))))
      ));
  });

  addCutComment$ = createEffect(() => {
    return this.actions$.pipe(ofType(CutActions.addCutComment),
      concatMap(({organizationId, siteId, cutId, payload}) =>
        this.api.addCutComment$(organizationId, siteId, cutId, payload).pipe(
          map(comment => Deserialize(comment, Comment)),
          map(comment => CutActions.addCutCommentSuccess({ comment })),
          catchError(error => of(CutActions.addCutCommentFailure({ error }))))
      ));
  });

  addCuts$ = createEffect(() => {
    return this.actions$.pipe(ofType(CutActions.addCuts),
      concatMap(({organizationId, siteId, cuts}) =>
        this.api.addCuts$(organizationId, siteId, cuts).pipe(
          map(cuts => cuts.map((cut: any) => {
            const obj: Cut = Deserialize(cut, Cut);
            if (!obj.name) {
              obj.name = this.translocoService.translate('cut') + " " + obj.id.slice(0,4).toUpperCase();
            }
            return obj;
          })),
          map(cuts => CutActions.addCutsSuccess({ cuts })),
          catchError(error => of(CutActions.addCutsFailure({ error }))))
      ));
  });


  deleteCuts$ = createEffect(() => {
    return this.actions$.pipe(ofType(CutActions.deleteCuts),
      concatMap(({organizationId, siteId, ids}) =>
        this.api.deleteCuts$(organizationId, siteId, ids).pipe(
          map(result =>  CutActions.deleteCutsSuccess({ ids: result.ids })),
          catchError(error => of(CutActions.deleteCutsFailure({ error }))))
      ));
  });

  shareCuts$ = createEffect(() => {
    return this.actions$.pipe(ofType(CutActions.shareCuts),
      concatMap(({organizationId, siteId, ids}) =>
        this.api.shareCuts$(organizationId, siteId, ids).pipe(
          map(result =>  CutActions.shareCutsSuccess({ ids: result.ids })),
          catchError(error => of(CutActions.shareCutsFailure({ error }))))
      ));
  });

  shareOffCuts$ = createEffect(() => {
    return this.actions$.pipe(ofType(CutActions.shareOffCuts),
      concatMap(({organizationId, siteId, ids}) =>
        this.api.shareOffCuts$(organizationId, siteId, ids).pipe(
          map(result =>  CutActions.shareOffCutsSuccess({ ids: result.ids })),
          catchError(error => of(CutActions.shareOffCutsFailure({ error }))))
      ));
  });

  constructor(private store: Store,
              private actions$: Actions,
              private api: ApiService,
              private translocoService: TranslocoService) {}

}
