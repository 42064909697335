<mat-card>
  <mat-card-header>
    <mat-card-title>
      <mat-icon>tune</mat-icon>&nbsp;{{'filters' | transloco}}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="content" *ngIf="(detections$ | async) as detections">

    <div class="content-section">
      <label>{{'confidence' | transloco}}</label>

      <filter-slider
        [(data)]="confidenceFilterSlide">
      </filter-slider>
    </div>

    <div class="content-section">
      <label>{{'create_modes' | transloco}}</label>
      <ul>
        @for (item of detections | distinctDetectionCreateModes; track item) {
          <li>
            <mat-checkbox
              [checked]="isSelected(item, detectionFilterKeysEnum.CREATE_MODES)"
              (change)="updateConfig(item, detectionFilterKeysEnum.CREATE_MODES)">
            {{item | formatDetectionMode}}
            </mat-checkbox>
          </li>
        }
      </ul>
    </div>

    <div class="content-section labels">
      <label>{{'labels' | transloco}}</label>
      <ul>
        @for (item of detections | distinctDetectionLabels; track item) {
          <li>
            <mat-checkbox
              [checked]="isSelected(item, detectionFilterKeysEnum.LABELS)"
              (change)="updateConfig(item, detectionFilterKeysEnum.LABELS)">
              {{item}}
            </mat-checkbox>
          </li>
        }
      </ul>
    </div>

  </mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="dialogRef.close()">{{'clear_all' | transloco}}</button>
    <button mat-button
            cdkFocusInitial
            color="primary"
            (click)="dialogRef.close(validConfig())">{{'validate' | transloco}}</button>
  </mat-card-actions>
</mat-card>
