
export class FileTools {

  static csvFileToArray(content: any, separator: string): Array<Array<string>> {
    const data: any[] = [];
    const rows = content.split('\n');
    for (let i = 0; i < rows.length; i++) {
      const columns = rows[i].split(separator);
      const textColumns = columns.filter((value:any) => isNaN(Number(value)));
      if (textColumns.length <= columns.length -1 && columns.length >= 3) {
        data.push(columns);
      }
    }
    return data;
  }

  static pFileReader(file: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = resolve;
      fr.readAsDataURL(file);
    });
  }
}
