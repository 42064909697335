import {
  Component, EventEmitter, Output,
} from '@angular/core';
import { Store } from '@ngrx/store';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { selectCurrentSite } from '../../../features/site/site.selectors';
import { Site } from '../../../models/site';
import { InspectionModule } from '../../../enums/inspection-module';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { User } from '../../../models/user';
import { selectUser } from '../../../features/user/user.selectors';
import {
  selectVolumeSummary
} from '../../../features/volume/volume.selectors';
import {
  selectDetectionSummary
} from '../../../features/detection/detection.selectors';
import { Actions, ofType } from '@ngrx/effects';
import {
  clearVolumeSummary,
  loadVolumeSummary, loadVolumeSummaryFailure,
  loadVolumeSummarySuccess
} from '../../../features/volume/volume.actions';
import {
  addDetectionEmergencySuccess,
  clearDetectionSummary,
  loadDetectionSummary,
  loadDetectionSummaryFailure,
  loadDetectionSummarySuccess
} from '../../../features/detection/detection.actions';
import {
  CreatePerreadPipelineDialog
} from '../../../shared/dialogs/create-perread-pipeline-dialog/create-perread-pipeline.dialog';
import {
  retrievePerreadPipeline,
  retrievePerreadPipelineFailure,
  retrievePerreadPipelineSuccess,
  startPerreadPipeline,
  startPerreadPipelineFailure,
  startPerreadPipelineSuccess
} from '../../../features/pipeline/pipeline.actions';
import {
  selectCurrentReferenceBatchId
} from '../../../features/batch/batch.selectors';
import { first } from 'rxjs';


@Component({
  selector: 'site-details-header',
  templateUrl: './site-details-header.component.html',
  styleUrls: ['./site-details-header.component.scss']
})
export class SiteDetailsHeaderComponent {

  site: Site | null | undefined;
  user: User | null | undefined;
  inspectionModule = InspectionModule;
  volumeSummary: any;
  isLoadingVolumeSummary: boolean = true;
  perreadPipelineAvailable: boolean = false;
  detectionSummary: any;
  isLoadingDetectionSummary: boolean = true;
  @Output() close: EventEmitter<any> = new EventEmitter()

  constructor(private store: Store, private actions: Actions, private dialog: MatDialog) {
    this.initHandlePerreadPipeline();
    this.initHandleSite();
    this.initHandleUser();
    this.initHandleVolumeSummary();
    this.initHandleDetectionSummary();
    this.initHandleBatch();
    this.initHandleDetectionEmergency();
  }

  private initHandleDetectionEmergency(): void {
    this.actions.pipe(ofType(addDetectionEmergencySuccess), takeUntilDestroyed())
      .subscribe(result => {
        if (this.site) {
          this.store.dispatch(loadDetectionSummary({
            organizationId: this.site.organization,
            siteId: this.site.id,
            batchId: this.site.lastBatch
          }));
        }
      });
  }

  private initHandlePerreadPipeline(): void {
    this.actions.pipe(ofType(
      retrievePerreadPipelineSuccess,
      startPerreadPipelineSuccess), takeUntilDestroyed())
      .subscribe(result => {
        this.perreadPipelineAvailable = !!(result['pipeline']);
      });
    this.actions.pipe(ofType(
      retrievePerreadPipelineFailure,
      startPerreadPipelineFailure), takeUntilDestroyed())
      .subscribe(result => {
        this.perreadPipelineAvailable = false;
      });
  }

  private initHandleBatch(): void {
    this.store.select(selectCurrentReferenceBatchId)
      .pipe(takeUntilDestroyed())
      .subscribe((referenceBatchId) => {
        if (referenceBatchId && this.site) {
          this.store.dispatch(retrievePerreadPipeline({
            organizationId: this.site.organization,
            siteId: this.site.id,
            comparedBatchId: this.site.lastBatch,
            referenceBatchId: referenceBatchId
          }));
        }
      });
  }

  private initHandleDetectionSummary(): void {
    this.store.select(selectDetectionSummary)
      .pipe(takeUntilDestroyed())
      .subscribe((summary) => {
        this.detectionSummary = summary;
      });
    this.actions.pipe(ofType(loadDetectionSummary), takeUntilDestroyed())
      .subscribe(result => {
        this.isLoadingDetectionSummary = true;
      });
    this.actions.pipe(ofType(loadDetectionSummarySuccess), takeUntilDestroyed())
      .subscribe(result => {
        this.isLoadingDetectionSummary = false;
      });
    this.actions.pipe(ofType(loadDetectionSummaryFailure), takeUntilDestroyed())
      .subscribe(result => {
        this.isLoadingDetectionSummary = false;
        this.store.dispatch(clearDetectionSummary());
      });
  }

  private initHandleVolumeSummary(): void {
    this.store.select(selectVolumeSummary)
      .pipe(takeUntilDestroyed())
      .subscribe((summary) => {
        this.volumeSummary = summary;
      });
    this.actions.pipe(ofType(loadVolumeSummary), takeUntilDestroyed())
      .subscribe(result => {
        this.isLoadingVolumeSummary = true;
      });
    this.actions.pipe(ofType(loadVolumeSummarySuccess), takeUntilDestroyed())
      .subscribe(result => {
        this.isLoadingVolumeSummary = false;
      });
    this.actions.pipe(ofType(loadVolumeSummaryFailure), takeUntilDestroyed())
      .subscribe(result => {
        this.isLoadingVolumeSummary = false;
        this.store.dispatch(clearVolumeSummary());
      });
  }

  private initHandleSite(): void {
    this.store.select(selectCurrentSite)
      .pipe(takeUntilDestroyed())
      .subscribe((site: Site | null | undefined) => {
        if (site) {
          this.site = site;
        }
      });
  }

  private initHandleUser(): void {
    this.store.select(selectUser)
      .pipe(takeUntilDestroyed())
      .subscribe((user: User | undefined) => {
        if (user) {
          this.user = user;
        }
      });
  }

  onClickDeselectSite() {
    this.close.next(this.site);
  }

  onClickLaunchPerreadPipeline() {
    const dialogRef: MatDialogRef<CreatePerreadPipelineDialog> = this.dialog.open(CreatePerreadPipelineDialog, {
      disableClose: false
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if(result) {
          this.store.select(selectCurrentReferenceBatchId)
            .pipe(first()).subscribe((batchId: string | undefined) => {
            if (batchId && this.site) {
              this.store.dispatch(startPerreadPipeline({
                organizationId: this.site.organization,
                siteId: this.site.id,
                comparedBatchId: this.site.lastBatch,
                referenceBatchId: batchId
              }));
            }
          });
        }
      });
  }

  get dateFormat() {
    if(this.user) {
      return (this.user.languageCode == 'fr') ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
    }
    return 'MM/dd/yyyy';
  }

}
