import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { Detection } from '../../../models/detection';
import {
  selectAllDetections
} from '../../../features/detection/detection.selectors';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  DetectionFilterDialog
} from '../../dialogs/detection-filter-dialog/detection-filter.dialog';
import {
  setConfigDetectionFilters
} from '../../../features/config/config.actions';
import {
  selectConfigDetectionFilters, selectConfigDetectionFiltersIsActive
} from '../../../features/config/config.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FilterDetectionsPipe } from '../../pipes/detection.pipe';



@Component({
  selector: 'detection-header',
  templateUrl: './detection-header.component.html',
  styleUrls: ['detection-header.component.scss']
})
export class DetectionHeaderComponent {

  detectionCount: number = 0;

  constructor(private store: Store,
              private dialog: MatDialog,
              private filter: FilterDetectionsPipe) {
    combineLatest([
      this.store.select(selectAllDetections),
      this.store.select(selectConfigDetectionFilters)
    ]).pipe(takeUntilDestroyed())
      .subscribe((results: any) => {
        this.detectionCount = this.filter.transform(results[0]).length;
      });
  }

  get detectionFiltersIsActive$(): Observable<boolean> {
    return this.store.select(selectConfigDetectionFiltersIsActive);
  }

  openFilters(): void {
    const dialogRef: MatDialogRef<DetectionFilterDialog> = this.dialog.open(DetectionFilterDialog, {
      disableClose: false
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if(result) {
          this.store.dispatch(setConfigDetectionFilters({detectionFilters: result}));
        }
        else {
          this.store.dispatch(setConfigDetectionFilters({detectionFilters: undefined}));
        }
      });
  }
}
