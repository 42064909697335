import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  setCameraConfiguration,
  setCameraMode,
} from '../../../../features/cesium/cesium.actions';
import { CesiumTools } from '../../../../tools/cesium.tools';
import { CameraMode } from '../../../../enums/camera';
import { EmergencyTools } from '../../../../tools/emergency.tools';
import { TrackerElevation } from '../../../../models/tracker';
import { Observable } from 'rxjs';
import { User } from '../../../../models/user';
import { selectUser } from '../../../../features/user/user.selectors';
import {
  selectTrackerElevation
} from '../../../../features/tracker/tracker.actions';
import {
  selectCurrentTrackerElevation
} from '../../../../features/tracker/tracker.selectors';

@Component({
  selector: 'elevation-card',
  templateUrl: './elevation-card.component.html',
  styleUrls: ['../card.component.scss'],
})
export class ElevationCardComponent {

  @Input({required: true}) elevation: TrackerElevation | undefined;

  constructor(private store: Store) {
  }

  get border() {
    return EmergencyTools.border(0);
  }

  get selectedItem$(): Observable<TrackerElevation | null | undefined> {
    return this.store.select(selectCurrentTrackerElevation);
  }

  onClickFlyTo(elevation: TrackerElevation): void {
    const cameraConfiguration = CesiumTools.cameraConfigurationFromTargetAndDirection(elevation.center, elevation.cameraDirection, elevation.length);
    if (!cameraConfiguration) return;
    this.store.dispatch(setCameraMode({cameraMode: CameraMode.MODE_3D}));
    this.store.dispatch(setCameraConfiguration({cameraConfiguration}));
  }

  onClickEdit(elevation: TrackerElevation): void {
    this.store.dispatch(selectTrackerElevation({id: elevation.id}));
  }

  get currentUser$(): Observable<User | undefined> {
    return this.store.select(selectUser)
  }
}
