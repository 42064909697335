import { Action, createReducer, on } from '@ngrx/store';
import { ConfigSideMode } from '../../enums/config';
import * as ConfigActions from './config.actions';
import { ViewRepresentation } from '../../enums/representation';

export interface State {
  sideMode: ConfigSideMode;
  displaySitesFilters: boolean,
  sitesFilters: any,
  colorRampPositiveColors: Array<string>;
  colorRampNegativeColors: Array<string> ;
  colorRampMin: number;
  colorRampMax: number;
  siteDetailSelectedSection: number | undefined;
  searchWorldText: string | undefined;
  searchBarDisplayed: boolean;
  tilesetStyleColorConditions: Array<any> | undefined;
  tilesetColorRampMin: number;
  tilesetColorRampMax: number;
  viewRepresentation: number;
  tilesetIsLoading: boolean;
  splittedTilesetIsLoading: boolean;
  pointSize: number;
  detectionFilters: any,
  isDownloadingPicture: boolean
}

export const initialState: State = {
  sideMode: ConfigSideMode.MODE_EXPANDED,
  displaySitesFilters: false,
  sitesFilters: undefined,
  colorRampPositiveColors: [],
  colorRampNegativeColors: [],
  colorRampMin: 0,
  colorRampMax: 0,
  siteDetailSelectedSection: undefined,
  searchWorldText: undefined,
  searchBarDisplayed: false,
  tilesetStyleColorConditions: undefined,
  tilesetColorRampMin: 0,
  tilesetColorRampMax: 0,
  viewRepresentation: ViewRepresentation.TEXTURED,
  tilesetIsLoading: false,
  splittedTilesetIsLoading: false,
  pointSize: 1,
  detectionFilters: undefined,
  isDownloadingPicture: false
};


const configReducer = createReducer(
  initialState,
  on(ConfigActions.setConfigSideMode, (state, { sideMode }) => {
    return { ...state, sideMode: sideMode };
  }),
  on(ConfigActions.setConfigDisplaySitesFilters, (state, { display }) => {
    return { ...state, displaySitesFilters: display };
  }),
  on(ConfigActions.setConfigSitesFilters, (state, { sitesFilters }) => {
    return { ...state, sitesFilters: sitesFilters };
  }),
  on(ConfigActions.setConfigColorRampPositiveColors, (state, { colors }) => {
    return { ...state, colorRampPositiveColors: colors };
  }),
  on(ConfigActions.setConfigColorRampNegativeColors, (state, { colors }) => {
    return { ...state, colorRampNegativeColors: colors };
  }),
  on(ConfigActions.setConfigColorRampMin, (state, { value }) => {
    return { ...state, colorRampMin: value };
  }),
  on(ConfigActions.setConfigColorRampMax, (state, { value }) => {
    return { ...state, colorRampMax: value };
  }),
  on(ConfigActions.setConfigSiteDetailSelectedSection, (state, { value }) => {
    return { ...state, siteDetailSelectedSection: value };
  }),
  on(ConfigActions.setConfigSearchWorldText, (state, { text }) => {
    return { ...state, searchWorldText: text };
  }),
  on(ConfigActions.setConfigTilesetStyleColorConditions, (state, { conditions }) => {
    return { ...state, tilesetStyleColorConditions: conditions };
  }),
  on(ConfigActions.setConfigTilesetColorRampMin, (state, { value }) => {
    return { ...state, tilesetColorRampMin: value };
  }),
  on(ConfigActions.setConfigTilesetColorRampMax, (state, { value }) => {
    return { ...state, tilesetColorRampMax: value };
  }),
  on(ConfigActions.setConfigViewRepresentation, (state, { value }) => {
    return { ...state, viewRepresentation: value };
  }),
  on(ConfigActions.setConfigSearchBarDisplayed, (state, { displayed }) => {
    return { ...state, searchBarDisplayed: displayed };
  }),
  on(ConfigActions.setConfigTilesetIsLoading, (state, { isLoading }) => {
    return { ...state, tilesetIsLoading: isLoading };
  }),
  on(ConfigActions.setConfigSplittedTilesetIsLoading, (state, { isLoading }) => {
    return { ...state, splittedTilesetIsLoading: isLoading };
  }),
  on(ConfigActions.setConfigPointCloudPointSize, (state, { pointSize }) => {
    if (pointSize) {
      return { ...state, pointSize: pointSize };
    }
    return state;
  }),
  on(ConfigActions.setConfigDetectionFilters, (state, { detectionFilters }) => {
    return { ...state, detectionFilters: detectionFilters };
  }),
  on(ConfigActions.setConfigIsDownloadingAlteredPicture, (state, { isDownloading }) => {
    return { ...state, isDownloadingPicture: isDownloading };
  }),
);

export function reducer(state: State | undefined, action: Action): any {
  return configReducer(state, action);
}
