import {
  Component,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Volume } from '../../../../models/volume';
import {
  selectAllVolumes
} from '../../../../features/volume/volume.selectors';
import { Observable } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  loadVolumes,
  loadVolumesFailure,
  loadVolumesSuccess
} from '../../../../features/volume/volume.actions';


@Component({
  selector: 'volume-list',
  templateUrl: './volume-list.component.html',
  styleUrls: ['../list.component.scss']
})
export class VolumeListComponent {

  isLoading: boolean = false;
  volumes: Array<Volume> = [];

  constructor(private store: Store, private actions: Actions) {
    this.store.select(selectAllVolumes)
      .pipe(takeUntilDestroyed())
      .subscribe((volumes: Array<Volume> | null | undefined) => {
        if (volumes) {
          this.volumes = volumes;
        } else {
          this.volumes = [];
        }
        this.isLoading = this.volumes.length == 0;
      });


    this.actions.pipe(ofType(loadVolumes), takeUntilDestroyed())
      .subscribe(result => {
        this.isLoading = true;
      });
    this.actions.pipe(ofType(loadVolumesSuccess), takeUntilDestroyed())
      .subscribe(result => {
        this.isLoading = false;
      });
    this.actions.pipe(ofType(loadVolumesFailure), takeUntilDestroyed())
      .subscribe(result => {
        this.isLoading = false;
      });
  }

  get volumes$(): Observable<Array<Volume>> {
    return this.store.select(selectAllVolumes);
  }
}
