import { autoserialize, autoserializeAs } from 'cerialize';
import { Color } from '../enums/color';

export class DetectionDensity {
  @autoserialize index!: number;
  @autoserialize count!: number;
  @autoserialize point!: Array<number>;
  @autoserializeAs('camera_direction') cameraDirection!: Array<number>;
  @autoserializeAs('rotation_matrix') rotationMatrix!: Array<Array<number>>;
  @autoserialize length!: number;
  @autoserialize dimensions!: Array<number>;
  colorHex!: string;

  constructor() {
    this.colorHex = Color.ANALOGOUS_1;
  }
}

export class VolumeDensity  {
  @autoserialize index!: number;
  @autoserialize count!: number;
  @autoserialize point!: Array<number>;
  @autoserializeAs('camera_direction') cameraDirection!: Array<number>;
  @autoserializeAs('rotation_matrix') rotationMatrix!: Array<Array<number>>;
  @autoserialize length!: number;
  @autoserialize dimensions!: Array<number>;
  colorHex!: string;

  constructor() {
    this.colorHex = Color.ANALOGOUS_2;
  }
}

export class DetectionSummary {
  @autoserializeAs('detection_count') detectionCount!: number;
  @autoserializeAs('emergency_count') emergencyCount!: number;
  @autoserializeAs('density_peak') densityPeak!: number;
  @autoserializeAs(DetectionDensity) densities!: Array<DetectionDensity>;
  @autoserialize evolution!: number;
}

export class VolumeSummary {
  @autoserializeAs('positive_volume_moved') positiveVolumeMoved!: number;
  @autoserializeAs('negative_volume_moved') negativeVolumeMoved!: number;
  @autoserializeAs('emergency_count') emergencyCount!: number;
  @autoserializeAs('density_peak') densityPeak!: number;
  @autoserializeAs(VolumeDensity) densities!: Array<VolumeDensity>;
}

export class Site {
  @autoserialize id!: string;
  @autoserialize name!: string;
  @autoserialize slug!: string;
  @autoserialize organization!: string;
  @autoserialize sector!: string;
  @autoserializeAs('emergency_level')  emergencyLevel!: number;
  @autoserializeAs('emergency_label')  emergencyLabel!: number;
  @autoserialize modules!: Array<number>;
  @autoserialize pin!: any;
  @autoserialize altitude!: number;
  @autoserialize height!: number;
  @autoserialize outline!: [];
  @autoserialize status!: number;
  @autoserializeAs('is_activable_by_user') isActivableByUser: boolean = false;
  @autoserializeAs('max_dist_to_plane') maxDistToPlane!: number;
  @autoserializeAs('last_batch') lastBatch!: string;
  @autoserializeAs('last_batch_date') lastBatchDate!: string;
  @autoserializeAs(DetectionSummary, 'detection_summary') detectionSummary!: DetectionSummary;
  @autoserializeAs('have_detection_emergency_template')  haveDetectionEmergencyTemplate!: boolean;
  @autoserializeAs(VolumeSummary, 'volume_summary') volumeSummary!: VolumeSummary;
  @autoserializeAs('have_volume_emergency_template')  haveVolumeEmergencyTemplate!: boolean;
  border!: string;
  backgroundColor!: string;
  textColor!: string;
}
