import { Pipe, PipeTransform } from '@angular/core';
import {
  TrackerImageBatchDataImage
} from '../../models/tracker';


@Pipe({name: 'sortdistance'})
export class MarkerSortDistancePipe implements PipeTransform {
  transform(data: Array<TrackerImageBatchDataImage>,): Array<TrackerImageBatchDataImage> {
    let sortArray = Object.assign([], data);
    return sortArray.sort((a: TrackerImageBatchDataImage, b: TrackerImageBatchDataImage) => {
      if (a.distance < b.distance) {
        return -1;
      }
      if (a.distance > b.distance) {
        return 1;
      }
      return 0;
    });
  }
}
