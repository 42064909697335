import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TrackerImage } from '../../../../models/tracker';
import { first, Observable } from 'rxjs';
import {
  selectAllTrackerImages
} from '../../../../features/tracker/tracker.selectors';
import { Actions, ofType } from '@ngrx/effects';
import {
  deleteTrackers,
  deleteTrackersSuccess,
  loadTrackersImage,
  loadTrackersImageFailure,
  loadTrackersImageSuccess,
  setTrackersShow,
  shareOffTrackers, shareOffTrackersSuccess,
  shareTrackers,
  shareTrackersSuccess
} from '../../../../features/tracker/tracker.actions';
import { selectCurrentSite } from '../../../../features/site/site.selectors';
import { Site } from '../../../../models/site';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';
import {
  DeleteConfirmDialog
} from '../../../dialogs/delete-confirm-dialog/delete-confirm.dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';


@Component({
  selector: 'marker-list',
  templateUrl: './marker-list.component.html',
  styleUrls: ['../list.component.scss']
})
export class MarkerListComponent {

  isLoading: boolean = false;
  selectedItemIds: Array<string> = [];

  constructor(private store: Store,
              private actions: Actions,
              private dialog: MatDialog,
              private translocoService: TranslocoService) {
    this.actions.pipe(ofType(loadTrackersImage), first())
      .subscribe(result => {
        this.isLoading = true;
      });
    this.actions.pipe(ofType(loadTrackersImageSuccess, loadTrackersImageFailure), first())
      .subscribe(result => {
        this.isLoading = false;
      });
    this.actions.pipe(ofType(deleteTrackersSuccess, shareTrackersSuccess, shareOffTrackersSuccess, setTrackersShow), takeUntilDestroyed())
      .subscribe(result => {
        this.selectedItemIds = [];
      });
  }

  get markers$(): Observable<Array<TrackerImage>> {
    return this.store.select(selectAllTrackerImages);
  }

  itemIsSelected(id: string): boolean {
    return this.selectedItemIds.indexOf(id) > -1;
  }

  onClickUpdateSelectedItemIds(checked: boolean, id: string): void {
    const index = this.selectedItemIds.indexOf(id);
    if (checked) {
      if (index < 0) {
        this.selectedItemIds.push(id);
      }
    }
    else {
      if (index > -1) {
        this.selectedItemIds.splice(index, 1);
      }
    }
  }

  allCheckboxStateChanged(checked: boolean): void {
    this.markers$
      .pipe(first())
      .subscribe((items: Array<TrackerImage>) => {
        this.selectedItemIds = (checked) ? items.map((i: TrackerImage) => i.id) : [];
      });
  }

  clickDelete(): void {
    const deleteDialogRef = this.dialog.open(DeleteConfirmDialog, {
      disableClose: false
    });
    deleteDialogRef.componentInstance.message = this.translocoService.translate("delete_selected_items", {count: this.selectedItemIds.length});
    deleteDialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.store.select(selectCurrentSite)
          .pipe(first())
          .subscribe((site: Site | null | undefined) => {
            if (site) {
              this.store.dispatch(deleteTrackers({
                organizationId: site.organization,
                siteId: site.id,
                ids: this.selectedItemIds
              }));
            }
          });
      }
    });
  }

  clickShow(): void {
    this.store.dispatch(setTrackersShow({ids: this.selectedItemIds, show: true}));
  }

  clickHide(): void {
    this.store.dispatch(setTrackersShow({ids: this.selectedItemIds, show: false}));
  }

  clickShare(): void {
    this.store.select(selectCurrentSite)
      .pipe(first())
      .subscribe((site: Site | null | undefined) => {
        if (site) {
          this.store.dispatch(shareTrackers({
            organizationId: site.organization,
            siteId: site.id,
            ids: this.selectedItemIds
          }));
        }
      });
  }

  clickSareOff(): void {
    this.store.select(selectCurrentSite)
      .pipe(first())
      .subscribe((site: Site | null | undefined) => {
        if (site) {
          this.store.dispatch(shareOffTrackers({
            organizationId: site.organization,
            siteId: site.id,
            ids: this.selectedItemIds
          }));
        }
      });
  }
}
