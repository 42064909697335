import {
  createSelector,
  createFeatureSelector,
} from '@ngrx/store';
import * as fromTracker from './tracker.reducer';
import { TrackerType } from '../../enums/tracker';
import { Tracker, TrackerElevation, TrackerImage } from '../../models/tracker';


// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = fromTracker.adapter.getSelectors();

export const selectTrackerState = createFeatureSelector<fromTracker.State>('trackers');

export const selectTrackerIds = createSelector(
  selectTrackerState,
  selectIds
);
export const selectTrackerEntities = createSelector(
  selectTrackerState,
  selectEntities
);
export const selectAllTrackers = createSelector(
  selectTrackerState,
  selectAll
);
export const selectTrackerTotal = createSelector(
  selectTrackerState,
  selectTotal
);


export const selectCurrentTrackerImageId = createSelector(
  selectTrackerState,
  fromTracker.getSelectedTrackerImageId
);
export const selectCurrentTrackerImage = createSelector(
  selectTrackerEntities,
  selectCurrentTrackerImageId,
  (entities, id) => (id) ? entities[id] as TrackerImage : null
);
export const selectAllTrackerImages = createSelector(
  selectAllTrackers,
  (trackers) => trackers.filter((e: any) => e.trackerType == TrackerType.IMAGE).map((e)=>  e as TrackerImage)
);
export const selectTrackerImageTotal = createSelector(
  selectAllTrackerImages,
  (trackers) => trackers.filter((e: Tracker) => e.trackerType == TrackerType.IMAGE).length
);



export const selectCurrentTrackerElevationId = createSelector(
  selectTrackerState,
  fromTracker.getSelectedTrackerElevationId
);
export const selectCurrentTrackerElevation = createSelector(
  selectTrackerEntities,
  selectCurrentTrackerElevationId,
  (entities, id) => (id) ? entities[id] as TrackerElevation : null
);
export const selectAllTrackerElevations = createSelector(
  selectAllTrackers,
  (trackers) => trackers.filter((e: Tracker) => e.trackerType == TrackerType.ELEVATION).map((e)=>  e as TrackerElevation)
);
export const selectTrackerElevationTotal = createSelector(
  selectAllTrackerElevations,
  (trackers) => trackers.filter((e: Tracker) => e.trackerType == TrackerType.ELEVATION).length
);
export const selectTrackersByIds = (ids: Array<string>) => createSelector(
  selectAllTrackers,
  (allItems) => {
    return allItems.filter(item => {
      return ids.includes(item.id);
    });
  });
