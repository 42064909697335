import { Component, } from '@angular/core';
import {
  animate,
  animateChild,
  query,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Store } from '@ngrx/store';
import {
  selectConfigPointCloudPointSize,
  selectConfigViewRepresentation
} from '../../../features/config/config.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ViewRepresentation } from '../../../enums/representation';
import {
  setConfigPointCloudPointSize
} from '../../../features/config/config.actions';
import { Observable } from 'rxjs';
import { MatSlider } from '@angular/material/slider';


@Component({
  selector: 'view-configuration',
  templateUrl: './view-configuration.component.html',
  styleUrls: ['./view-configuration.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ top: '-400px'}),
        query('@*', animateChild(), { optional: true }),
        animate('350ms ease-out', style({ top: '25px'})),
      ]),
      transition(':leave', [
        style({ top: '25px'}),
        animate('300ms ease-in', style({ top: '-400px'}))
      ]),
    ]),
  ],
})
export class ViewConfigurationComponent {

  isPointCloudDisplayed: boolean = false;
  pointSizeMin: number = 1;
  pointSizeMax: number = 10;

  constructor(private store: Store) {
    this.initHandleViewConfiguration();
  }

  private initHandleViewConfiguration(): void {
    this.store.select(selectConfigViewRepresentation)
      .pipe(takeUntilDestroyed()).subscribe((viewRepresentation: ViewRepresentation) => {
      this.isPointCloudDisplayed = viewRepresentation == ViewRepresentation.POINT_CLOUD
        || viewRepresentation == ViewRepresentation.SPLITTED_POINT_CLOUD
        || viewRepresentation == ViewRepresentation.DIFFERENTIAL;
    });
  }


}
