import { Volume } from '../models/volume';
import { Detection } from '../models/detection';
import { EmergencyTools } from './emergency.tools';
import {
  DetectionBackgroundColor,
  DetectionBorder,
  DetectionStatus,
  DetectionTextColor
} from '../enums/detection';
import { VolumeBackgroundColor, VolumeBorder } from '../enums/volume';
import { Site } from '../models/site';
import { Store } from '@ngrx/store';
import {
  setConfigColorRampMax,
  setConfigColorRampMin,
  setConfigColorRampNegativeColors,
  setConfigColorRampPositiveColors,
} from '../features/config/config.actions';
import {
  SiteBackgroundColor,
  SiteBorderColor,
  SiteStatus, SiteTextColor
} from '../enums/site';


export class ColorTools {

  static HEXToRGBA(hex: string, alpha: number): string {

    if (hex.charAt && hex.charAt(0) === '#') {
      const arr = hex.split('')
      arr.shift()
      hex = arr.join('')
    }

    if (hex.length === 3) {
      hex = hex
        .split('')
        .reduce((accum: any[], value: string) => {
          return accum.concat([value, value])
        }, [])
        .join('')
    }

    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return 'rgba(' + r + ',' + g + ',' + b + ',' + alpha + ')';
  }

  static RGBToHEX(rgb: any){
    const hex = [];
    for (let i = 0; i < 3; i++){
      const bit = (rgb[i] - 0).toString(16);
      hex.push((bit.length == 1) ? '0' + bit : bit);
    }
    return hex;
  }

  static HSBToRGB(hsb: any) {
    const br = Math.round(hsb[2] / 100 * 255);
    if (hsb[1] == 0){
      return [br, br, br];
    } else {
      const hue = hsb[0] % 360;
      const f = hue % 60;
      const p = Math.round((hsb[2] * (100 - hsb[1])) / 10000 * 255);
      const q = Math.round((hsb[2] * (6000 - hsb[1] * f)) / 600000 * 255);
      const t = Math.round((hsb[2] * (6000 - hsb[1] * (60 - f))) / 600000 * 255);
      switch (Math.floor(hue / 60)){
        case 0: return [br, t, p];
        case 1: return [q, br, p];
        case 2: return [p, br, t];
        case 3: return [p, q, br];
        case 4: return [t, p, br];
        case 5: return [br, p, q];
      }
    }
    return false;
  }

  static HSBToHEX(hsb: any){
    return '#' + ColorTools.RGBToHEX(ColorTools.HSBToRGB(hsb)).join('');
  }

  static tilesetStyleColorConditions(minValue: number, maxValue:number, stepNb: number=20) {
    const step = (maxValue - minValue) / stepNb;
    let conditions = [];
    for (let i = 0; i < stepNb; i++){
      const value = minValue + step * i;
      const h = 2/(3*(minValue-maxValue))*(value-minValue) + 2/3;
      const rule = "${distance} < " + String(value);
      const color = [h, 1, 0.5];
      conditions.push([rule, color]);
    }
    conditions.push(["true", [0, 1, 0.5]]);
    return conditions;
  }

  static setEmergencyColors(obj: any): any {
    if (obj.emergency){
      obj.border = EmergencyTools.border(obj.emergency.level);
      obj.backgroundColor = EmergencyTools.backgroundColor(obj.emergency.level);
      obj.textColor = EmergencyTools.textColor(obj.emergency.level);
    }
    else {
      obj.border = VolumeBorder.DEFAULT;
      obj.backgroundColor = VolumeBackgroundColor.DEFAULT;
      obj.textColor = '#ffffff';
    }
    return obj;
  }

  static setColorsForVolumes(volumes: Array<Volume>, step: number, store: Store): Array<Volume> {
    // Search max color
    let nbPositiveColors = 1;
    let nbNegativeColors = 1;
    volumes.forEach((obj: Volume) => {
      const quotient = Math.ceil(Math.abs(obj.volume) / step);
      if(obj.volume >= 0) {
        nbPositiveColors = Math.max(nbPositiveColors, quotient);
      }else {
        nbNegativeColors = Math.max(nbNegativeColors, quotient);
      }
    });

    // Generate color ramp
    let positiveColors: any[] = [];
    let negativeColors: any[] = [];

    for (let i = 0 ; i < nbPositiveColors ; i++) {
      positiveColors.push(ColorTools.HSBToHEX([0, ((i+1)/nbPositiveColors) * 100 , 100]));
    }

    for (let i = 0 ; i < nbNegativeColors ; i++) {
      negativeColors.push(ColorTools.HSBToHEX([224, ((i+1)/nbNegativeColors) * 100, 100]));
    }

    // Set color to Volume
    let min = 0;
    let max = 0;
    volumes.forEach((obj: Volume) => {
      const quotient = Math.ceil(Math.abs(obj.volume) / step);
      if(obj.volume >= 0) {
        max = Math.max(obj.volume, max);
        const index = Math.min(quotient, positiveColors.length-1);
        obj.hexColor = positiveColors[index];
      }else {
        min = Math.min(obj.volume, min);
        const index = Math.min(quotient, negativeColors.length-1);
        obj.hexColor = negativeColors[index];
      }

      ColorTools.setEmergencyColors(obj);
    });

    store.dispatch(setConfigColorRampPositiveColors({colors: positiveColors}));
    store.dispatch(setConfigColorRampNegativeColors({colors: negativeColors.reverse()}));
    store.dispatch(setConfigColorRampMin({value: min}));
    store.dispatch(setConfigColorRampMax({value: max}));

    return volumes;
  }


  static setColorsForDetection(detection: Detection): Detection {
    if (detection.emergency){
      detection.border = EmergencyTools.border(detection.emergency.level);
      detection.backgroundColor = EmergencyTools.backgroundColor(detection.emergency.level);
      detection.textColor = EmergencyTools.textColor(detection.emergency.level);
    }
    else {
      if (detection.status == DetectionStatus.TO_BE_CONFIRMED) {
        detection.border = DetectionBorder.TO_BE_CONFIRMED;
        detection.backgroundColor = DetectionBackgroundColor.TO_BE_CONFIRMED;
        detection.textColor = DetectionTextColor.TO_BE_CONFIRMED;
      }
      else {
        detection.border = DetectionBorder.CONFIRMED;
        detection.backgroundColor = DetectionBackgroundColor.CONFIRMED;
        detection.textColor = DetectionTextColor.CONFIRMED;
      }
    }
    return detection
  }


  static setColorsForDetections(detections: Array<Detection>): Array<Detection> {
    detections.forEach((detection: Detection) => {
      ColorTools.setColorsForDetection(detection);
    });
    return detections
  }


  static setSiteEmergencyColors(site: any): void {
    site.border = EmergencyTools.border(site.emergencyLevel);
    site.backgroundColor = EmergencyTools.backgroundColor(site.emergencyLevel);
    site.textColor = EmergencyTools.textColor(site.emergencyLevel);
  }

  static setSiteAvailableColors(site: any): void {
    site.border = SiteBorderColor.AVAILABLE; // '0.5px solid ' + Color.PRIMARY;
    site.backgroundColor = SiteBackgroundColor.AVAILABLE; // '#efefef';
    site.textColor = SiteTextColor.AVAILABLE; // Color.PRIMARY;
  }

  static setSiteNotAvailableColors(site: any): void {
    site.border = SiteBorderColor.NOT_AVAILABLE; // '0.5px solid #cfcfcf';
    site.backgroundColor = SiteBackgroundColor.NOT_AVAILABLE; // '#efefef';
    site.textColor = SiteTextColor.NOT_AVAILABLE; // '#afafaf';
  }

  static setColorsForSite(site: any): any {
    if (site.status == SiteStatus.UPDATING || site.status == SiteStatus.RUNNING) {
      if (site.emergencyLevel == 0) {
        ColorTools.setSiteAvailableColors(site);
      }
      else {
        ColorTools.setSiteEmergencyColors(site);
      }
    }
    else {
      ColorTools.setSiteNotAvailableColors(site);
    }
    return site;
  }

  static setColorsForSites(sites: Array<Site>): Array<Site> {
    sites.forEach((site: Site) => {
      ColorTools.setColorsForSite(site);
    });
    return sites
  }

}
