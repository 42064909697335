import {
  autoserialize, autoserializeAs,
  deserialize, deserializeAs,
} from 'cerialize';
import { TrackerType } from '../enums/tracker';

// ===============================================
// TrackerImage
// ===============================================

export class TrackerImageBatchDataImage {
  @autoserializeAs("image") name!: string;
  @autoserializeAs("pixel") position!: Array<number>;
  @autoserializeAs("center_distance") distance!: number;
}

export class TrackerImageBatchData {
  @autoserialize batch!: string;
  @autoserializeAs('date_acquired') dateAcquired!: number;
  @autoserializeAs(TrackerImageBatchDataImage) images!: Array<TrackerImageBatchDataImage>;
}

export class TrackerImage {
  kind: string = 'marker';
  @autoserializeAs(TrackerImageBatchData) data!: Array<TrackerImageBatchData>;

  @autoserialize id!: string;
  @autoserialize name!: string;
  @autoserialize author!: string;
  @autoserialize point!: Array<number>;
  @autoserializeAs('tracker_type') trackerType!: TrackerType;
  @deserialize center!: Array<number>;
  @deserializeAs('camera_direction') cameraDirection!: Array<number>;
  @deserialize length!: number;
  @deserializeAs('is_shared') isShared!: boolean;
  show: boolean = true;
  checked: boolean = true;
}


// ===============================================
// TrackerElevation
// ===============================================

export class TrackerElevationBatchData {
  @autoserialize batch!: string;
  @autoserialize altitude!: number;
  @deserialize position!: Array<number>;
  @autoserializeAs('date_acquired') dateAcquired!: string;
}

export class TrackerElevation {
  kind: string = 'elevation';
  @autoserializeAs(TrackerElevationBatchData) data!: Array<TrackerElevationBatchData>;

  @autoserialize id!: string;
  @autoserialize name!: string;
  @autoserialize author!: string;
  @autoserialize point!: Array<number>;
  @autoserializeAs('tracker_type') trackerType!: TrackerType;
  @deserialize center!: Array<number>;
  @deserializeAs('camera_direction') cameraDirection!: Array<number>;
  @deserialize length!: number;
  @deserializeAs('is_shared') isShared!: boolean;
  show: boolean = true;
  checked: boolean = true;
}




export type Tracker = TrackerImage | TrackerElevation;
