import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { SearchComponent } from '../search.component';
import {
  selectConfigSearchWorldText
} from '../../../../features/config/config.selectors';
import {
  setConfigSearchWorldText
} from '../../../../features/config/config.actions';
import {
  selectAllMeasures
} from '../../../../features/measure/measure.selectors';
import { selectAllCuts } from '../../../../features/cut/cut.selectors';
import {
  selectAllVolumes
} from '../../../../features/volume/volume.selectors';
import {
  selectAllDetections
} from '../../../../features/detection/detection.selectors';
import { SearchPipe } from '../../../pipes/search.pipe';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { selectMeasure } from '../../../../features/measure/measure.actions';
import { selectTrackerImage, selectTrackerElevation } from '../../../../features/tracker/tracker.actions';
import { selectCut } from '../../../../features/cut/cut.actions';
import {
  selectDetection
} from '../../../../features/detection/detection.actions';
import { selectVolume } from '../../../../features/volume/volume.actions';
import { TranslocoService } from '@jsverse/transloco';
import {
  selectAllTrackerElevations,
  selectAllTrackerImages,
} from '../../../../features/tracker/tracker.selectors';

@Component({
  selector: 'world-search',
  templateUrl: './world-search.component.html',
  styleUrls: ['../search.component.scss', './world-search.component.scss']
})
export class WorldSearchComponent extends SearchComponent {

  searchResults: Array<any> = [];

  constructor(store: Store, private searchModule: SearchPipe, private translocoService: TranslocoService) {
    super(store);
    this.placeholder = this.translocoService.translate('placeholder_search_item');
    this.initHandleWorldSearch();
  }

  private initHandleWorldSearch(): void {
    combineLatest([
      this.store.select(selectConfigSearchWorldText),
      this.store.select(selectAllDetections),
      this.store.select(selectAllVolumes),
      this.store.select(selectAllMeasures),
      this.store.select(selectAllTrackerImages),
      this.store.select(selectAllCuts),
      this.store.select(selectAllTrackerElevations),
    ]).pipe(takeUntilDestroyed())
      .subscribe(results => {
        this.searchText = (results[0]) ? results[0] : '';
        const detections: Array<any> = (results[1]) ? this.searchModule.transform(results[1], this.searchText, false, 'name') : [];
        const volumes: Array<any> = (results[2]) ? this.searchModule.transform(results[2], this.searchText, false, 'name') : [];
        const measures: Array<any> = (results[3]) ? this.searchModule.transform(results[3], this.searchText, false, 'name') : [];
        const markers: Array<any> = (results[4]) ? this.searchModule.transform(results[4], this.searchText, false, 'name') : [];
        const cuts: Array<any> = (results[5]) ? this.searchModule.transform(results[5], this.searchText, false, 'name') : [];
        const elevations: Array<any> = (results[6]) ? this.searchModule.transform(results[6], this.searchText, false, 'name') : [];
        this.searchResults = [ ...detections, ...volumes, ...measures, ...markers, ...cuts, ...elevations];
      });
  }

  override onTextChange(text: string): void {
    this.store.dispatch(setConfigSearchWorldText({text}));
  }

  onSelectItem(item: any) {
    switch (item.option.value.kind) {
      case "measure": {
        this.store.dispatch(selectMeasure({id: item.option.value.id}));
        break
      }
      case "marker": {
        this.store.dispatch(selectTrackerImage({id: item.option.value.id}));
        break
      }
      case "cut": {
        this.store.dispatch(selectCut({id: item.option.value.id}));
        break
      }
      case "detection": {
        this.store.dispatch(selectDetection({id: item.option.value.id}));
        break
      }
      case "volume": {
        this.store.dispatch(selectVolume({id: item.option.value.id}));
        break
      }
      case "elevation": {
        this.store.dispatch(selectTrackerElevation({id: item.option.value.id}));
        break
      }
    }
    this.store.dispatch(setConfigSearchWorldText({text: undefined}));
  }
}
