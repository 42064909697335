<div class="container">
  <pan-zoom #panzoom [config]="panZoomConfig">
    <canvas #canvas></canvas>
  </pan-zoom>

  <div *ngIf="!imageIsLoaded" class="spinner">
    <mat-spinner strokeWidth="5"></mat-spinner>
  </div>

  <div *ngIf="noImage"
       class="no-photo">
    <span class="material-symbols-outlined">no_photography</span>
  </div>
</div>


