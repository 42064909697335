<mat-card *ngIf="detection"
          [style.border]=detection.border
          [ngClass]="{'selected': detection.id == (selectedItem$ | async)?.id}"
          (click)="onClickEdit(detection)">

  <div class="card-status"
       [style.background-color] = detection.backgroundColor
       [style.color]=detection.textColor>
    <span *ngIf="detection.status == detectionStatusEnum.TO_BE_CONFIRMED">{{'to_confirm' | transloco}}</span>
    <span *ngIf="detection.status == detectionStatusEnum.CONFIRMED && !detection.emergency">{{'confirmed' | transloco}}</span>
    <span *ngIf="detection.status == detectionStatusEnum.CONFIRMED && detection.emergency">{{detection.emergency.label}}</span>
  </div>

  <mat-card-header style="padding-bottom: 16px;">
    <mat-card-title>{{detection.name}}</mat-card-title>
    <mat-card-subtitle>{{detection.createMode | formatDetectionMode}} ({{confidence.toFixed(0)}} %)</mat-card-subtitle>
  </mat-card-header>

  <div class="shared-item-indicators">
    <mat-icon *ngIf="!detection.show">visibility_off</mat-icon>
    <mat-icon>share</mat-icon>
  </div>
</mat-card>



