import { createAction, props } from '@ngrx/store';

export const setConfigSideMode = createAction('[Config] Set Side Mode', props<{ sideMode: any}>());
export const setConfigDisplaySitesFilters = createAction('[Config] Set Display Sites Filters', props<{ display: boolean}>());
export const setConfigSitesFilters = createAction('[Config] Set Sites Filters', props<{ sitesFilters: any}>());
export const setConfigColorRampPositiveColors = createAction('[Config] Set Color Ramp Positive Colors', props<{ colors: Array<string> }>());
export const setConfigColorRampNegativeColors = createAction('[Config] Set Color Ramp Negative Colors', props<{ colors: Array<string> }>());
export const setConfigColorRampMin = createAction('[Config] Set Color Ramp Min ', props<{ value: number }>());
export const setConfigColorRampMax = createAction('[Config] Set Color Ramp Max ', props<{ value: number }>());
export const setConfigSiteDetailSelectedSection = createAction('[Config] Set Site Detail Selected Seciton', props<{ value: number | undefined }>());
export const setConfigSearchWorldText = createAction('[Config] Search World Text', props<{ text: string | undefined}>());
export const setConfigTilesetStyleColorConditions = createAction('[Config] Set Tileset Style Color Conditions', props<{ conditions: Array<any> | undefined }>());
export const setConfigTilesetColorRampMin = createAction('[Config] Set Tileset Color Ramp Min', props<{ value: number }>());
export const setConfigTilesetColorRampMax = createAction('[Config] Set Tileset Color Ramp Max', props<{ value: number }>());
export const setConfigViewRepresentation = createAction('[Config] Set View Representation', props<{ value: number }>());
export const setConfigSearchBarDisplayed = createAction('[Config] Set Search Bar Displayed', props<{ displayed: boolean }>());
export const setConfigTilesetIsLoading = createAction('[Config] Set Tileset Is Loading', props<{ isLoading: boolean }>());
export const setConfigSplittedTilesetIsLoading = createAction('[Config] Set Splitted Tileset Is Loading', props<{ isLoading: boolean }>());
export const setConfigPointCloudPointSize = createAction('[Config] Set Splitted Tileset Is Loading', props<{ pointSize: number }>());
export const setConfigDetectionFilters = createAction('[Config] Set Detection Filters', props<{ detectionFilters: any}>());
export const setConfigIsDownloadingAlteredPicture = createAction('[Config] Set is Downloading Altered Picture', props<{ isDownloading: boolean}>());
