import {
  Component, Input,
} from '@angular/core';
import { Store } from '@ngrx/store';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Site } from '../../../models/site';
import { InspectionModule } from '../../../enums/inspection-module';
import { DrawingMode } from '../../../enums/drawing-mode';
import { selectDrawingMode } from '../../../features/cesium/cesium.selectors';
import { setDrawingMode } from '../../../features/cesium/cesium.actions';
import { Actions, ofType } from '@ngrx/effects';
import {
  addMeasureArea,
  addMeasureDistance, addMeasureFailure, addMeasureSuccess
} from '../../../features/measure/measure.actions';
import {
  addCut,
  addCutFailure, addCuts, addCutsFailure, addCutsSuccess,
  addCutSuccess
} from '../../../features/cut/cut.actions';
import {
  addDetection, addDetectionFailure,
  addDetectionSuccess
} from '../../../features/detection/detection.actions';
import {
  setConfigSearchBarDisplayed
} from '../../../features/config/config.actions';
import {
  selectConfigSearchBarDisplayed, selectConfigViewRepresentation
} from '../../../features/config/config.selectors';
import { ViewRepresentation } from '../../../enums/representation';
import { Observable } from 'rxjs';
import {
  addTracker,
  addTrackerElevations, addTrackerElevationsFailure,
  addTrackerElevationsSuccess,
  addTrackerFailure,
  addTrackerImages,
  addTrackerImagesFailure,
  addTrackerImagesSuccess,
  addTrackerSuccess
} from '../../../features/tracker/tracker.actions';


@Component({
  selector: 'draw-entities-toolbar',
  templateUrl: './draw-entities-toolbar.component.html',
  styleUrls: ['./draw-entities-toolbar.component.scss']
})
export class DrawEntitiesToolbarComponent {

  @Input({ required: true }) site: Site | undefined;
  inspectionModule = InspectionModule;
  private drawingMode: DrawingMode | undefined;
  searchBarDisplayed: boolean = false;
  isComputing: boolean = false;
  viewRepresentation = ViewRepresentation;

  constructor(private store: Store, private actions: Actions) {
    this.initHandleDrawingMode();
    this.initHandleSearchBar();
    this.initHandleActions();
  }

  private initHandleDrawingMode() {
    this.store.select(selectDrawingMode)
      .pipe(takeUntilDestroyed())
      .subscribe((mode: DrawingMode | undefined) => {
        this.drawingMode = mode;
      });
  }

  private initHandleSearchBar() {
    this.store.select(selectConfigSearchBarDisplayed)
      .pipe(takeUntilDestroyed())
      .subscribe((displayed: boolean) => {
        this.searchBarDisplayed = displayed;
      });
  }

  private initHandleActions() {
    this.actions.pipe(
      ofType(
        addDetection,
        addMeasureDistance,
        addMeasureArea,
        addCut,
        addCuts,
        addTracker,
        addTrackerImages,
        addTrackerElevations),
      takeUntilDestroyed())
      .subscribe(result => {
        this.isComputing = true;
      });

    this.actions.pipe(
      ofType(
        addDetectionSuccess, addDetectionFailure,
        addMeasureSuccess, addMeasureFailure,
        addCutSuccess, addCutFailure,
        addCutsSuccess, addCutsFailure,
        addTrackerSuccess, addTrackerFailure,
        addTrackerImagesSuccess, addTrackerImagesFailure,
        addTrackerElevationsSuccess, addTrackerElevationsFailure),
      takeUntilDestroyed())
      .subscribe(result => {
        this.isComputing = false;
      });
  }

  drawLine(event: any): void {
    const mode = (this.drawingMode == DrawingMode.MEASURE_LINE) ? undefined : DrawingMode.MEASURE_LINE;
    this.store.dispatch(setDrawingMode({drawingMode: mode}));
  }

  get isDrawingLine(): boolean {
    return this.drawingMode == DrawingMode.MEASURE_LINE;
  }

  drawPolygon(event: any): void {
    const mode = (this.drawingMode == DrawingMode.MEASURE_POLYGON) ? undefined : DrawingMode.MEASURE_POLYGON;
    this.store.dispatch(setDrawingMode({drawingMode: mode}));
  }

  get isDrawingPolygon(): boolean {
    return this.drawingMode == DrawingMode.MEASURE_POLYGON;
  }

  drawCut(event: any): void {
    const mode = (this.drawingMode == DrawingMode.CUT_LINE) ? undefined : DrawingMode.CUT_LINE;
    this.store.dispatch(setDrawingMode({drawingMode: mode}));
  }

  get isDrawingCut(): boolean {
    return this.drawingMode == DrawingMode.CUT_LINE;
  }

  drawMarker(event: any): void {
    const mode = (this.drawingMode == DrawingMode.MARKER_POINT) ? undefined : DrawingMode.MARKER_POINT;
    this.store.dispatch(setDrawingMode({drawingMode: mode}));
  }

  get isDrawingMarker(): boolean {
    return this.drawingMode == DrawingMode.MARKER_POINT;
  }

  drawNewDetection(event: any): void {
    const mode = (this.drawingMode == DrawingMode.NEW_DETECTION) ? undefined : DrawingMode.NEW_DETECTION;
    this.store.dispatch(setDrawingMode({drawingMode: mode}));
  }

  get isDrawingNewDetection(): boolean {
    return this.drawingMode == DrawingMode.NEW_DETECTION;
  }

  drawImagesAtPoint(event: any): void {
    const mode = (this.drawingMode == DrawingMode.IMAGES_AT_POINT) ? undefined : DrawingMode.IMAGES_AT_POINT;
    this.store.dispatch(setDrawingMode({drawingMode: mode}));
  }

  get isDrawingImageAtPoint(): boolean {
    return this.drawingMode == DrawingMode.IMAGES_AT_POINT;
  }

  drawElevation(event: any): void {
    const mode = (this.drawingMode == DrawingMode.ELEVATION) ? undefined : DrawingMode.ELEVATION;
    this.store.dispatch(setDrawingMode({drawingMode: mode}));
  }

  get isDrawingElevation(): boolean {
    return this.drawingMode == DrawingMode.ELEVATION;
  }

  toggleSearch(event: any): void {
    this.store.dispatch(setConfigSearchBarDisplayed({displayed: !this.searchBarDisplayed}));
  }

  get currentViewRepresentation$(): Observable<ViewRepresentation> {
    return this.store.select(selectConfigViewRepresentation);
  }

}
