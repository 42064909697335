import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { DetectionDensity, Site, VolumeDensity } from '../../models/site';
import {
  selectAllSites,
  selectCurrentSite,
  selectSearchSiteText,
  selectSiteDensityHighlight
} from '../../features/site/site.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CesiumDirective } from '../../shared/directives/cesium';
import { combineLatest, first, Observable } from 'rxjs';
import { CameraMode } from '../../enums/camera';
import {
  selectCameraConfiguration,
  selectCameraMode,
  selectCameraRotationRate,
  selectDrawingMode,
  selectIsMiniMapDragging,
  selectMapHandleKeyboard,
  selectMiniMapCameraConfiguration,
} from '../../features/cesium/cesium.selectors';
import {
  setCameraConfiguration,
  setCameraMode,
  setDrawingMode,
  setMiniMapCameraConfiguration,
} from '../../features/cesium/cesium.actions';
import { CesiumTools } from '../../tools/cesium.tools';
import {
  selectAllDetections,
  selectCurrentDetection, selectDetectionTotal
} from '../../features/detection/detection.selectors';
import { Detection } from '../../models/detection';
import { CameraConfiguration } from '../../features/cesium/cesium.reducer';
import {
  addMeasureArea,
  addMeasureDistance,
  addMeasureFailure,
  addMeasureSuccess,
  clearAllMeasures, deleteMeasuresSuccess, deleteMeasureSuccess,
  loadMeasures, loadMeasuresSuccess,
  selectMeasure, setMeasureShow, setMeasuresShow, updateMeasureSuccess
} from '../../features/measure/measure.actions';
import {
  addDetection,
  addDetectionEmergencyFailure,
  addDetectionEmergencySuccess,
  addDetectionFailure,
  addDetectionSuccess,
  clearAllDetections,
  clearDetectionEmergencyTemplate,
  clearDetectionSummary,
  clearDetectionTemplate,
  deleteDetectionFailure,
  deleteDetectionSuccess, loadDetectionEmergencyTemplate,
  loadDetections, loadDetectionsSuccess,
  loadDetectionSummary,
  loadDetectionTemplate,
  selectDetection,
  updateDetectionFailure,
  updateDetectionSuccess
} from '../../features/detection/detection.actions';
import {
  addVolumeEmergencyFailure,
  addVolumeEmergencySuccess,
  clearAllVolumes,
  clearVolumeEmergencyTemplate,
  clearVolumeSummary, loadVolumeEmergencyTemplate, loadVolumesSuccess,
  selectVolume,
  setVolumeColorMode
} from '../../features/volume/volume.actions';
import {
  addCut,
  addCutFailure, addCutsSuccess,
  addCutSuccess,
  clearAllCuts, deleteCutsSuccess, deleteCutSuccess,
  loadCuts, loadCutsSuccess,
  selectCut, setCutShow, setCutsShow, updateCutSuccess
} from '../../features/cut/cut.actions';
import { Volume } from '../../models/volume';
import {
  selectAllVolumes,
  selectVolumeTotal,
} from '../../features/volume/volume.selectors';
import { OverlayService } from '../../services/overlay.service';
import { CesiumEntityService } from '../../services/cesium-entity.service';
import { DrawingMode } from '../../enums/drawing-mode';
import { DataSourceType } from '../../enums/datasource-type';
import {
  selectAllMeasures,
} from '../../features/measure/measure.selectors';
import { Measure } from '../../models/measure';
import { Cut } from '../../models/cut';
import { InspectionModule } from '../../enums/inspection-module';
import { Actions, ofType } from '@ngrx/effects';
import {
  selectConfigDetectionFilters,
  selectConfigPointCloudPointSize,
  selectConfigSitesFilters,
  selectConfigTilesetStyleColorConditions,
  selectConfigViewRepresentation
} from '../../features/config/config.selectors';
import { FiltersPipe } from '../../shared/pipes/filters.pipe';
import { SearchPipe } from '../../shared/pipes/search.pipe';
import {
  CesiumPrimitiveService
} from '../../services/cesium-primitive.service';
import { InspectionModulePipe } from '../../shared/pipes/module.pipe';
import {
  setConfigColorRampMax,
  setConfigColorRampMin,
  setConfigColorRampNegativeColors,
  setConfigColorRampPositiveColors, setConfigPointCloudPointSize,
  setConfigSearchWorldText, setConfigSiteDetailSelectedSection,
  setConfigSplittedTilesetIsLoading,
  setConfigTilesetIsLoading,
  setConfigTilesetStyleColorConditions,
  setConfigViewRepresentation,
} from '../../features/config/config.actions';
import { retrieveSite } from '../../features/site/site.actions';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  CreateDetectionDialog
} from '../../shared/dialogs/create-detection-dialog/create-detection.dialog';
import { VolumeColorMode } from '../../enums/volume';
import { TranslocoService } from '@jsverse/transloco';
import { TilesetConfig } from '../../models/tileset-config';
import {
  clearAllBatchs,
  loadBatchs,
  selectReferenceBatch
} from '../../features/batch/batch.actions';
import {
  addTilesetConfig,
  addTilesetConfigFailure,
  addTilesetConfigSuccess,
  clearAllTilesetConfigs,
  loadTilesetConfigs,
  loadTilesetConfigsSuccess,
  setTilesetConfigId
} from '../../features/tileset-config/tileset-config.actions';
import {
  selectAllTilesetConfigs,
  selectCurrentTilesetConfig,
  selectCurrentTilesetConfigId,
} from '../../features/tileset-config/tileset-config.selectors';
import { TrackerElevation, TrackerImage } from '../../models/tracker';
import { cloneDeep } from 'lodash';
import { ViewRepresentation } from '../../enums/representation';
import {
  selectCurrentReferenceBatch,
} from '../../features/batch/batch.selectors';
import {
  BatchDiffRepresentationSelectorDialog
} from '../../shared/dialogs/batch-diff-representation-selector-dialog/batch-diff-representation-selector.dialog';
import { Batch } from '../../models/batch';
import { environment } from '../../../environments/environment';
import {
  SplitViewSliderDirective
} from '../../shared/directives/split-view-slider';
import { selectUser } from '../../features/user/user.selectors';
import { User } from '../../models/user';
import { CesiumService } from '../../services/cesium.service';
import {
  reportRequireAllData,
  reportWithData
} from '../../features/report/report.actions';
import { CollectionType } from '../../enums/collection-type';
import {
  addTracker,
  addTrackerFailure,
  addTrackerSuccess,
  clearAllTrackers,
  loadTrackersImage,
  loadTrackersElevation,
  selectTrackerElevation,
  selectTrackerImage,
  loadTrackersElevationSuccess,
  loadTrackersImageSuccess,
  deleteTrackerSuccess,
  setTrackerShow,
  updateTrackerSuccess,
  addTrackerElevationsSuccess,
  addTrackerImagesSuccess,
  setTrackersShow,
  deleteTrackersSuccess
} from '../../features/tracker/tracker.actions';
import { TrackerType } from '../../enums/tracker';
import {
  selectAllCuts,
} from '../../features/cut/cut.selectors';
import {
  selectAllTrackerElevations,
  selectAllTrackerImages
} from '../../features/tracker/tracker.selectors';
import { FilterDetectionsPipe } from '../../shared/pipes/detection.pipe';


@Component({
  selector: 'world',
  templateUrl: './world.component.html',
  styleUrls: ['./world.component.scss'],
})
export class WorldComponent implements AfterViewInit {

  @ViewChild(CesiumDirective) cesium: any;
  @ViewChild(SplitViewSliderDirective) slider: any;
  mapHandleKeyboard: boolean = true;
  inspectionModule = InspectionModule;
  viewRepresentation = ViewRepresentation;
  selectedSite: Site | undefined;
  selectedViewRepresentation: number | undefined;
  referenceBatch: Batch | null | undefined;
  private user: User | null |undefined;
  private cameraMode: CameraMode = CameraMode.MODE_3D;
  private isMiniMapDragging = false;
  private drawingMode: DrawingMode | undefined;
  private floatingPoint: any | undefined;
  private activeShapePoints: Array<any> = [];
  private activeShape: any | undefined;

  constructor(private store: Store,
              private actions: Actions,
              private toastr: ToastrService,
              private overlayService: OverlayService,
              private cesiumService: CesiumService,
              private entityService: CesiumEntityService,
              private primitiveService: CesiumPrimitiveService,
              private inspectionModulePipe: InspectionModulePipe,
              private filterPipe: FiltersPipe,
              private searchPipe: SearchPipe,
              private dialog: MatDialog,
              private translocoService: TranslocoService,
              private modulePipe: InspectionModulePipe,
              private detectionFilter: FilterDetectionsPipe) {
    this.initHandleDrawing();
    this.initHandleKeyboard();
    this.initHandleSite();
    this.initHandleMiniMap();
    this.initHandleCamera();
    this.initHandleDetections();
    this.initHandleVolumes();
    this.initHandleMeasures();
    this.initHandleCuts();
    this.initHandleTileset();
    this.initHandleTracker();
    this.initViewRepresentation();
    this.initHandleBatch();
    this.initHandleUser();
    this.initHandleReport();
  }

  private initHandleReport(): void {
    this.actions.pipe(ofType(reportWithData), takeUntilDestroyed())
      .subscribe(result => {
        this.clearDraw();
      });
    this.actions.pipe(ofType(reportRequireAllData), takeUntilDestroyed())
      .subscribe(result => {
        const data = this.cesiumService.entities(this.cesium.viewer, 1);
        this.store.dispatch(reportWithData({data}));
      });
  }


  private initHandleUser(): void {
    this.store.select(selectUser)
      .pipe(takeUntilDestroyed())
      .subscribe((user: User | undefined) => {
        if (user) {
          this.user = user;
        }
      });
  }

  private initHandleBatch():void {
    this.store.select(selectCurrentReferenceBatch)
      .pipe(takeUntilDestroyed())
      .subscribe((referenceBatch: Batch | null | undefined) => {
        this.referenceBatch = referenceBatch;
        this.store.dispatch(clearDetectionSummary());
        this.store.dispatch(clearVolumeSummary());
        this.store.dispatch(clearAllDetections());
        this.store.dispatch(clearAllVolumes());
    });
  }

  private initViewRepresentation(): void {
    combineLatest([
      this.store.select(selectCurrentReferenceBatch),
      this.store.select(selectConfigViewRepresentation)
    ]).pipe(takeUntilDestroyed())
      .subscribe((results) => {
        const referenceBatch: Batch | null | undefined = results[0];
        this.selectedViewRepresentation = (results[1]) ? results[1] : ViewRepresentation.TEXTURED;
        this.store.select(selectAllTilesetConfigs)
          .pipe(first())
          .subscribe((tilesetConfigs: Array<TilesetConfig>) => {
            if (tilesetConfigs.length) {
              const tcs = cloneDeep(tilesetConfigs);
              let tc;
              if (this.selectedViewRepresentation == ViewRepresentation.POINT_CLOUD
                || this.selectedViewRepresentation == ViewRepresentation.SPLITTED_POINT_CLOUD) {
                tc = tcs.filter(tc => tc.id == "1").pop();
              }
              else if (this.selectedViewRepresentation == ViewRepresentation.DIFFERENTIAL) {
                tc = tcs.filter(tc => tc.canApplyStyle && referenceBatch?.id == tc.name).pop();
              }
              else {
                tc = tcs.filter(tc => tc.id == "0").pop();
              }

              this.store.dispatch(setTilesetConfigId({tilesetConfigId: (tc) ? tc.id : undefined}));
              if (!tc && referenceBatch && this.selectedViewRepresentation == ViewRepresentation.DIFFERENTIAL) {
                this.displayNewDifferentialTilesetDialog(referenceBatch);
              }

              const secondaryTilesetCollection = CesiumTools.getOrCreatePrimitiveCollection(this.cesium.viewer, CollectionType.SECONDARY_TILESET);
              secondaryTilesetCollection.removeAll();
              if (referenceBatch &&
                (this.selectedViewRepresentation == ViewRepresentation.SPLITTED_TEXTURED || this.selectedViewRepresentation == ViewRepresentation.SPLITTED_POINT_CLOUD)) {
                this.store.dispatch(setConfigSplittedTilesetIsLoading({isLoading: true}));
                const dirname = (this.selectedViewRepresentation == ViewRepresentation.SPLITTED_TEXTURED) ? 'main' : 'point_cloud';
                const referenceBatchTilesetUrl = environment.tilesUrl + '/' + referenceBatch.id + '/'+ dirname +'/tileset.json';
                Cesium.Cesium3DTileset.fromUrl(referenceBatchTilesetUrl, {
                  skipLevelOfDetail: true,
                  baseScreenSpaceError: 1024,
                  skipScreenSpaceErrorFactor: 16,
                  skipLevels: 1,
                  immediatelyLoadDesiredLevelOfDetail: false,
                  loadSiblings: false,
                  cullWithChildrenBounds: true
                }).then((tileset:any) => {
                  tileset.splitDirection = Cesium.SplitDirection.LEFT;
                  secondaryTilesetCollection.add(tileset);
                  tileset.allTilesLoaded.addEventListener(() => {
                    this.store.dispatch(setConfigSplittedTilesetIsLoading({isLoading: false}));
                    if (this.selectedViewRepresentation == ViewRepresentation.SPLITTED_POINT_CLOUD) {
                      this.store.select(selectConfigPointCloudPointSize)
                        .pipe(first())
                        .subscribe((pointSize: number) => {
                          // Hack to trigger style when tileset is long to be load.
                          this.store.dispatch(setConfigPointCloudPointSize({pointSize: 1}));
                          this.store.dispatch(setConfigPointCloudPointSize({pointSize}));
                        });
                    }
                  });
                }).catch((err:any) => {
                  this.store.dispatch(setConfigSplittedTilesetIsLoading({isLoading: false}));
                });
              }else {
                this.cesium.viewer.scene.splitPosition = 0.0;
              }
            }

            if (this.cesium?.viewer) {
              switch (this.selectedViewRepresentation) {
                case ViewRepresentation.DETECTIONS:
                  const detectionCollection = CesiumTools.getOrCreatePrimitiveCollection(this.cesium.viewer, CollectionType.DETECTION);
                  detectionCollection.show = true;
                  break
                case ViewRepresentation.VOLUMES:
                  const volumeDataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.VOLUME);
                  volumeDataSource.entities.show = true;
                  break
                default:
                  const collection = CesiumTools.getOrCreatePrimitiveCollection(this.cesium.viewer, CollectionType.DETECTION);
                  collection.show = false;
                  const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.VOLUME);
                  dataSource.entities.show = false;
              }
            }
          });
      });
  }

  private initHandleTileset(): void {
    this.actions.pipe(ofType(addTilesetConfigSuccess), takeUntilDestroyed())
      .subscribe((result: any) => {
        if (result) {
          this.store.select(selectConfigViewRepresentation)
            .pipe(first())
            .subscribe((viewRepresentation: ViewRepresentation) => {
              if (viewRepresentation == ViewRepresentation.DIFFERENTIAL) {
                const tilesetConfig = result['tilesetConfig'];
                this.store.dispatch(setTilesetConfigId({tilesetConfigId: tilesetConfig.id}));
              }
          });
        }
      });
    this.actions.pipe(ofType(addTilesetConfigFailure), takeUntilDestroyed())
      .subscribe((result: any) => {
        if (result) {
          this.toastr.error(this.translocoService.translate('add_tileset_config_failure'));
          this.store.select(selectConfigViewRepresentation)
            .pipe(first())
            .subscribe((viewRepresentation: ViewRepresentation) => {
              if (viewRepresentation == ViewRepresentation.DIFFERENTIAL) {
                this.store.dispatch(setConfigViewRepresentation({value: ViewRepresentation.TEXTURED}));
              }
            });
        }
      });

    this.actions.pipe(ofType(loadTilesetConfigsSuccess), takeUntilDestroyed())
      .subscribe((result: any) => {
        this.store.select(selectConfigViewRepresentation)
          .pipe(first())
          .subscribe(viewRepresentation => {
            this.store.dispatch(setConfigViewRepresentation({value: -1}));
            this.store.dispatch(setConfigViewRepresentation({value: viewRepresentation}));
        });
      });


    combineLatest([
      this.store.select(selectConfigViewRepresentation),
      this.store.select(selectConfigTilesetStyleColorConditions),
      this.store.select(selectConfigPointCloudPointSize)
    ]).pipe(takeUntilDestroyed())
      .subscribe(results => {
        const viewRepresentation = results[0];
        const conditions = results[1];
        const pointSize = results[2];
        if(!this.cesium) return;
        const tilesetCollection = CesiumTools.getOrCreatePrimitiveCollection(this.cesium.viewer, CollectionType.PRIMARY_TILESET);
        if (tilesetCollection.length) {
          this.store.select(selectCurrentTilesetConfig)
            .pipe(first())
            .subscribe((tilesetConfig: TilesetConfig | null | undefined) => {
              if (tilesetConfig?.canApplyStyle) {
                let style = {
                  pointSize
                }
                if (conditions) {
                  const alteredConditions = cloneDeep(conditions);
                  style = Object.assign({color: {
                      conditions: alteredConditions?.map((c: any) => {
                        const params = c[1];
                        c[1] = "hsl("+ String(params[0]) + ", "+ String(params[1]) +", "+ String(params[2]) + ")";
                        return c;
                      }),
                    }}, style);
                }

                const tileset = tilesetCollection.get(0);
                tileset.style = new Cesium.Cesium3DTileStyle(style);
                if (viewRepresentation == ViewRepresentation.SPLITTED_POINT_CLOUD) {
                  const secondaryTilesetCollection = CesiumTools.getOrCreatePrimitiveCollection(this.cesium.viewer, CollectionType.SECONDARY_TILESET);
                  if (secondaryTilesetCollection.length) {
                    const secondaryTileset = secondaryTilesetCollection.get(0);
                    secondaryTileset.style = new Cesium.Cesium3DTileStyle(style);
                  }
                }
              }
          })
        }
      });

    this.store.select(selectCurrentTilesetConfig)
      .pipe(takeUntilDestroyed())
      .subscribe((tilesetConfig: TilesetConfig | null | undefined) => {
        if(!this.cesium) return;
        const tilesetCollection = CesiumTools.getOrCreatePrimitiveCollection(this.cesium.viewer, CollectionType.PRIMARY_TILESET);
        tilesetCollection.removeAll();
        if (tilesetConfig?.url){
          this.store.dispatch(setConfigTilesetIsLoading({isLoading: true}));
          Cesium.Cesium3DTileset.fromUrl(tilesetConfig?.url, {
            skipLevelOfDetail: true,
            baseScreenSpaceError: 1024,
            skipScreenSpaceErrorFactor: 16,
            skipLevels: 1,
            immediatelyLoadDesiredLevelOfDetail: false,
            loadSiblings: false,
            cullWithChildrenBounds: true
          }).then((tileset:any) => {
            tileset.splitDirection = Cesium.SplitDirection.RIGHT;
            tilesetCollection.add(tileset);
            tileset.allTilesLoaded.addEventListener(() => {
              if (tilesetConfig.canApplyStyle) {
                combineLatest([
                  this.store.select(selectConfigViewRepresentation),
                  this.store.select(selectConfigTilesetStyleColorConditions),
                  this.store.select(selectConfigPointCloudPointSize),
                ]).pipe(first())
                  .subscribe(results => {
                    const viewRepresentation = results[0];
                    const conditions = results[1];
                    const pointSize = results[2];
                    // Hack to trigger style when tileset is long to be load.
                    this.store.dispatch(setConfigPointCloudPointSize({pointSize: 1}));
                    this.store.dispatch(setConfigTilesetStyleColorConditions({conditions: undefined}));
                    if (viewRepresentation == ViewRepresentation.DIFFERENTIAL) {
                      this.store.dispatch(setConfigTilesetStyleColorConditions({conditions}));
                      this.store.dispatch(setConfigPointCloudPointSize({pointSize}));
                    }
                    else if (viewRepresentation == ViewRepresentation.POINT_CLOUD
                      || viewRepresentation == ViewRepresentation.SPLITTED_POINT_CLOUD){
                      this.store.dispatch(setConfigPointCloudPointSize({pointSize}));
                    }
                });
              }else {
                this.store.dispatch(setConfigTilesetStyleColorConditions({conditions: undefined}));
              }
              this.store.dispatch(setConfigTilesetIsLoading({isLoading: false}));
            });
          }).catch((err:any) => {
            this.store.dispatch(setConfigTilesetIsLoading({isLoading: false}));
          });
        }
      });
  }

  private initHandleDrawing(): void {
    this.store.select(selectDrawingMode)
      .pipe(takeUntilDestroyed())
      .subscribe((mode: DrawingMode | undefined) => {
        this.drawingMode = mode;
        if (!this.drawingMode) {
          this.floatingPoint = undefined;
        } else {
          const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.DRAW);
          dataSource.entities.removeAll();
          this.activeShape = undefined;
          this.activeShapePoints = [];
        }
      });
  }

  private initHandleKeyboard(): void {
    this.store.select(selectMapHandleKeyboard)
      .pipe(takeUntilDestroyed())
      .subscribe((mapHandleKeyboard: boolean) => {
        this.mapHandleKeyboard = mapHandleKeyboard;
      });
  }

  private initHandleSite(): void {
    this.store.select(selectCurrentSite)
      .pipe(takeUntilDestroyed())
      .subscribe((site: Site | null | undefined) => {
        if (site) {
          if (this.selectedSite && site.id != this.selectedSite.id) {
            this.configureSite(site);
          }
          else if (!this.selectedSite) {
            this.configureSite(site);
          }
        }else if (this.cesium) {
          this.clean();
        }
      });

    this.store.select(selectSiteDensityHighlight)
      .pipe(takeUntilDestroyed())
      .subscribe((density: DetectionDensity | VolumeDensity | undefined) => {
        if (this.cesium && this.cesium.viewer && this.cesium.viewer.scene) {
          const densityCollection = CesiumTools.getOrCreatePrimitiveCollection(this.cesium.viewer, CollectionType.DENSITY);
          densityCollection.removeAll();
          if (density) {
            const tilesetCollection = CesiumTools.getOrCreatePrimitiveCollection(this.cesium.viewer, CollectionType.PRIMARY_TILESET);
            const overwritedColor = (tilesetCollection.length && tilesetCollection.get(0).style) ? '#FFFFFF' : undefined;
            this.primitiveService.createDensityPrimitive(
              densityCollection,
              density,
              overwritedColor
            );
          }
        }
      });

    combineLatest([
      this.store.select(selectAllSites),
      this.store.select(selectSearchSiteText),
      this.store.select(selectConfigSitesFilters)
    ]).pipe(takeUntilDestroyed())
      .subscribe((results) => {
        let sites: Array<Site> = results[0];
        const search:string = (results[1]) ? results[1] : '';
        sites = this.filterPipe.transform(sites);
        sites = this.searchPipe.transform(sites, search, true, 'name');
        if (this.cesium && sites) {
          const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.SITE);
          dataSource.entities.removeAll();
          sites.forEach(site =>  {
            this.entityService.createSiteEntity(dataSource, site);
          });
        }
      });
  }

  private initHandleMiniMap(): void {
    this.store.select(selectIsMiniMapDragging)
      .pipe(takeUntilDestroyed())
      .subscribe((isDragging: boolean) => {
        this.isMiniMapDragging = isDragging;
      });
    this.store.select(selectMiniMapCameraConfiguration)
      .pipe(takeUntilDestroyed())
      .subscribe( (cameraConfiguration: any) => {
          this.updateCameraConfiguration(
            this.cameraMode,
            cameraConfiguration,
            this.isMiniMapDragging
          );
      });
  }

  private initHandleCamera() {
    this.store.select(selectCameraMode)
      .pipe(takeUntilDestroyed())
      .subscribe( (mode: CameraMode) => {
        this.updateCameraMode(mode);
      });
    this.store.select(selectCameraConfiguration)
      .pipe(takeUntilDestroyed())
      .subscribe( (cameraConfiguration: any) => {
        this.updateCameraConfiguration(
          this.cameraMode,
          cameraConfiguration,
          this.isMiniMapDragging
        );
      });
    this.store.select(selectCameraRotationRate)
      .pipe(takeUntilDestroyed())
      .subscribe( (rotationRate: number | undefined) => {
        this.updateCameraLookAt(rotationRate);
      });
  }

  private initHandleMeasures() {
    this.actions.pipe(ofType(loadMeasuresSuccess), takeUntilDestroyed())
      .subscribe(result => {
        this.store.select(selectAllMeasures)
          .pipe(first())
          .subscribe((measures: Array<Measure> | null | undefined) => {
            if (!this.cesium) return;
            const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.MEASURE);
            dataSource.entities.removeAll();
            if (measures) {
              measures.forEach((measure: Measure) => {
                this.entityService.createMeasureEntity(dataSource, measure);
              });
            }
          });
      });

    this.actions.pipe(ofType(addMeasureSuccess), takeUntilDestroyed())
      .subscribe(result => {
        this.clearDraw();
        if (this.cesium) {
          const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.MEASURE);
          this.entityService.createMeasureEntity(dataSource, result.measure);
        }
        this.toastr.success(this.translocoService.translate('add_measure_success'));
        this.store.dispatch(selectMeasure({id: result.measure.id}));
      });

    this.actions.pipe(ofType(updateMeasureSuccess), takeUntilDestroyed())
      .subscribe((result: any) => {
        if (result) {
          if (!this.cesium) return;
          const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.MEASURE);
          const entity = dataSource.entities.getById(result.update.id);
          this.entityService.updateEntity(entity, 'measure', result.update.changes);
        }
      });

    this.actions.pipe(ofType(addMeasureFailure), takeUntilDestroyed())
      .subscribe(result => {
          this.clearDraw();
          this.toastr.warning(this.translocoService.translate('add_measure_failure'));
      });

    this.actions
      .pipe(ofType(deleteMeasureSuccess), takeUntilDestroyed())
      .subscribe(result => {
        const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.MEASURE);
        dataSource.entities.removeById(result.id);
      });
    this.actions.pipe(ofType(deleteMeasuresSuccess), takeUntilDestroyed())
      .subscribe((result: any) => {
        const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.MEASURE);
        result.ids.forEach((id: string) => {
          dataSource.entities.removeById(id);
        });
      });
    this.actions.pipe(ofType(setMeasureShow), takeUntilDestroyed())
      .subscribe((result: any) => {
        const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.MEASURE);
        dataSource.entities.getById(result.id).show = result.show;
      });
    this.actions.pipe(ofType(setMeasuresShow), takeUntilDestroyed())
      .subscribe((result: any) => {
        const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.MEASURE);
        result.ids.forEach((id: string) => {
          dataSource.entities.getById(id).show = result.show;
        });
      });

  }

  private initHandleCuts(): void {
    this.actions.pipe(ofType(
      loadCutsSuccess,
      addCutsSuccess), takeUntilDestroyed())
      .subscribe((results: any) => {
        this.store.select(selectAllCuts)
          .pipe(first())
          .subscribe((cuts: Array<Cut> | null | undefined) => {
            if (!this.cesium) return;
            const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.CUT);
            dataSource.entities.removeAll();
            if (cuts) {
              cuts.forEach((cut: Cut) => {
                this.entityService.createCutEntity(
                  dataSource, cut, this.selectedSite?.altitude, this.selectedSite?.height
                );
              });
            }
        });
      });

    this.actions.pipe(ofType(addCutSuccess), takeUntilDestroyed())
      .subscribe((result: any) => {
        if (result) {
          this.clearDraw();
          if (this.cesium) {
            const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.CUT);
            this.entityService.createCutEntity(
              dataSource, result.cut, this.selectedSite?.altitude, this.selectedSite?.height
            );
          }
          this.toastr.success(this.translocoService.translate('add_cut_success'));
          this.store.dispatch(selectCut({id: result.cut.id}));
        }
      });

    this.actions.pipe(ofType(updateCutSuccess), takeUntilDestroyed())
      .subscribe((result: any) => {
        if (result) {
          if (!this.cesium) return;
          const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.CUT);
          const entity = dataSource.entities.getById(result.update.id);
          this.entityService.updateEntity(entity, 'cut', result.update.changes);
        }
      });

    this.actions.pipe(ofType(addCutFailure), takeUntilDestroyed())
      .subscribe(result => {
        this.clearDraw();
        this.toastr.warning(this.translocoService.translate('add_cut_failure'));
      });

    this.actions
      .pipe(ofType(deleteCutSuccess), takeUntilDestroyed())
      .subscribe(result => {
        const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.CUT);
        dataSource.entities.removeById(result.id);
      });
    this.actions.pipe(ofType(deleteCutsSuccess), takeUntilDestroyed())
      .subscribe((result: any) => {
        const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.CUT);
        result.ids.forEach((id: string) => {
          dataSource.entities.removeById(id);
        });
      });
    this.actions.pipe(ofType(setCutShow), takeUntilDestroyed())
      .subscribe((result: any) => {
        const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.CUT);
        dataSource.entities.getById(result.id).show = result.show;
      });
    this.actions.pipe(ofType(setCutsShow), takeUntilDestroyed())
      .subscribe((result: any) => {
        const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.CUT);
        result.ids.forEach((id: string) => {
          dataSource.entities.getById(id).show = result.show;
        });
      });
  }

  private initHandleTracker(): void {
    this.actions.pipe(ofType(loadTrackersElevationSuccess, addTrackerElevationsSuccess), takeUntilDestroyed())
      .subscribe(result => {
        this.store.select(selectAllTrackerElevations)
          .pipe(first())
          .subscribe((elevations: Array<TrackerElevation> | null | undefined) => {
            if (!this.cesium) return;
            const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.ELEVATION);
            dataSource.entities.removeAll();
            if (elevations) {
              elevations.forEach((elevation: TrackerElevation) => {
                this.entityService.createElevationEntity(dataSource, elevation);
              });
            }
          });
      });

    this.actions.pipe(ofType(loadTrackersImageSuccess, addTrackerImagesSuccess), takeUntilDestroyed())
      .subscribe(result => {
        this.store.select(selectAllTrackerImages)
          .pipe(first())
          .subscribe((markers: Array<TrackerImage> | null | undefined) => {
            if (!this.cesium) return;
            const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.MARKER);
            dataSource.entities.removeAll();
            if (markers) {
              markers.forEach((marker: TrackerImage) => {
                this.entityService.createMarkerEntity(dataSource, marker);
              });
            }
          });
      });

    this.actions.pipe(ofType(addTrackerSuccess), takeUntilDestroyed())
      .subscribe(result => {
        this.clearDraw();
        const trackerType = result.tracker.trackerType;
        if (trackerType == TrackerType.IMAGE) {
          if (this.cesium) {
            const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.MARKER);
            this.entityService.createMarkerEntity(dataSource, result.tracker as TrackerImage);
          }
          this.toastr.success(this.translocoService.translate('add_marker_success'));
          this.store.dispatch(selectTrackerImage({id: result.tracker.id}));
        }
        else if (trackerType == TrackerType.ELEVATION) {
          if (this.cesium) {
            const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.ELEVATION);
            this.entityService.createElevationEntity(dataSource, result.tracker as TrackerElevation);
          }
          this.toastr.success(this.translocoService.translate('add_elevation_success'));
          this.store.dispatch(selectTrackerElevation({id: result.tracker.id}));
        }
      });

    this.actions.pipe(ofType(updateTrackerSuccess), takeUntilDestroyed())
      .subscribe((result: any) => {
        if (result) {
          if (!this.cesium) return;
          const trackerType = result.update.changes.trackerType;
          if (trackerType == TrackerType.IMAGE) {
            const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.MARKER);
            const entity = dataSource.entities.getById(result.update.id);
            this.entityService.updateEntity(entity, 'marker', result.update.changes);
          }
          else if (trackerType == TrackerType.ELEVATION) {
            const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.ELEVATION);
            const entity = dataSource.entities.getById(result.update.id);
            this.entityService.updateEntity(entity, 'elevation', result.update.changes);
          }
        }
      });

    this.actions.pipe(ofType(addTrackerFailure), takeUntilDestroyed())
      .subscribe(result => {
        this.store.select(selectDrawingMode)
          .pipe(first())
          .subscribe((mode: DrawingMode | undefined) => {
            this.drawingMode = mode;
            if (this.drawingMode == DrawingMode.MARKER_POINT) {
              this.toastr.warning(this.translocoService.translate('add_marker_failure'));
            }
            else if (this.drawingMode == DrawingMode.ELEVATION) {
              this.toastr.warning(this.translocoService.translate('add_elevation_failure'));
            }
            this.clearDraw();
          });
      });

    this.actions
      .pipe(ofType(deleteTrackerSuccess), takeUntilDestroyed())
      .subscribe(result => {
        let dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.ELEVATION);
        dataSource.entities.removeById(result.id);
        dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.MARKER);
        dataSource.entities.removeById(result.id);
      });
    this.actions.pipe(ofType(deleteTrackersSuccess), takeUntilDestroyed())
      .subscribe((result: any) => {
        let dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.ELEVATION);
        result.ids.forEach((id: string) => {
          dataSource.entities.removeById(id);
        });
        dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.MARKER);
        result.ids.forEach((id: string) => {
          dataSource.entities.removeById(id);
        });
      });
    this.actions.pipe(ofType(setTrackerShow), takeUntilDestroyed())
      .subscribe((result: any) => {
        let dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.ELEVATION);
        if (dataSource.entities.getById(result.id)) dataSource.entities.getById(result.id).show = result.show;
        dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.MARKER);
        if (dataSource.entities.getById(result.id)) dataSource.entities.getById(result.id).show = result.show;
      });
    this.actions.pipe(ofType(setTrackersShow), takeUntilDestroyed())
      .subscribe((result: any) => {
        let dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.ELEVATION);
        result.ids.forEach((id: string) => {
          if (dataSource.entities.getById(id)) dataSource.entities.getById(id).show = result.show;
        });
        dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.MARKER);
        result.ids.forEach((id: string) => {
          if (dataSource.entities.getById(id)) dataSource.entities.getById(id).show = result.show;
        });
      });
  }

  private initHandleVolumes(): void {
    this.actions
      .pipe(ofType(loadVolumesSuccess), takeUntilDestroyed())
      .subscribe((result: any) => {
        this.store.select(selectAllVolumes)
          .pipe(first())
          .subscribe((volumes ) => {
            if (!this.cesium) return;
            const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.VOLUME);
            dataSource.entities.removeAll();
            if (volumes) {
              volumes.forEach((volume: Volume) => {
                this.entityService.createVolumeEntity(dataSource, volume);
              });
            }
          });
    });

    this.actions
      .pipe(ofType(addVolumeEmergencySuccess), takeUntilDestroyed())
      .subscribe((data: any) => {
        this.toastr.success(this.translocoService.translate('add_volume_emergency_success'));
        this.store.select(selectCurrentSite)
          .pipe(first())
          .subscribe((site: Site | null | undefined) => {
            if (site) {
              this.store.dispatch(retrieveSite({
                organizationId: site.organization,
                siteId: site.id
              }));
            }
          });
      });

    this.actions
      .pipe(ofType(addVolumeEmergencyFailure), takeUntilDestroyed())
      .subscribe((data: any) => {
        this.toastr.error(this.translocoService.translate('add_volume_emergency_failure'));
      });
  }

  private initHandleDetections(): void {

    this.store.select(selectConfigDetectionFilters)
      .pipe(takeUntilDestroyed())
      .subscribe((result: any) => {
        this.store.select(selectAllDetections)
          .pipe(first())
          .subscribe((detections: Array<Detection>) => {
            if(!this.cesium) return;
            const detectionCollection = CesiumTools.getOrCreatePrimitiveCollection(this.cesium.viewer, CollectionType.DETECTION);
            detectionCollection.removeAll();
            if (detections) {
              this.detectionFilter.transform(detections).forEach((detection: Detection) => {
                this.primitiveService.createDetectionPrimitive(detectionCollection, detection);
              });
            }
          });
      });

    this.actions
      .pipe(ofType(loadDetectionsSuccess), takeUntilDestroyed())
      .subscribe((result: any) => {
        this.store.select(selectAllDetections)
          .pipe(first())
          .subscribe((detections: Array<Detection>) => {
            if(!this.cesium) return;
            const detectionCollection = CesiumTools.getOrCreatePrimitiveCollection(this.cesium.viewer, CollectionType.DETECTION);
            detectionCollection.removeAll();
            if (detections) {
              detections.forEach((detection: Detection) => {
                this.primitiveService.createDetectionPrimitive(detectionCollection, detection);
              });
            }
          });
      });

    this.store.select(selectCurrentDetection)
      .pipe(takeUntilDestroyed())
      .subscribe((selectedDetection: Detection | null | undefined) => {
        if(!this.cesium) return;
        const detectionCollection = CesiumTools.getOrCreatePrimitiveCollection(this.cesium.viewer, CollectionType.DETECTION);

        if (detectionCollection.length) {
          for(let i = 0 ; i < detectionCollection.length ; i++) {
            const primitive = detectionCollection.get(i);
            const detection = primitive.entity;
            if (selectedDetection) {
              if (detection.id == selectedDetection.id) {
                detectionCollection.remove(primitive);
                this.primitiveService.createDetectionPrimitive(detectionCollection, selectedDetection, true);
                break
              }
            }
            else {
              if (primitive.isSelected) {
                detectionCollection.remove(primitive);
                this.primitiveService.createDetectionPrimitive(detectionCollection, detection);
                break
              }
            }
          }
        }
      });

    this.actions
      .pipe(ofType(
        addDetectionSuccess,
        deleteDetectionSuccess,
        updateDetectionSuccess,
        addDetectionEmergencySuccess), takeUntilDestroyed())
      .subscribe((data: any) => {
        this.store.select(selectCurrentSite)
          .pipe(first())
          .subscribe((site: Site | null | undefined) => {
            if (site) {
              this.store.dispatch(retrieveSite({
                organizationId: site.organization,
                siteId: site.id
              }));
            }
          });
      });
    this.actions
      .pipe(ofType(addDetectionEmergencyFailure), takeUntilDestroyed())
      .subscribe((data: any) => {
        this.toastr.error(this.translocoService.translate('add_detection_emergency_failure'));
      });
    this.actions
      .pipe(ofType(updateDetectionFailure), takeUntilDestroyed())
      .subscribe((data: any) => {
        this.toastr.error(this.translocoService.translate('update_detection_failure'));
      });
    this.actions.pipe(ofType(addDetectionEmergencySuccess), takeUntilDestroyed())
      .subscribe((result: any) => {
        if (result) {
          this.clearDraw();
          this.toastr.success(this.translocoService.translate('add_detection_emergency_success'));
        }
      });

    this.actions
      .pipe(ofType(addDetectionSuccess), takeUntilDestroyed())
      .subscribe(result => {
        this.clearDraw();
        if (this.cesium) {
          const detectionCollection = CesiumTools.getOrCreatePrimitiveCollection(this.cesium.viewer, CollectionType.DETECTION);
          this.primitiveService.createDetectionPrimitive(detectionCollection, result.detection);
        }
        this.toastr.success(this.translocoService.translate('add_detection_success'));
        this.store.dispatch(selectDetection({id: undefined}));
        this.store.dispatch(selectDetection({id: result.detection.id}));
      });

    this.actions.pipe(ofType(addDetectionFailure), takeUntilDestroyed())
      .subscribe(result => {
        this.clearDraw();
        this.toastr.warning(this.translocoService.translate('add_detection_failure'));
      });

    this.actions
      .pipe(ofType(deleteDetectionSuccess), takeUntilDestroyed())
      .subscribe(result => {
        const detectionCollection = CesiumTools.getOrCreatePrimitiveCollection(this.cesium.viewer, CollectionType.DETECTION);
        const primitive = CesiumTools.retrievePrimitiveByEntityIdFromCollection(detectionCollection, result.id);
        detectionCollection.remove(primitive);
        this.store.dispatch(selectDetection({id: undefined}));
      });
    this.actions
      .pipe(ofType(deleteDetectionFailure), takeUntilDestroyed())
      .subscribe(result => {
        this.toastr.warning(this.translocoService.translate('delete_detection_failure'));
      });
  }

  ngAfterViewInit() {
    this.configureCamera();
    this.configureInputs();
    this.store.dispatch(setCameraMode({cameraMode: CameraMode.MODE_3D}));

    if(!this.cesium || !this.cesium.viewer) return;

    // Keep those calls to preset primitiveCollections in this exact order !!!
    CesiumTools.getOrCreatePrimitiveCollection(this.cesium.viewer, CollectionType.PRIMARY_TILESET);
    CesiumTools.getOrCreatePrimitiveCollection(this.cesium.viewer, CollectionType.SECONDARY_TILESET);
    CesiumTools.getOrCreatePrimitiveCollection(this.cesium.viewer, CollectionType.DENSITY);
    CesiumTools.getOrCreatePrimitiveCollection(this.cesium.viewer, CollectionType.DETECTION);
  }

  private configureCamera(): void {
    if(!this.cesium || !this.cesium.viewer) return;
    this.cesium.viewer.camera.changed.addEventListener((event: any)=> {
      this.store.select(selectIsMiniMapDragging)
        .pipe(first())
        .subscribe((isMiniMapDragging:boolean) => {
          const camera = this.cesium.viewer.camera;
          if (camera && this.cameraMode == CameraMode.MODE_2DV && !isMiniMapDragging) {
            const cameraConfiguration: CameraConfiguration = {
              position: camera.position.clone(),
              orientation: {
                heading: camera.heading,
                pitch: camera.pitch,
                roll: camera.roll,
              }
            };
            this.store.dispatch(setCameraConfiguration({cameraConfiguration}));
          }
        });
    });
  }

  private configureInputs(): void {
    if(!this.cesium || !this.cesium.viewer) return;
    this.cesium.viewer.screenSpaceEventHandler.setInputAction(
      (movement:any) => { this.onViewerMouseMove(this.cesium.viewer, movement.endPosition); },
      Cesium.ScreenSpaceEventType.MOUSE_MOVE);
    this.cesium.viewer.screenSpaceEventHandler.setInputAction(
      (movement:any) => {this.onViewerMouseLeftClick(this.cesium.viewer, movement.position); },
      Cesium.ScreenSpaceEventType.LEFT_CLICK);
    this.cesium.viewer.screenSpaceEventHandler.setInputAction(
      (movement:any) => { this.onViewerMouseLeftDoubleClick(this.cesium.viewer, movement.position); },
      Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
    this.cesium.viewer.screenSpaceEventHandler.setInputAction(
      (movement:any) => { this.onViewerMouseRightClick(this.cesium.viewer, movement.position); },
      Cesium.ScreenSpaceEventType.RIGHT_CLICK);
    this.cesium.viewer.screenSpaceEventHandler.setInputAction(
      (movement:any) => { this.onViewerMouseLeftUp(this.cesium.viewer, movement.position); },
      Cesium.ScreenSpaceEventType.LEFT_UP);
    this.cesium.viewer.screenSpaceEventHandler.setInputAction(
      (movement:any) => { this.onViewerMouseLeftDown(this.cesium.viewer, movement.position); },
      Cesium.ScreenSpaceEventType.LEFT_DOWN);
  }

  private onViewerMouseMove(viewer:any, position: any): void {
    if(this.drawingMode) {
      if (Cesium.defined(this.floatingPoint)) {
        const ray = viewer.camera.getPickRay(position);
        const tilesetCollection = CesiumTools.getOrCreatePrimitiveCollection(this.cesium.viewer, CollectionType.PRIMARY_TILESET);
        const length: any = tilesetCollection.length;
        let newPosition = (length) ? tilesetCollection.get(0).pick(ray, viewer.scene) : undefined;
        if(!newPosition) newPosition = viewer.scene.globe.pick(ray, viewer.scene);
        if (Cesium.defined(newPosition)) {
          this.floatingPoint.position.setValue(newPosition);
          // const distance = Cesium.Cartesian3.distance(this.activeShapePoints[this.activeShapePoints.length-2], newPosition);
          // this.floatingPoint.label.text = distance.toFixed(2)+ ' m';
          this.activeShapePoints.pop();
          this.activeShapePoints.push(newPosition);
        }
      }
    }
    else if(this.slider?.isActive) {
      this.cesium.viewer.scene.splitPosition = this.slider.splitPosition;
    }
    else {
      const feature = this.cesium.viewer.scene.pick(position);
      this.overlayService.displayFeatureContent(viewer, this.cesium.entityOverlay, feature, position);
    }
  }

  private onViewerMouseLeftClick(viewer:any, position: any): void {
    if (this.drawingMode) {
      const ray = viewer.camera.getPickRay(position);
      const tilesetCollection = CesiumTools.getOrCreatePrimitiveCollection(this.cesium.viewer, CollectionType.PRIMARY_TILESET);
      const length: any = tilesetCollection.length;
      let earthPosition = (length) ? tilesetCollection.get(0).pick(ray, viewer.scene) : undefined;
      if(!earthPosition) earthPosition = viewer.scene.globe.pick(ray, viewer.scene);
      if (!earthPosition) return;
      if (Cesium.defined(earthPosition)) {
        const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.DRAW);
        if (this.activeShapePoints.length === 0) {
          this.floatingPoint = this.entityService.createDrawPointEntity(dataSource, this.drawingMode, earthPosition);
          this.activeShapePoints.push(earthPosition);
          const dynamicPositions = new Cesium.CallbackProperty( () => {
            return this.activeShapePoints;
          }, false);
          const dynamicMinumunHeights = new Cesium.CallbackProperty( () => {
            return this.activeShapePoints.map((p: any) => (this.selectedSite) ? this.selectedSite.altitude : 0);
          }, false);
          const dynamicMaxumunHeights = new Cesium.CallbackProperty( () => {
            return this.activeShapePoints.map((p: any) => (this.selectedSite) ? this.selectedSite.altitude + this.selectedSite.height : 100);
          }, false);

          this.activeShape = this.entityService.drawShape(
            dataSource, this.drawingMode, dynamicPositions, dynamicMinumunHeights, dynamicMaxumunHeights
          );
        }
        this.activeShapePoints.push(earthPosition);
        this.entityService.createDrawPointEntity(dataSource, earthPosition, this.selectedSite?.height);
        if ((this.drawingMode == DrawingMode.MEASURE_LINE || this.drawingMode == DrawingMode.CUT_LINE)
          && this.activeShapePoints.length == 3) {
          this.stopDrawing(viewer);
        }
        else if (this.drawingMode == DrawingMode.NEW_DETECTION && this.activeShapePoints.length == 5) {
          this.activeShapePoints.push(this.activeShapePoints[0]);
          this.stopDrawing(viewer);
        }
        else if (this.drawingMode == DrawingMode.MARKER_POINT || this.drawingMode == DrawingMode.IMAGES_AT_POINT || this.drawingMode == DrawingMode.ELEVATION) {
          this.stopDrawing(viewer);
        }
      }
    }else {
      const feature = this.cesium.viewer.scene.pick(position);
      if (Cesium.defined(feature) &&
        (Cesium.defined(feature.id) || Cesium.defined(feature.primitive.entity))) {
        const entity = feature.id || feature.primitive.entity;
        this.entityService.displayEntity(entity);
      }
    }
  }

  private onViewerMouseLeftDoubleClick(viewer:any, position: any): void {

  }

  private onViewerMouseRightClick(viewer:any, position: any): void {
    if (this.drawingMode) {
      this.activeShapePoints.pop();
      if(this.activeShapePoints.length >= 3) {
        const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.DRAW);
        if(this.drawingMode == DrawingMode.MEASURE_POLYGON || this.drawingMode == DrawingMode.REPORT_AREA) {
          this.activeShapePoints.push(this.activeShapePoints[0]);
          this.entityService.drawShape(
            dataSource,
            this.drawingMode,
            this.activeShapePoints,
            this.activeShapePoints.map((p: any) => (this.selectedSite) ? this.selectedSite.altitude : 0),
            this.activeShapePoints.map((p: any) => (this.selectedSite) ? this.selectedSite.altitude + this.selectedSite.height : 0)
          );
        }
        dataSource.entities.remove(this.floatingPoint);
        this.stopDrawing(viewer);
      }
      else {
        this.clearDraw();
      }
    }
  }

  private onViewerMouseLeftUp(viewer:any, position: any): void {

  }

  private onViewerMouseLeftDown(viewer:any, position: any): void {

  }

  private updateCameraMode(mode: any): void {
    if (this.cameraMode != mode) {
      this.cameraMode = mode;
      this.cesium.setMode(this.cameraMode);
    }
  }

  private updateCameraConfiguration(mode: any, cameraConfiguration: CameraConfiguration, isDragging: boolean): void {
    switch (mode){
      case CameraMode.MODE_2DV: {
        if (cameraConfiguration) {
          if (isDragging) {
            const camera = this.cesium.viewer.camera;
            camera.position = Cesium.Cartesian3.fromElements(cameraConfiguration.position.x, cameraConfiguration.position.y, cameraConfiguration.position.z);
          }
        }
        break;
      }
      default: {
        if (cameraConfiguration){
          const preset = CesiumTools.cameraFromCameraConfiguration(cameraConfiguration);
          this.cesium.viewer.camera.flyTo(preset);
        }
        break;
      }
    }
  }

  private updateCameraLookAt(rotationRate: any): void {
    switch (this.cameraMode){
      case CameraMode.MODE_2DV: {
        if(rotationRate) {
          const camera = this.cesium.viewer.camera;
          camera.lookRight(rotationRate);
          const heading = Math.round((camera.heading || 0) * 180 / Cesium.Math.PI);
          const orientation = CesiumTools.cameraHeadingPitchRoll(
            heading,
            0,
            0
          );
          const cameraConfiguration: CameraConfiguration = {position: camera.position.clone(), orientation};
          this.store.dispatch(setCameraConfiguration({cameraConfiguration}));
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  private stopDrawing(viewer: any): void {
    if (this.selectedSite?.organization && this.selectedSite?.id) {
      switch (this.drawingMode) {
        case DrawingMode.MEASURE_LINE:
          this.store.dispatch(addMeasureDistance({
            organizationId: this.selectedSite.organization,
            siteId: this.selectedSite.id,
            batchId: this.selectedSite.lastBatch,
            point1: CesiumTools.cartesian3ToArray(this.activeShapePoints[0]),
            point2: CesiumTools.cartesian3ToArray(this.activeShapePoints[this.activeShapePoints.length -1]),
            cameraPosition: CesiumTools.cartesian3ToArray(viewer.camera.position),
          }));
          break;
        case DrawingMode.MEASURE_POLYGON:
          this.store.dispatch(addMeasureArea({
            organizationId: this.selectedSite.organization,
            siteId: this.selectedSite.id,
            batchId: this.selectedSite.lastBatch,
            polygon: CesiumTools.cartesian3ArrayToArray(this.activeShapePoints.slice(0, this.activeShapePoints.length-1)),
            cameraPosition: CesiumTools.cartesian3ToArray(viewer.camera.position)
          }));
          break;
        case DrawingMode.CUT_LINE:
          this.store.dispatch(addCut({
            organizationId: this.selectedSite.organization,
            siteId: this.selectedSite.id,
            point1: CesiumTools.cartesian3ToArray(this.activeShapePoints[0]),
            point2: CesiumTools.cartesian3ToArray(this.activeShapePoints[this.activeShapePoints.length -1]),
          }));
          break;
        case DrawingMode.MARKER_POINT:
          this.store.dispatch(addTracker({
            organizationId: this.selectedSite.organization,
            siteId: this.selectedSite.id,
            point: CesiumTools.cartesian3ToArray(this.activeShapePoints[0]),
            trackerType: TrackerType.IMAGE
          }));
          break;
        case DrawingMode.NEW_DETECTION:
          this.displayNewDetectionDialog(
            this.selectedSite,
            CesiumTools.cartesian3ArrayToArray(this.activeShapePoints.slice(0,4)),
            CesiumTools.cartesian3ToArray(viewer.camera.position)
          );
          break;
        case DrawingMode.ELEVATION:
          this.store.dispatch(addTracker({
            organizationId: this.selectedSite.organization,
            siteId: this.selectedSite.id,
            point: CesiumTools.cartesian3ToArray(this.activeShapePoints[0]),
            trackerType: TrackerType.ELEVATION
          }));
          break;
        case DrawingMode.REPORT_AREA:
          const area = CesiumTools.cartesian3ArrayToArray(this.activeShapePoints.slice(0, this.activeShapePoints.length-1));
          const entities = this.cesiumService.entitiesInPolygon(this.cesium.viewer, area, 1);
          this.store.dispatch(reportWithData({data: entities}));
          break;
        default:
          break;
      }
    }
    this.store.dispatch(setDrawingMode({drawingMode: undefined}));
  }

  private clearDraw(): void {
    const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.DRAW);
    this.floatingPoint = undefined;
    dataSource.entities.removeAll();
    this.activeShape = undefined;
    this.activeShapePoints = [];
    this.store.dispatch(setDrawingMode({drawingMode: undefined}));
  }

  private configureSite(site: Site) {
    this.selectedSite = site;
    this.cesium.displayDataSources(false);

    const position = Cesium.Cartesian3.fromArray(site.pin);
    const preset = CesiumTools.camera3DPresetsFromPosition(position);
    this.cesium.viewer.camera.flyTo(preset);
    this.cesium.viewer.scene.globe.translucency.frontFaceAlphaByDistance = new Cesium.NearFarScalar(
      50.0,
      0.0,
      1500.0,
      1.0
    );

    this.store.dispatch(loadBatchs({
      organizationId: site.organization,
      siteId: site.id
    }));

    this.store.dispatch(loadTilesetConfigs({
      site: site
    }));

    this.store.dispatch(loadCuts({
      organizationId: site.organization,
      siteId: site.id
    }));

    this.store.dispatch(loadMeasures({
      organizationId: site.organization,
      siteId: site.id,
      batchId: site.lastBatch,
    }));

    this.store.dispatch(loadTrackersImage({
      organizationId: site.organization,
      siteId: site.id
    }));

    this.store.dispatch(loadTrackersElevation({
      organizationId: site.organization,
      siteId: site.id
    }));


    this.store.dispatch(setConfigSiteDetailSelectedSection({value: 1}));

    if (this.modulePipe.transform(site, [InspectionModule.ARCAD])) {
      this.store.dispatch(loadDetections({
        organizationId: site.organization,
        siteId: site.id,
        batchId: site.lastBatch
      }));
      this.store.dispatch(loadDetectionSummary({
        organizationId: site.organization,
        siteId: site.id,
        batchId: site.lastBatch
      }));
      this.store.dispatch(loadDetectionTemplate({
        organizationId: site.organization,
        siteId: site.id
      }));

      if (site.haveDetectionEmergencyTemplate) {
        this.store.dispatch(loadDetectionEmergencyTemplate({
          organizationId: site.organization,
          siteId: site.id
        }));
      }
    }

    if (this.modulePipe.transform(site, [InspectionModule.PERREAD])) {
      if (site.haveVolumeEmergencyTemplate) {
        this.store.dispatch(loadVolumeEmergencyTemplate({
          organizationId: site.organization,
          siteId: site.id
        }));
      }
    }
  }

  private clean(): void {
    this.selectedSite = undefined;

    const dataSource = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.DRAW);
    dataSource.entities.removeAll();

    let collection = CesiumTools.getOrCreatePrimitiveCollection(this.cesium.viewer, CollectionType.DETECTION);
    collection.removeAll();
    collection = CesiumTools.getOrCreatePrimitiveCollection(this.cesium.viewer, CollectionType.DENSITY);
    collection.removeAll();
    collection = CesiumTools.getOrCreatePrimitiveCollection(this.cesium.viewer, CollectionType.SECONDARY_TILESET);
    collection.removeAll();
    collection = CesiumTools.getOrCreatePrimitiveCollection(this.cesium.viewer, CollectionType.PRIMARY_TILESET);
    collection.removeAll();

    collection = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.MEASURE);
    collection.entities.removeAll();
    collection = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.CUT);
    collection.entities.removeAll();
    collection = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.ELEVATION);
    collection.entities.removeAll();
    collection = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.MARKER);
    collection.entities.removeAll();
    collection = CesiumTools.getOrCreateDataSource(this.cesium.viewer, DataSourceType.VOLUME);
    collection.entities.removeAll();

    this.cesium.displayDataSources(true);
    this.cesium.viewer.scene.globe.translucency.frontFaceAlphaByDistance = undefined;
    this.store.dispatch(setVolumeColorMode({colorMode: VolumeColorMode.COLOR_MODE_VOLUME_MOVED}));
    this.store.dispatch(setDrawingMode({drawingMode: undefined}));
    this.store.dispatch(setConfigColorRampMin({value: 0}));
    this.store.dispatch(setConfigColorRampMax({value: 0}));
    this.store.dispatch(setConfigColorRampNegativeColors({colors: []}));
    this.store.dispatch(setConfigColorRampPositiveColors({colors: []}));
    this.store.dispatch(setMiniMapCameraConfiguration({cameraConfiguration: undefined}))
    this.store.dispatch(setConfigTilesetStyleColorConditions({conditions: undefined}));
    this.store.dispatch(setConfigPointCloudPointSize({pointSize: 1}));
    this.store.dispatch(setConfigViewRepresentation({value: ViewRepresentation.TEXTURED}));
    this.store.dispatch(setTilesetConfigId({tilesetConfigId: undefined}));
    this.store.dispatch(clearDetectionSummary());
    this.store.dispatch(clearVolumeSummary());
    this.store.dispatch(clearAllBatchs());
    this.store.dispatch(clearAllMeasures());
    this.store.dispatch(clearAllDetections());
    this.store.dispatch(clearAllVolumes());
    this.store.dispatch(clearAllCuts());
    this.store.dispatch(clearAllTrackers());
    this.store.dispatch(clearAllTilesetConfigs());
    this.store.dispatch(clearVolumeEmergencyTemplate());
    this.store.dispatch(clearDetectionEmergencyTemplate());
    this.store.dispatch(clearDetectionTemplate());
    this.store.dispatch(setConfigSearchWorldText({text: undefined}));
    this.store.dispatch(selectDetection({id: undefined}));
    this.store.dispatch(selectVolume({id: undefined}));
    this.store.dispatch(selectMeasure({id: undefined}));
    this.store.dispatch(selectTrackerImage({id: undefined}));
    this.store.dispatch(selectTrackerElevation({id: undefined}));
    this.store.dispatch(selectCut({id: undefined}));
    this.store.dispatch(selectReferenceBatch({id: undefined}));
  }

  private displayNewDetectionDialog(site: Site, points: Array<Array<number>>, cameraPosition: Array<number>): void {
    const dialogRef: MatDialogRef<CreateDetectionDialog> = this.dialog.open(CreateDetectionDialog, {
      disableClose: false
    });
    dialogRef.afterClosed()
      .subscribe(result => {
      if(result) {
        this.store.dispatch(addDetection({
          organizationId: site.organization,
          siteId: site.id,
          batchId: site.lastBatch,
          payload: {
            'label': result,
            'points': points,
            'camera_position' : cameraPosition,
            'max_dist_to_plane': site.maxDistToPlane
          }}));
      }
      else {
        this.clearDraw();
      }
    });
  }

  private displayNewDifferentialTilesetDialog(referenceBatch: Batch): void {
    const dialogRef: MatDialogRef<BatchDiffRepresentationSelectorDialog> = this.dialog.open(BatchDiffRepresentationSelectorDialog, {
      disableClose: false,
    });
    dialogRef.componentInstance.batchComparedId = this.selectedSite?.lastBatch;
    dialogRef.componentInstance.batchReferenceId = referenceBatch.id;
    dialogRef.afterClosed()
      .subscribe(result => {
        if(result) {
          this.store.select(selectCurrentSite)
            .pipe(first())
            .subscribe((site: Site | null | undefined) => {
              if (site) {
                this.store.dispatch(addTilesetConfig({
                  organizationId: site.organization,
                  siteId: site.id,
                  batch_compared: site.lastBatch,
                  batch_reference: referenceBatch.id
                }));
              }
            });
        }
        else {
          this.store.dispatch(setConfigViewRepresentation({value: ViewRepresentation.TEXTURED}));
        }
      });
  }



  get dateFormat() {
    if(this.user) {
      return (this.user.languageCode == 'fr') ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
    }
    return 'MM/dd/yyyy';
  }

  get selectedTilesetConfigId$(): Observable<string | undefined> {
    return this.store.select(selectCurrentTilesetConfigId);
  }

  get scene(): any {
    return this.cesium.viewer.scene;
  }


  get totalDetection$(): Observable<number> {
    return this.store.select(selectDetectionTotal);
  }

  get totalVolume$(): Observable<number> {
    return this.store.select(selectVolumeTotal);
  }
}
