import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Detection } from '../../../../models/detection';
import {
  selectAllDetections
} from '../../../../features/detection/detection.selectors';
import { Actions, ofType } from '@ngrx/effects';
import {
  loadDetections,
  loadDetectionsFailure,
  loadDetectionsSuccess
} from '../../../../features/detection/detection.actions';
import { FilterDetectionsPipe } from '../../../pipes/detection.pipe';
import { combineLatest } from 'rxjs';
import {
  selectConfigDetectionFilters
} from '../../../../features/config/config.selectors';

@Component({
  selector: 'detection-list',
  templateUrl: './detection-list.component.html',
  styleUrls: ['../list.component.scss']
})
export class DetectionListComponent {

  detections: Array<Detection> = [];
  isLoading: boolean = false;

  constructor(private store: Store,
              private actions: Actions,
              private filter: FilterDetectionsPipe) {

    combineLatest([
      this.store.select(selectAllDetections),
      this.store.select(selectConfigDetectionFilters)
    ]).pipe(takeUntilDestroyed())
      .subscribe((results: any) => {
        const detections = results[0];
        if (detections) {
          this.detections = this.filter.transform(detections);
        } else {
          this.detections = [];
        }
      });

    this.actions.pipe(ofType(loadDetections), takeUntilDestroyed())
      .subscribe(result => {
        this.isLoading = true;
      });
    this.actions.pipe(ofType(loadDetectionsSuccess), takeUntilDestroyed())
      .subscribe(result => {
        this.isLoading = false;
      });
    this.actions.pipe(ofType(loadDetectionsFailure), takeUntilDestroyed())
      .subscribe(result => {
        this.isLoading = false;
      });
  }
}
