import {
  Component,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  setConfigPointCloudPointSize
} from '../../../../features/config/config.actions';
import {
  selectConfigPointCloudPointSize
} from '../../../../features/config/config.selectors';


@Component({
  selector: 'view-point-cloud-size-slider',
  templateUrl: './view-point-cloud-size-slider.component.html',
  styleUrls: ['./view-point-cloud-size-slider.component.scss'],
})
export class ViewPointCloudSizeSliderComponent {

  pointSizeMin: number = 1;
  pointSizeMax: number = 10;

  constructor(private store: Store) {

  }

  onSliderChange(event: any): void {
    this.store.dispatch(setConfigPointCloudPointSize({pointSize: event}));
  }

  get pointSize$(): Observable<number> {
    return this.store.select(selectConfigPointCloudPointSize);
  }
}
