import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'item-list-header',
  templateUrl: './item-list-header.component.html',
  styleUrls: ['./item-list-header.component.scss'],
})
export class ItemListHeaderComponents {
  @Input() hasSelectedItems: boolean = false;
  @Input() allItemsSelected: boolean = false;
  @Output() allCheckboxStateChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() clickDelete: EventEmitter<void> = new EventEmitter();
  @Output() clickShow: EventEmitter<void> = new EventEmitter();
  @Output() clickHide: EventEmitter<void> = new EventEmitter();
  @Output() clickShare: EventEmitter<void> = new EventEmitter();
  @Output() clickShareOff: EventEmitter<void> = new EventEmitter();


  onAllCheckboxStateChange(event: any): void {
    this.allCheckboxStateChanged.next(event.checked);
  }

  onClickDelete(event: any): void {
    this.clickDelete.next();
  }

  onClickShow(event: any): void {
    this.clickShow.next();
  }

  onClickHide(event: any): void {
    this.clickHide.next();
  }

  onClickShare(event: any): void {
    this.clickShare.next();
  }

  onClickShareOff(event: any): void {
    this.clickShareOff.next();
  }
}
