import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as MeasureActions from './measure.actions';
import { Deserialize } from 'cerialize';
import { Measure } from '../../models/measure';
import { ApiService } from '../../services/api.service';
import { TranslocoService } from '@jsverse/transloco';
import { Comment } from '../../models/comment';


@Injectable()
export class MeasureEffects {

  loadMeasures$ = createEffect(() => {
    return this.actions$.pipe(ofType(MeasureActions.loadMeasures),
      concatMap(({organizationId, siteId, batchId}) =>
        this.api.measures$(organizationId, siteId, batchId).pipe(
          map(measures => measures.map((measure: any) => {
            const obj: Measure = Deserialize(measure, Measure);
            if (!obj.name) {
              obj.name = this.translocoService.translate('measure') + " " + obj.id.slice(0,4).toUpperCase();
            }
            return obj;
          })),
          map(measures => MeasureActions.loadMeasuresSuccess({ measures })),
          catchError(error => of(MeasureActions.loadMeasuresFailure({ error }))))
      ));
  });

  addMeasureDistance$ = createEffect(() => {
    return this.actions$.pipe(ofType(MeasureActions.addMeasureDistance),
      concatMap(({organizationId, siteId, batchId, point1, point2, cameraPosition}) =>
      this.api.measureDistance$(organizationId, siteId, batchId, point1, point2, cameraPosition).pipe(
        map(measure => {
          const obj: Measure = Deserialize(measure, Measure);
          obj.name = this.translocoService.translate('measure') + " " + obj.id.slice(0,4).toUpperCase();
          return obj;
        }),
        map(measure => MeasureActions.addMeasureSuccess({ measure })),
        catchError(error => of(MeasureActions.addMeasureFailure({ error }))))
    ));
  });

  addMeasureArea$ = createEffect(() => {
    return this.actions$.pipe(ofType(MeasureActions.addMeasureArea),
      concatMap(({organizationId, siteId, batchId, polygon, cameraPosition}) =>
        this.api.measureArea$(organizationId, siteId, batchId, polygon, cameraPosition).pipe(
          map(measure => {
            const obj: Measure = Deserialize(measure, Measure);
            obj.name = this.translocoService.translate('measure') + " " + obj.id.slice(0,4).toUpperCase();
            return obj;
          }),
          map(measure => MeasureActions.addMeasureSuccess({ measure })),
          catchError(error => of(MeasureActions.addMeasureFailure({ error }))))
      ));
  });

  deleteMeasure$ = createEffect(() => {
    return this.actions$.pipe(ofType(MeasureActions.deleteMeasure),
      concatMap(({organizationId, siteId, batchId, measureId}) =>
        this.api.deleteMeasure$(organizationId, siteId, batchId, measureId).pipe(
          map(cut =>  MeasureActions.deleteMeasureSuccess({ id: measureId })),
          catchError(error => of(MeasureActions.deleteMeasureFailure({ error }))))
      ));
  });

  updateMeasure$ = createEffect(() => {
    return this.actions$.pipe(ofType(MeasureActions.updateMeasure),
      concatMap(({organizationId, siteId, batchId, measureId, payload}) =>
        this.api.updateMeasure$(organizationId, siteId, batchId, measureId, payload).pipe(
          map(measure => Deserialize(measure, Measure)),
          map(measure => {
            return {id: measure.id, changes: measure};
          }),
          map(update => MeasureActions.updateMeasureSuccess({ update })),
          catchError(error => of(MeasureActions.updateMeasureFailure({ error }))))
      ));
  });

  loadMeasureComments$ = createEffect(() => {
    return this.actions$.pipe(ofType(MeasureActions.loadMeasureComments),
      concatMap(({organizationId, siteId, batchId, measureId}) =>
        this.api.measureComments$(organizationId, siteId, batchId, measureId).pipe(
          map(comments => comments.map((comment: any) => Deserialize(comment, Comment))),
          map(comments => MeasureActions.loadMeasureCommentsSuccess({ comments })),
          catchError(error => of(MeasureActions.loadMeasureCommentsFailure({ error }))))
      ));
  });

  addMeasureComment$ = createEffect(() => {
    return this.actions$.pipe(ofType(MeasureActions.addMeasureComment),
      concatMap(({organizationId, siteId, batchId, measureId, payload}) =>
        this.api.addMeasureComment$(organizationId, siteId, batchId, measureId, payload).pipe(
          map(comment => Deserialize(comment, Comment)),
          map(comment => MeasureActions.addMeasureCommentSuccess({ comment })),
          catchError(error => of(MeasureActions.addMeasureCommentFailure({ error }))))
      ));
  });

  deleteMeasures$ = createEffect(() => {
    return this.actions$.pipe(ofType(MeasureActions.deleteMeasures),
      concatMap(({organizationId, siteId, batchId, ids}) =>
        this.api.deleteMeasures$(organizationId, siteId, batchId, ids).pipe(
          map(result =>  MeasureActions.deleteMeasuresSuccess({ ids: result.ids })),
          catchError(error => of(MeasureActions.deleteMeasuresFailure({ error }))))
      ));
  });

  shareMeasures$ = createEffect(() => {
    return this.actions$.pipe(ofType(MeasureActions.shareMeasures),
      concatMap(({organizationId, siteId, batchId, ids}) =>
        this.api.shareMeasures$(organizationId, siteId, batchId, ids).pipe(
          map(result =>  MeasureActions.shareMeasuresSuccess({ ids: result.ids })),
          catchError(error => of(MeasureActions.shareMeasuresFailure({ error }))))
      ));
  });

  shareOffMeasures$ = createEffect(() => {
    return this.actions$.pipe(ofType(MeasureActions.shareOffMeasures),
      concatMap(({organizationId, siteId, batchId, ids}) =>
        this.api.shareOffMeasures$(organizationId, siteId, batchId, ids).pipe(
          map(result =>  MeasureActions.shareOffMeasuresSuccess({ ids: result.ids })),
          catchError(error => of(MeasureActions.shareOffMeasuresFailure({ error }))))
      ));
  });

  constructor(private actions$: Actions,
              private api: ApiService,
              private translocoService: TranslocoService) {}

}
